import FAQ from "models/FAQ"
import { perform } from "utils/api/perform"

const root = (id: string) => perform(`/event_groups/${id}/qa`)

const list = async (eventGroupId: string): Promise<FAQ[]> =>
	(await root(eventGroupId)<{ data: FAQ[] }>("get", `/`)).data

const update = async (eventGroupId: string, questions: FAQ[]): Promise<void> =>
	await root(eventGroupId)("post", `/`, {
		data: questions,
	})

const _delete = (tagId: string, eventGroupId: string): Promise<void> =>
	root(eventGroupId)("delete", `/${tagId}`)

export default {
	update,
	list,
	delete: _delete,
}
