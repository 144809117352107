import Capsule from "components/Capsule"
import TR from "components/TR"
import { scheduleActions } from "dashboard/schedule/scheduleSlice"
import plusIcon from "images/icons/plus.svg"
import Event from "models/Event"
import Tag from "models/Tag"
import { useContext } from "react"
import store, { useScheduleSelector, useGuestSelector } from "store"
import MainContext from "store/context/MainContext"

const EventScrollableRow = ({
	isHovered,
	setIsHovered,
	event,
	date,
	index,
	editSheet,
}: {
	isHovered: boolean
	setIsHovered: (isHovered: boolean) => void
	event: Event
	date: number
	index: number
	editSheet: JSX.Element
}) => {
	const schedule = useScheduleSelector()
	const guests = useGuestSelector()
	const mainContext = useContext(MainContext)

	const handleSelectTag = (tag: Tag) => {
		let tags: Tag[]

		if (schedule.selectedTags.some((t) => t.tag === tag.tag)) {
			tags = schedule.selectedTags.filter((t) => tag.tag !== t.tag)
		} else {
			tags = [...schedule.selectedTags, tag]
		}

		store.dispatch(scheduleActions.filter(tags))
	}

	const handleRemoveTag = (tag: Tag, event: Event) => {
		store.dispatch(
			scheduleActions.update({
				...event,
				tags: event.tags.filter((t) => t !== tag),
			})
		)
	}

	return (
		<TR key={`${date}-${index}-tr`}>
			{/* No idea where that 1px missing is from... */}
			<td
				className={`group/tags flex h-[79px] w-[99%] cursor-pointer items-center ${
					isHovered ? "bg-dark-100 bg-opacity-5" : ""
				}`}
				onClick={() => mainContext.setSheet(editSheet)}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
			>
				<span className="pr-12 font-semibold">
					{
						guests.all.filter((g) =>
							g.tags.some((t) => event.tags.some((tag) => tag.id === t.id))
						).length
					}
				</span>
				<div className="flex items-center gap-2">
					{event.tags.map((t) => {
						const isSelected =
							schedule.selectedTags.some((tag) => tag.id === t.id) ||
							schedule.selectedTags.length === 0
						return (
							<Capsule
								key={t.tag}
								color={`#${t.color}`}
								className={`${isSelected ? "opacity-100" : "opacity-40"}`}
								onClick={() => handleSelectTag(t)}
								onDelete={() => handleRemoveTag(t, event)}
							>
								{t.tag}
							</Capsule>
						)
					})}
					<img
						src={plusIcon}
						alt="add tag"
						className="hidden cursor-pointer px-3 group-hover/tags:block"
						onClick={() => mainContext.setSheet(editSheet)}
					/>
				</div>
			</td>
		</TR>
	)
}

export default EventScrollableRow
