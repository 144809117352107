import useApi from "common/useApi"
import useLogout from "common/useLogout"
import withI18n, { I18nProps } from "common/withI18n"
import Alert from "components/Alert"
import Button from "components/Button"
import H2 from "components/H2"
import Header from "dashboard/Header"
import Switch from "dashboard/Switch"
import { memo, useState } from "react"
import store, { useDashSelector, useI18nSelector } from "store"
import { i18nActions } from "store/i18nSlice"
import logoutIcon from "./images/logout.svg"
import warningIcon from "./images/warning.svg"

const Profile = ({ t }: I18nProps) => {
	const eventGroupId = useDashSelector().eventGroupId
	const [showDeleteAlert, setShowDeleteAlert] = useState(false)
	const locale = useI18nSelector().locale
	const { logout } = useLogout()
	const apiWithToken = useApi()

	const performDelete = async () => {
		const api = await apiWithToken()

		if (api == null) {
			return
		}

		setShowDeleteAlert(false)

		try {
			await api.eventGroups.delete(eventGroupId)
			logout()
		} catch {}
	}

	return (
		<>
			<Header
				title={t("profile.title")}
				buttons={[
					{
						icon: logoutIcon,
						text: t("header.logout.button"),
						onClick: logout,
					},
				]}
			/>

			<div className="ml-[450px] mt-40 flex max-w-3xl flex-col gap-6">
				<H2>{t("profile.privacy.title")}</H2>
				<div className="flex gap-8 rounded-2xl bg-light-200 p-8">
					<Button
						className="w-[334px]"
						variant="outline"
						href="/privacy-policy"
					>
						{t("profile.privacy.policyButton")}
					</Button>
					<Button
						className="w-[334px]"
						variant="outline"
						onClick={async () => {
							try {
								const api = await apiWithToken()

								if (api == null) {
									return
								}

								await api.eventGroups.downloadAccountData(eventGroupId)
							} catch {}
						}}
					>
						English
					</Button>
				</div>
				<div className="flex items-center gap-8 rounded-2xl bg-red-300 bg-opacity-10 p-8">
					<img src={warningIcon} alt="" className="h-12 w-12" />
					<p>{t("profile.privacy.deleteAccount.disclaimer")}</p>
					<Button
						className="w-[220px] shrink-0 font-semibold text-red-300"
						variant="outline"
						color="destructive"
						onClick={() => setShowDeleteAlert(true)}
					>
						{t("profile.privacy.deleteAccount.button")}
					</Button>
				</div>
				<div className="relative flex items-center gap-16">
					<p className="w-20">{t("profile.language")}: </p>
					<div className="flex items-center gap-8">
						<span
							className={`cursor-pointer ${locale === "ro" ? "font-bold" : ""}`}
							onClick={() => store.dispatch(i18nActions.setLocale("ro"))}
						>
							Română
						</span>
						<Switch
							checked={locale !== "ro"}
							onChange={() =>
								store.dispatch(
									i18nActions.setLocale(locale === "ro" ? "en" : "ro")
								)
							}
						/>
						<span
							className={`cursor-pointer ${locale === "ro" ? "" : "font-bold"}`}
							onClick={() => store.dispatch(i18nActions.setLocale("en"))}
						>
							English
						</span>
					</div>
				</div>
				<Alert
					title={t("profile.alert.deleteAccount.title")}
					text={t("profile.alert.deleteAccount.text")}
					buttonTitle={t("profile.alert.deleteAccount.buttonTitle")}
					onClick={performDelete}
					open={showDeleteAlert}
					onClose={() => setShowDeleteAlert(false)}
				/>
			</div>
		</>
	)
}

export default memo(withI18n(Profile))
