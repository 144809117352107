/* eslint-disable @typescript-eslint/no-empty-function */
import { NotificationProps } from "components/Notification"
import { createContext, Dispatch, ReactNode, SetStateAction } from "react"

const MainContext = createContext<MainContextType>({
	setSheet: () => {},
	setNotification: () => {},
	setIsLoading: () => {},
})

export type MainContextType = {
	setSheet: Dispatch<SetStateAction<ReactNode | undefined>>
	setNotification: (props: NotificationProps) => void
	setIsLoading: Dispatch<SetStateAction<boolean>>
}

export default MainContext
