/* eslint-disable @typescript-eslint/no-empty-function */
import { createSelector } from "@reduxjs/toolkit"
import useApi from "common/useApi"
import withI18n, { I18nProps } from "common/withI18n"
import Alert from "components/Alert"
import EmptyState from "components/EmptyState"
import RadioButton from "components/RadioButton"
import SortableTH, { SortDirection } from "components/SortableTH"
import TH from "components/TH"
import TR from "components/TR"
import AddEditSheet from "dashboard/guests/AddEditSheet"
import GuestFixedRow from "dashboard/guests/GuestFixedRow"
import GuestScrollableRow from "dashboard/guests/GuestScrollableRow"
import { guestsActions, GuestsState } from "dashboard/guests/guestsSlice"
import { performFilter, performSort, Sort } from "dashboard/guests/helpers"
import setupActionsEffect from "dashboard/guests/setupActionsEffect"
import updateGuest from "dashboard/guests/updateGuest"
import Header from "dashboard/Header"
import { fixedSideContentWidth } from "dashboard/Layout"
import addIcon from "images/add.svg"
import Guest, { guestDTO, SortableField } from "models/Guest"
import Tag from "models/Tag"
import {
	memo,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react"
import store, { useDashSelector, useGuestSelector } from "store"
import MainContext from "store/context/MainContext"
import exportIcon from "./images/export.svg"
import noGuestIcon from "./images/noGuest.svg"

const selectSortedAndFilteredGuests = createSelector(
	(state: GuestsState) => ({
		sort: state.sort,
		selectedTags: state.selectedTags,
		guests: state.all,
	}),
	({ sort, selectedTags, guests }) => {
		let newGuests = performSort(sort, guests)

		if (selectedTags.length > 0) {
			newGuests = performFilter(selectedTags, newGuests)
		}

		return newGuests
	}
)

const Guests = ({ t }: I18nProps) => {
	const eventGroupId = useDashSelector().eventGroupId
	const guestsState = useGuestSelector()
	const guests = selectSortedAndFilteredGuests(guestsState)
	const [showDeleteAlert, setShowDeleteAlert] = useState(false)
	const [guestsToDelete, setGuestsToDelete] = useState<Guest[]>([])
	const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null)

	const setNotification = useContext(MainContext).setNotification
	const setSheet = useContext(MainContext).setSheet
	const apiWithToken = useApi()

	const confirmDelete = useCallback((guests: Guest[]) => {
		setGuestsToDelete(guests)
		setShowDeleteAlert(true)
		return false
	}, [])
	const handleDelete = useCallback(
		async (guests: Guest[]) => {
			const api = await apiWithToken()

			if (api == null) {
				return
			}

			// This is a multi-step optimistic process.
			const toDelete = [...guests]
			const initialContextGuests = [...guestsState.all]
			let failed = false

			// First we set the state with the final result.
			store.dispatch(guestsActions.clearSelection())
			store.dispatch(guestsActions.clearSort())
			store.dispatch(guestsActions.clearFilter())
			store.dispatch(guestsActions.remove(guestsToDelete))

			// We then try to delete each guest.
			for (const guest of guestsToDelete) {
				try {
					await api.guests.delete(guest.id, eventGroupId, guest.guestGroupId)
				} catch {
					failed = true
					// If any of the deletion fails, we remove that guest from `toDelete`.
					toDelete.splice(
						toDelete.findIndex((g) => g.id === guest.id),
						1
					)
				}
			}

			if (failed) {
				setNotification({
					type: "warning",
					title: t("notification.deleteFailure"),
				})
			} else {
				setNotification({
					type: "success",
					title: t("notification.deleteSuccess"),
				})
			}

			// After we tried to delete all guests, if the remaining list is not the same as the initial list, we set the state again.
			// This is to make sure that the failed calls are "inserted" back into the state at the correct positions.
			// Otherwise, if we'd try to insert the failed calls back into the list, we wouldn't be able to properly insert them at the old positions.
			if (toDelete.length === guestsToDelete.length) {
				setGuestsToDelete([])
				return
			}

			setGuestsToDelete([])

			store.dispatch(
				guestsActions.set(
					initialContextGuests.filter(
						(guest: Guest) => !toDelete.some((g) => guest.id === g.id)
					)
				)
			)
		},
		[
			eventGroupId,
			guestsState.all,
			guestsToDelete,
			setNotification,
			t,
			apiWithToken,
		]
	)

	//#region Sheets
	const addSheet = useMemo(
		() =>
			function TheAddSheet(t: I18nProps["t"]) {
				return (
					<AddEditSheet
						title={t("guests.sheet.add.title")}
						buttonTitle={t("guests.sheet.add.button.save")}
						action={(guest) => {
							store.dispatch(guestsActions.clearSelection())
							store.dispatch(guestsActions.clearSort())
							store.dispatch(guestsActions.clearFilter())
							store.dispatch(guestsActions.add([guest]))
						}}
					/>
				)
			},
		[]
	)
	const editSheet = useMemo(
		() =>
			function TheEditSheet(
				t: I18nProps["t"],
				guest: Guest = guestsState.selection[0]
			) {
				return (
					<AddEditSheet
						guest={guest}
						title={t("guests.sheet.edit.title")}
						buttonTitle={t("guests.sheet.edit.button.update")}
						action={(guest) => updateGuest(guest, guests)}
						// Doesn't work through `confirmDelete`. The `Alert` also doesn't want to show, so we had to put that inside.
						onDelete={() => handleDelete([guest])}
					/>
				)
			},
		[guests, guestsState.selection, handleDelete]
	)
	//#endregion

	//#region Actions
	const baseActions = useMemo(() => {
		const actions = [
			{
				text: t("guests.button.add"),
				icon: addIcon,
				onClick: () => setSheet(addSheet(t)),
			},
		]

		if (guests.length > 0) {
			actions.splice(0, 0, {
				text: t("guests.button.export"),
				icon: exportIcon,
				onClick: () => {},
			})
		}

		return actions
	}, [setSheet, addSheet, guests, t])
	const [actions, setActions] = useState(baseActions)

	useEffect(() => {
		setupActionsEffect(
			t,
			guestsState.all,
			guestsState.selection,
			confirmDelete,
			eventGroupId,
			setSheet,
			baseActions,
			setActions,
			() => editSheet(t)
		)
	}, [
		t,
		guestsState,
		guestsState.selection,
		baseActions,
		editSheet,
		setSheet,
		confirmDelete,
		eventGroupId,
		guests,
	])
	//#endregion

	//#region Sorting and Filtering

	/**
	 * Calculates the correct sort to be applied.
	 */
	const handleSort = (
		field: SortableField,
		direction: SortDirection = "asc"
	) => {
		let newSort: Sort

		if (guestsState.sort == null || guestsState.sort?.field !== field) {
			newSort = {
				field,
				direction,
			}
		} else if (guestsState.sort?.direction === "asc") {
			newSort = {
				field,
				direction: "desc",
			}
		} else {
			newSort = {
				field,
				direction: "asc",
			}
		}

		store.dispatch(guestsActions.sort(newSort))
	}

	/**
	 * Filters guests based on selected tags and applies the active sort after.
	 */
	const handleSelectTag = (tag: Tag) => {
		let tags: Tag[]

		if (guestsState.selectedTags.some((t) => t.id === tag.id)) {
			tags = guestsState.selectedTags.filter((t) => tag.id !== t.id)
		} else {
			tags = [...guestsState.selectedTags, tag]
		}

		if (tags.length === 0) {
			store.dispatch(guestsActions.clearFilter())
		} else {
			store.dispatch(guestsActions.filter(tags))
		}
	}
	//#endregion

	const handleRemoveTag = async (tag: Tag, guest: Guest) => {
		const api = await apiWithToken()

		if (api == null) {
			return
		}

		const newGuest = {
			...guest,
			tags: guest.tags.filter((t) => t.id !== tag.id),
		}

		const update = (guest: Guest) => updateGuest(guest, guests)

		try {
			const updatedGuest = await api.guests.update(
				guestDTO(newGuest),
				newGuest.id,
				eventGroupId,
				newGuest.guestGroupId
			)

			update(updatedGuest)
		} catch (e) {
			update(guest)
		}
	}

	if (guestsState.all.length === 0) {
		return (
			<div>
				<Header
					title={t("guests.title")}
					subtitle={{ text: t("guests.subtitle"), restrictWidth: true }}
					buttons={actions}
				/>
				<div className="mt-6 pr-9">
					<EmptyState
						title={t("guests.noContent.title")}
						icon={noGuestIcon}
						text={
							<p className="text-center">
								<span>{t("guests.noContent.text.1")}</span>
								<br />
								<span>{t("guests.noContent.text.2")}</span>
							</p>
						}
						button={{
							text: t("guests.noContent.button"),
							onClick: () => setSheet(addSheet(t)),
						}}
					/>
				</div>
			</div>
		)
	}

	return (
		<>
			<Alert
				title={t(
					guestsState.selection.length > 1
						? "guests.alert.delete.confirmation.title.plural"
						: "guests.alert.delete.confirmation.title"
				)}
				text={`${t(
					guestsState.selection.length > 1
						? "guests.alert.delete.confirmation.text.plural"
						: "guests.alert.delete.confirmation.text"
				)} ${t("cannotBeUndone")}`}
				buttonTitle={t(
					guestsState.selection.length > 1
						? "guests.alert.delete.confirmation.button.plural"
						: "guests.alert.delete.confirmation.button"
				)}
				onClick={() => {
					setShowDeleteAlert(false)
					handleDelete(guestsToDelete)
					setSheet(null)
				}}
				open={showDeleteAlert}
				onClose={() => {
					setShowDeleteAlert(false)
					setGuestsToDelete([])
				}}
			/>

			<Header
				title={t("guests.title")}
				subtitle={{ text: t("guests.subtitle"), restrictWidth: true }}
				buttons={actions}
			/>

			<div className="overflow-y-scroll">
				<div className="flex pb-24">
					<div className={`w-[${fixedSideContentWidth}px] shrink-0`}>
						<table>
							<thead>
								<TR>
									<TH>
										<RadioButton
											checked={
												guestsState.selection.length === guests.length &&
												guests.length > 0
											}
											onClick={() => {
												if (guestsState.selection.length === guests.length) {
													store.dispatch(guestsActions.clearSelection())
												} else {
													store.dispatch(guestsActions.setSelection(guests))
												}
											}}
										/>
									</TH>
									<th
										scope="col"
										className="text-center font-semibold opacity-60"
									>
										{guests.length}
									</th>
									<SortableTH
										label={t("guests.column.firstName")}
										direction={
											guestsState.sort?.field === "firstName"
												? guestsState.sort?.direction
												: undefined
										}
										onClick={() => handleSort("firstName")}
									/>
									<SortableTH
										label={t("guests.column.lastName")}
										direction={
											guestsState.sort?.field === "lastName"
												? guestsState.sort?.direction
												: undefined
										}
										onClick={() => handleSort("lastName")}
									/>
								</TR>
							</thead>
							<tbody>
								{guests.map((guest, i) => (
									<GuestFixedRow
										key={guest.id}
										isHovered={i === hoveredRowIndex}
										setIsHovered={(flag: boolean) =>
											setHoveredRowIndex(flag ? i : null)
										}
										guest={guest}
										selection={guestsState.selection}
										setSelection={(selection: Guest[]) =>
											selection.length === 0
												? store.dispatch(guestsActions.clearSelection())
												: store.dispatch(guestsActions.setSelection(selection))
										}
										editSheet={editSheet(t, guest)}
									/>
								))}
							</tbody>
						</table>
					</div>
					<div className="w-full overflow-x-scroll">
						<table>
							<thead>
								<TR>
									<SortableTH
										label={t("guests.column.email")}
										direction={
											guestsState.sort?.field === "email"
												? guestsState.sort?.direction
												: undefined
										}
										onClick={() => handleSort("email")}
									/>
									<SortableTH
										label="+1s"
										direction={
											guestsState.sort?.field === "plusOnes"
												? guestsState.sort?.direction
												: undefined
										}
										onClick={() => handleSort("plusOnes")}
									/>
									<TH className="opacity-40">{t("guests.column.tags")}</TH>
								</TR>
							</thead>
							<tbody>
								{guests.map((guest, i) => (
									<GuestScrollableRow
										key={guest.id}
										isHovered={i === hoveredRowIndex}
										setIsHovered={(flag: boolean) =>
											setHoveredRowIndex(flag ? i : null)
										}
										guest={guest}
										selectedTags={guestsState.selectedTags}
										setSheet={setSheet}
										editSheet={() => editSheet(t, guest)}
										handleRemoveTag={handleRemoveTag}
										handleSelectTag={handleSelectTag}
									/>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	)
}

export default memo(withI18n(Guests))
