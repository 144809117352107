import Capsule from "components/Capsule"
import TR from "components/TR"
import plusIcon from "images/icons/plus.svg"
import Guest from "models/Guest"
import Tag from "models/Tag"
import { memo } from "react"
import { MainContextType } from "store/context/MainContext"

const GuestScrollableRow = ({
	isHovered,
	setIsHovered,
	guest,
	selectedTags,
	setSheet,
	editSheet,
	handleSelectTag,
	handleRemoveTag,
}: {
	isHovered: boolean
	setIsHovered: (isHovered: boolean) => void
	guest: Guest
	selectedTags: Tag[]
	setSheet: MainContextType["setSheet"]
	editSheet: (guest: Guest) => JSX.Element
	handleSelectTag: (tag: Tag) => void
	handleRemoveTag: (tag: Tag, guest: Guest) => void
}): JSX.Element => (
	<TR
		key={guest.id}
		className={`cursor-pointer ${isHovered ? "bg-dark-100 bg-opacity-5" : ""}`}
		onClick={() => setSheet(editSheet(guest))}
		onMouseEnter={() => setIsHovered(true)}
		onMouseLeave={() => setIsHovered(false)}
	>
		<td className="w-1 min-w-[260px] max-w-[260px] overflow-x-scroll">
			{guest.sortableFields.email}
		</td>
		<td className="w-1">
			{guest.sortableFields.plusOnes === "0"
				? ""
				: guest.sortableFields.plusOnes}
		</td>
		<td className="group/tags w-[99%]">
			<div className="flex shrink-0 grow items-center gap-2 pr-20 group-hover/tags:pr-10">
				{[...guest.tags]
					.sort((a, b) => (a.tag < b.tag ? -1 : 1))
					.map((t) => {
						const isSelected =
							selectedTags.some((tag) => tag.id === t.id) ||
							selectedTags.length === 0

						return (
							<Capsule
								key={t.tag}
								className={`${isSelected ? "opacity-100" : "opacity-40"}`}
								color={`#${t.color}`}
								onClick={() => handleSelectTag(t)}
								onDelete={() => handleRemoveTag(t, guest)}
							>
								{t.tag}
							</Capsule>
						)
					})}

				<img
					src={plusIcon}
					alt="add tag"
					className={`hidden cursor-pointer px-3 group-hover/tags:block`}
					onClick={() => setSheet(editSheet(guest))}
				/>
			</div>
		</td>
	</TR>
)

export default memo(GuestScrollableRow)
