import clsx from "clsx"
import sortAscIcon from "images/icons/sort-asc.svg"
import sortDescIcon from "images/icons/sort-desc.svg"
import sortIcon from "images/icons/sort.svg"
import { memo } from "react"

// eslint-disable-next-line import/exports-last
export type SortDirection = "asc" | "desc" | undefined

const SortableTH = ({
	className,
	label,
	direction,
	onClick,
}: {
	className?: string
	label: string
	direction: SortDirection
	onClick?: () => void
}) => (
	<th scope="col" className={clsx("whitespace-nowrap p-5", className)}>
		<div
			className={`flex gap-1 ${onClick == null ? "" : "cursor-pointer"}`}
			onClick={onClick}
		>
			<span className="opacity-40">{label}</span>
			<img
				src={
					direction === "asc"
						? sortAscIcon
						: direction === "desc"
						  ? sortDescIcon
						  : sortIcon
				}
			/>
		</div>
	</th>
)

export default memo(SortableTH)
