import { createSelector } from "@reduxjs/toolkit"
import useApi from "common/useApi"
import withI18n, { I18nProps } from "common/withI18n"
import Alert from "components/Alert"
import Capsule from "components/Capsule"
import { dashActions } from "dashboard/dashSlice"
import { GuestsState } from "dashboard/guests/guestsSlice"
import closeIcon from "images/icons/close-small.svg"
import deleteIcon from "images/icons/delete-small.svg"
import plusIcon from "images/icons/plus.svg"
import Tag from "models/Tag"
import { memo, useState } from "react"
import store, { useDashSelector, useGuestSelector } from "store"

const selectNumberGuestsForTag = createSelector(
	(state: GuestsState, tag: Tag | null) => ({
		guests: state.all,
		tag,
	}),
	({ guests, tag }) =>
		tag == null
			? 0
			: guests.filter((g) => g.tags.some((t) => t.id === tag.id)).length
)

const Tags = ({
	t,
	tags,
	selectedTags,
	deletableTags = true,
	canAddTags = true,
	onSelectTag,
	onDeselectTag,
}: I18nProps & {
	tags?: Tag[]
	selectedTags: Tag[]
	deletableTags?: boolean
	canAddTags?: boolean
	onSelectTag: (tag: Tag) => void
	onDeselectTag: (tag: Tag) => void
}): JSX.Element => {
	const { tags: _allTags, eventGroupId } = useDashSelector()
	const [tagToAdd, setTagToAdd] = useState<string | null>(null)
	const [tagToDelete, setTagToDelete] = useState<Tag | null>(null)
	const [showDeleteAlert, setShowDeleteAlert] = useState(false)
	const guests = useGuestSelector()
	const numberOfGuestsForTag = selectNumberGuestsForTag(guests, tagToDelete)
	const allTags = tags ?? _allTags
	const apiWithToken = useApi()

	const deleteTag = async () => {
		const api = await apiWithToken()

		if (tagToDelete == null || api == null) {
			return
		}

		setShowDeleteAlert(false)

		try {
			await api.tags.delete(tagToDelete.id, eventGroupId)

			store.dispatch(dashActions.removeTags([tagToDelete]))
		} catch {}
	}

	const showDeleteAlertForTag = (tag: Tag) => {
		setTagToDelete(tag)
		setShowDeleteAlert(true)
	}

	const addTag = async () => {
		if (allTags.find((t) => t.tag === tagToAdd) != null) {
			setTagToAdd(null)
			return
		}

		const api = await apiWithToken()

		if (tagToAdd == null || tagToAdd === "" || api == null) {
			return
		}

		// Optimistic approach
		const oldTags = allTags
		const newTag = {
			id: `${Date.now()}`,
			tag: tagToAdd,
			color: "bg-gray-disabled",
		}

		store.dispatch(dashActions.addTags([newTag]))

		try {
			const result = await api.tags.create(tagToAdd, eventGroupId)
			store.dispatch(dashActions.setTags([...oldTags, result]))
		} catch {
			store.dispatch(dashActions.setTags(oldTags))
		}

		setTagToAdd(null)
	}

	return (
		<div className="w-full">
			<Alert
				title={
					<span>
						{t("tags.button.delete")}{" "}
						<span className="font-semibold italic">{tagToDelete?.tag}?</span>
					</span>
				}
				text={
					<p>
						{t("tags.deleteAlert.message.1")}{" "}
						<span className="font-bold">
							{numberOfGuestsForTag}{" "}
							{numberOfGuestsForTag === 1 ? t("guest") : t("guests")}
						</span>
						{t("tags.deleteAlert.message.2")}
					</p>
				}
				buttonTitle={t("tags.deleteAlert.button")}
				onClick={deleteTag}
				open={showDeleteAlert}
				onClose={() => setShowDeleteAlert(false)}
			/>

			<div className="mb-8 w-full">
				<h4 className="text-center font-semibold text-gray-600">
					{t("tags.selectedTitle")}
				</h4>
				<div className="mt-4 flex gap-2 overflow-scroll pb-4">
					{selectedTags.length === 0 ? (
						<span className="w-full text-center italic text-dark-100 opacity-40">
							{t("tags.noTagSelected")}
						</span>
					) : (
						[...selectedTags]
							.sort((a, b) => (a.tag < b.tag ? -1 : 1))
							.map((t) => {
								return (
									<Capsule
										key={t.tag}
										color={`#${t.color}`}
										className="h-10"
										onDelete={() => onDeselectTag(t)}
									>
										{t.tag}
									</Capsule>
								)
							})
					)}
				</div>
			</div>

			<div className="mb-12 w-full">
				<h4 className="text-center text-gray-500">{t("tags.allTitle")}</h4>
				<div className="mt-2 flex gap-x-2 gap-y-3 overflow-scroll py-4">
					{[...allTags]
						.filter((tag) => !selectedTags.some((t) => t.tag === tag.tag))
						.sort((a, b) => (a.tag < b.tag ? -1 : 1))
						.map((t) => {
							return (
								<Capsule
									key={t.tag}
									className="peer/capsule bg-gray-disabled h-10"
									icon={{ src: deleteIcon, alt: "Delete Tag" }}
									onClick={() => onSelectTag(t)}
									onDelete={
										deletableTags ? () => showDeleteAlertForTag(t) : undefined
									}
								>
									{t.tag}
								</Capsule>
							)
						})}

					{tagToAdd != null ? (
						<div className="flex gap-x-2">
							<input
								className="h-10 w-40 rounded-3xl px-4 ml-1"
								value={tagToAdd}
								onChange={(e) => setTagToAdd(e.target.value)}
								onKeyUp={(e) => {
									if (e.key !== "Enter") {
										return
									}

									addTag()
								}}
							/>
							<img
								src={plusIcon}
								alt="save tag"
								className="cursor-pointer"
								onClick={addTag}
							/>
							<img
								src={closeIcon}
								alt="cancel add tag"
								className="cursor-pointer"
								onClick={() => setTagToAdd(null)}
							/>
						</div>
					) : (
						<img
							src={plusIcon}
							alt="add tag"
							className={`cursor-pointer pr-16 ${canAddTags ? "" : "hidden"} ${
								deletableTags ? "peer-hover/capsule:pr-9" : ""
							}`}
							onClick={() => setTagToAdd("")}
						/>
					)}
				</div>
			</div>
		</div>
	)
}

export default memo(withI18n(Tags))
