import { ReactNode } from "react"

const CardInfo = ({
	value,
	label1,
	label2,
	tooltip,
	fadedValue = false,
	semiboldedValue = true,
}: CardData) => {
	return (
		<div title={tooltip} className="flex grow flex-col items-center gap-4">
			<span
				className={`font-title text-4xl ${
					semiboldedValue ? "font-semibold" : "font-bold"
				} ${fadedValue ? "opacity-50" : ""}`}
			>
				{value}
			</span>
			<span className="flex flex-col items-center opacity-70">
				<span>{label1}</span>
				<span>{label2}</span>
			</span>
		</div>
	)
}

export type CardData = {
	value: number
	label1: ReactNode
	label2: ReactNode
	tooltip?: string
	fadedValue?: boolean
	semiboldedValue?: boolean
}

export default CardInfo
