import Guest, {
	RawGuest,
	processGuests,
	processGuest,
	GuestDTO,
	SplitGroupSliceDTO,
	SplitGroupResponse,
} from "models/Guest"
import { perform } from "utils/api/perform"

const eventPath = (eventGroupId: string) => `/event_groups/${eventGroupId}`
const groupPath = (eventGroupId: string) =>
	`${eventPath(eventGroupId)}/guest_groups`
const guestPath = (eventGroupId: string, groupId: string) =>
	`${groupPath(eventGroupId)}/${groupId}/guests`

/**
 * `/event_groups/:id`
 */
const eventRoot = (eventGroupId: string) => perform(eventPath(eventGroupId))
/**
 * `/event_groups/:id/guest_groups`
 * */
const groupRoot = (eventGroupId: string) => perform(groupPath(eventGroupId))
/**
 * `/event_groups/:id/guest_groups/:id/guests`
 * */
const guestRoot = (eventGroupId: string, groupId: string) =>
	perform(guestPath(eventGroupId, groupId))

const list = async (eventGroupId: string): Promise<Guest[]> => {
	const guests = await eventRoot(eventGroupId)<RawGuest[]>("get", "/guests")

	return processGuests(guests)
}

const get = async (
	eventGroupId: string,
	groupId: string,
	id: string
): Promise<Guest> => {
	const guest = await guestRoot(eventGroupId, groupId)<RawGuest>(
		"get",
		`/${id}`
	)

	return processGuest(guest)
}

const update = async (
	guest: GuestDTO,
	id: string,
	eventGroupId: string,
	groupId: string
): Promise<Guest> => {
	const newGuest = await guestRoot(eventGroupId, groupId)<RawGuest>(
		"put",
		`/${id}`,
		{ ...guest }
	)

	return processGuest(newGuest)
}

const create = async (
	guest: GuestDTO,
	eventGroupId: string
): Promise<Guest> => {
	const guests = await groupRoot(eventGroupId)<RawGuest>("post", "/", {
		...guest,
	})

	return processGuest(guests)
}

const mergeGroup = async (
	guestIds: string[],
	groupId: string,
	eventGroupId: string
): Promise<void> =>
	groupRoot(eventGroupId)("put", `/${groupId}/merge`, {
		otherGuestIds: guestIds,
	})

const splitGroup = async (
	slices: SplitGroupSliceDTO[],
	groupId: string,
	eventGroupId: string
): Promise<SplitGroupResponse> => {
	return await groupRoot(eventGroupId)<SplitGroupResponse>(
		"put",
		`/${groupId}/split`,
		{
			slices: slices,
		}
	)
}

const _delete = async (
	id: string,
	eventGroupId: string,
	groupId: string
): Promise<void> => guestRoot(eventGroupId, groupId)("delete", `/${id}`)

export default {
	create,
	list,
	get,
	update,
	mergeGroup,
	splitGroup,
	delete: _delete,
}
