/* eslint-disable import/no-unassigned-import */
import App from "common/App"
import * as locales from "date-fns/locale"
import { registerLocale, setDefaultLocale } from "react-datepicker"
import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { AppConfig } from "utils/envSetup"
import "react-datepicker/dist/react-datepicker.css"
import "common/index.css"
import initGA from "utils/GA/initGA"

initGA()

// There's no fucking way to get the current locale from the browser
// and set it as the default locale for date-fns/DatePicker, so we have to
// import them all, find the current locale, and then register it.
if (AppConfig.isDev) {
	const code = locales.ro.code ?? "ro-RO"
	registerLocale(code, locales.ro)
	setDefaultLocale(code)
} else {
	const defaultLocale = "en-GB"
	type Locale = keyof typeof locales
	const localeKey =
		Object.keys(locales).find(
			(key) =>
				// eslint-disable-next-line import/namespace
				locales[key as Locale].code === window.navigator.language ??
				defaultLocale
		) ?? defaultLocale
	// eslint-disable-next-line import/namespace
	const locale = locales[localeKey as Locale]

	if (locale.code != null) {
		registerLocale(locale.code, locale)
		setDefaultLocale(AppConfig.isProd ? window.navigator.language : locale.code)
	}
}

const app = (
	<BrowserRouter>
		<App />
	</BrowserRouter>
)

const container = document.getElementById("root")

if (container != null) {
	createRoot(container).render(app)
}

if (!AppConfig.isProd && module.hot) {
	module.hot?.accept()
}
