import Tag from "models/Tag"

const groupColor = (() => {
	let i = 0
	let oldId = ""

	return (id: string) => {
		if (oldId !== id) {
			oldId = id
			// Generate a unique number from the id, so the colors are consistent on reloads and when grouping and ungrouping.
			i = parseInt(id)
		}

		return `${
			[
				"ring-red-500",
				"ring-blue-500",
				"ring-teal-500",
				"ring-yellow-500",
				"ring-purple-500",
				"ring-pink-500",
				"ring-gray-500",
				"ring-green-500",
				"ring-indigo-500",
				"ring-orange-500",
				"ring-cyan-500",
			][i % 11]
		}`
	}
})()

type Guest = Omit<RawGuest, "details" | "email"> & {
	pictureUrl?: string
	hasGroup: boolean
	groupColor?: string
	sortableFields: {
		[sortableField in SortableField]: string
	}
}

export type RawGuest = {
	id: string
	email?: string
	details: {
		firstName: string
		lastName: string
		pictureUrl?: string
	}
	groupLead: boolean
	guestGroupId: string
	tags: Tag[]
}

/**
 * Moves some fields around and removes others, to make the guest
 * object easier to use in the app.
 */
export const processGuest = (guest: RawGuest): Guest => {
	const g: Guest = {
		...guest,
		hasGroup: false,
		pictureUrl: guest.details.pictureUrl,
		sortableFields: {
			firstName: guest.details.firstName,
			lastName: guest.details.lastName,
			email: guest.email ?? "",
			plusOnes: "0",
		},
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	delete (g as any).details
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	delete (g as any).email

	return g
}

/**
 * Converts the given `RawGuest` data into `Guest` objects. This calls {@link processGuest} for each item and it correctly sets the `hasGroup` and `plusOnes` fields. It then sorts the guests by groups.
 * It also accepts an array of `Guest` objects, in which case it will skip the conversion.
 */
export const processGuests = (guests: RawGuest[] | Guest[]): Guest[] => {
	let previousId = ""
	let members = 0

	return guests
		.map((g) => {
			const guest = "sortableFields" in g ? { ...g } : processGuest(g)
			// Don't filter on every iteration.
			members =
				guest.guestGroupId === previousId
					? members
					: (guests as RawGuest[]).filter(
							(m) => m.guestGroupId === guest.guestGroupId
					  ).length
			previousId = guest.guestGroupId

			// Each guest is created with a guest group ID, but if there are no other guests with that group ID, then it's a single guest.
			if (members > 1) {
				guest.hasGroup = true
				guest.groupColor = groupColor(guest.guestGroupId)

				// We also need to check that the guest is the lead of the group, to set the `plusOnes`.
				if (guest.groupLead) {
					guest.sortableFields.plusOnes = (members - 1).toString()
				}
			} else {
				guest.hasGroup = false
				guest.groupColor = undefined
				guest.sortableFields.plusOnes = ""
			}

			return guest
		})
		.sort((a, b) => {
			if (a.hasGroup && !b.hasGroup) {
				return -1
			} else if (!a.hasGroup && b.hasGroup) {
				return 1
			} else if (a.guestGroupId === b.guestGroupId) {
				return a.groupLead ? -1 : 1
			} else {
				return a.guestGroupId.localeCompare(b.guestGroupId)
			}
		})
}

export type SortableField = "firstName" | "lastName" | "email" | "plusOnes"

export type GuestDTO = {
	firstName?: string
	lastName?: string
	email?: string
	pictureUrl?: string
	tags: string[]
	groupLead: boolean
}

export type SplitGroupSliceDTO = {
	groupLeadId?: string
	guestIds: string[]
}

export type SplitGroupResponse = (SplitGroupSliceDTO & {
	guestGroupId: string
})[]

export const guestDTO = (guest: Guest): GuestDTO => ({
	firstName: guest.sortableFields.firstName,
	lastName: guest.sortableFields.lastName,
	email: guest.sortableFields.email,
	pictureUrl: guest.pictureUrl,
	tags: guest.tags.map((t) => t.tag),
	groupLead: guest.groupLead,
})

export default Guest
