import clsx from "clsx"

const Switch = ({
	checked,
	onChange,
	className,
}: {
	checked: boolean
	onChange: (value: boolean) => void
	className?: string
}): JSX.Element => (
	<div
		className={clsx(
			"flex h-8 w-14 items-center rounded-3xl bg-white",
			className
		)}
		onClick={(e) => {
			e.stopPropagation()
			onChange(!checked)
		}}
	>
		<div
			className={`h-6 w-6 rounded-full transition-all duration-300 ease-out ${
				checked ? "translate-x-7 bg-orange-300" : "ml-1 bg-gray-300"
			}`}
		></div>
	</div>
)

export default Switch
