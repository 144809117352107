import withI18n, { I18nProps } from "common/withI18n"
import { memo } from "react"
import { useLocation, Link as RLink, useNavigate } from "react-router-dom"
import R from "routes/R"
import { useGuestSelector, useScheduleSelector } from "store"

const imageForRoute = (route: string, isActive: boolean) => {
	switch (route) {
		case R.dashboard:
			return isActive
				? require("./images/dashboard-active.svg")
				: require("./images/dashboard.svg")
		case R.guests:
			return isActive
				? require("./images/guests-active.svg")
				: require("./images/guests.svg")
		case R.comms:
			return isActive
				? require("./images/comms-active.svg")
				: require("./images/comms.svg")
		case R.schedule:
			return isActive
				? require("./images/schedule-active.svg")
				: require("./images/schedule.svg")
		case R.design:
			return isActive
				? require("./images/design-active.svg")
				: require("./images/design.svg")
		case R.pages:
			return isActive
				? require("./images/pages-active.svg")
				: require("./images/pages.svg")
		case R.settings:
			return isActive
				? require("./images/settings-active.svg")
				: require("./images/settings.svg")
	}
}

const titleForRoute = (route: string, t: I18nProps["t"]) => {
	switch (route) {
		case R.guests:
			return t("sidebar.guests")
		case R.comms:
			return t("sidebar.comms")
		case R.schedule:
			return t("sidebar.schedule")
		case R.design:
			return t("sidebar.design")
		case R.pages:
			return t("sidebar.pages")
		case R.settings:
			return t("sidebar.settings")
		case R.profile:
			return t("sidebar.profile")
		default:
			return t("sidebar.dashboard")
	}
}

const Link = ({
	to,
	disabledReason,
	t,
}: {
	to: string
	disabledReason?: string
	t: I18nProps["t"]
}) => {
	const location = useLocation()
	const isActive = location.pathname === to
	const title = titleForRoute(to, t)
	const navigate = useNavigate()

	return (
		<div
			className={`${
				disabledReason != null
					? "cursor-not-allowed opacity-30"
					: "cursor-pointer"
			}`}
			onClick={disabledReason != null ? undefined : () => navigate(to)}
		>
			<div
				className={`flex h-20 w-20 items-center justify-center text-[10px] ${
					isActive ? "rounded-3xl bg-light-100 font-semibold" : ""
				}`}
				title={disabledReason}
			>
				<div className="flex flex-col items-center gap-3">
					<img
						className="h-7 w-7"
						src={imageForRoute(to, isActive).default}
						alt={title}
					/>
					{title}
				</div>
			</div>
		</div>
	)
}

const Links = ({ t }: I18nProps) => {
	const guests = useGuestSelector().all
	const events = useScheduleSelector().events

	return (
		<>
			<Link to={R.dashboard} t={t} />
			<Link to={R.guests} t={t} />
			<Link to={R.schedule} t={t} />
			<Link to={R.design} t={t} />
			<Link to={R.pages} t={t} />
			<Link
				to={R.comms}
				t={t}
				disabledReason={
					guests.length === 0 || events.length === 0
						? t("dashboard.sidebar.disabledRSVPs")
						: undefined
				}
			/>
			<Link to={R.settings} t={t} />

			<RLink to={R.profile}>
				<img
					className="mt-10 h-12 w-12 rounded-full bg-gray-500"
					src={require("images/avatars/avatar-1.png")}
				/>
			</RLink>
		</>
	)
}

export default memo(withI18n(Links))
