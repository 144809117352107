import { AppConfig } from "utils/envSetup"
import gtag from "utils/GA/gtag"

let didInit = false

const initGA = () => {
	if (didInit) {
		return
	}

	didInit = true

	const id = AppConfig.isProd ? "G-C9LK4YMPD3" : "G-XZNFXDYPXE"

	const defScript = document.createElement("script")
	defScript.innerHTML = `
		window.dataLayer = window.dataLayer || []
		function gtag(){dataLayer.push(arguments)}
	`

	document.head.insertBefore(defScript, document.head.firstChild)

	const gtagScript = document.createElement("script")
	gtagScript.async = true
	gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${id}`

	document.head.insertBefore(gtagScript, document.head.firstChild)

	// This only works on a real domain
	if (AppConfig.isProd) {
		const cookiebotScript = document.createElement("script")
		cookiebotScript.id = "Cookiebot"
		cookiebotScript.src = "https://consent.cookiebot.com/uc.js"
		cookiebotScript.setAttribute(
			"data-cbid",
			"b6331e39-588c-434d-8162-dcf8b888a566"
		)
		cookiebotScript.setAttribute("data-blockingmode", "auto")

		document.head.insertBefore(cookiebotScript, document.head.firstChild)
	}

	gtag.raw("js", new Date())

	gtag.config(id, {
		store_gac: false,
		debug_mode: false,
	})

	gtag.set({
		adSenseId: 0,
		allowAdFeatures: false,
	})

	gtag.pageview()
}

export default initGA
