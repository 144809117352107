import { Auth0Provider, AppState } from "@auth0/auth0-react"
import { ReactNode } from "react"
import { useNavigate } from "react-router-dom"
import { AppConfig } from "utils/envSetup"

const Auth0ProviderWithHistory = ({ children }: { children: ReactNode }) => {
	const navigate = useNavigate()

	const onRedirectCallback = (appState: AppState | undefined) => {
		navigate(appState?.returnTo ?? window.location.pathname)
	}

	return (
		<Auth0Provider
			domain={"dev-y94jfa9e.us.auth0.com"}
			clientId={AppConfig.auth0ClientId}
			authorizationParams={{
				audience,
				redirect_uri: `${AppConfig.homeURL}/dashboard`,
			}}
			useRefreshTokens
			cacheLocation="localstorage"
			onRedirectCallback={onRedirectCallback}
		>
			{children}
		</Auth0Provider>
	)
}

export const audience = "https://api.eventa.rsvp"

export default Auth0ProviderWithHistory
