import { I18nProps } from "common/withI18n"
// import { guestsActions } from "dashboard/guests/guestsSlice"
// import mergeGroups from "dashboard/guests/mergeGroups"
// import splitGroup from "dashboard/guests/splitGroup"
// import Guest, { guestDTO } from "models/Guest"
import Guest from "models/Guest"
// import store from "store"
import { MainContextType } from "store/context/MainContext"
// import api from "utils/api"
import deleteIcon from "./images/delete.svg"
// import exportIcon from "./images/export.svg"
// import leadIcon from "./images/lead.svg"
import tagsIcon from "./images/tags.svg"

type Action = {
	text: string
	icon: string
	onClick: () => void
}

// const makeGroupLead = async (
// 	guest: Guest,
// 	rootGuests: Guest[],
// 	eventGroupId: string
// ) => {
// 	try {
// 		const result = await api.guests.update(
// 			{
// 				...guestDTO(guest),
// 				groupLead: true,
// 			},
// 			guest.id,
// 			eventGroupId,
// 			guest.guestGroupId
// 		)
// 		const newGuest = {
// 			...result,
// 			hasGroup: guest.hasGroup,
// 			groupColor: guest.groupColor,
// 			sortableFields: {
// 				...result.sortableFields,
// 				plusOnes: `${
// 					rootGuests.filter((g) => g.guestGroupId === guest.guestGroupId)
// 						.length - 1
// 				}`,
// 			},
// 		}

// 		store.dispatch(guestsActions.update(newGuest))
// 	} catch {}
// }

const setupActionsEffect = (
	t: I18nProps["t"],
	rootGuests: Guest[],
	selection: Guest[],
	handleDelete: (guests: Guest[]) => void,
	eventGroupId: string,
	setSheet: MainContextType["setSheet"],
	baseActions: Action[],
	setActions: (actions: Action[]) => void,
	editSheet: () => JSX.Element
	// eslint-disable-next-line sonarjs/cognitive-complexity
) => {
	const commonSelectionActions = [
		// {
		// 	text: t("guests.button.exportSelection"),
		// 	icon: exportIcon,
		// 	// eslint-disable-next-line @typescript-eslint/no-empty-function
		// 	onClick: () => {},
		// },
		{
			text: t("guests.button.deleteSelection"),
			icon: deleteIcon,
			onClick: () => handleDelete(selection),
		},
	]

	let actions: { text: string; icon: string; onClick: () => void }[] = []

	// const splitAction = splitGroup(
	// 	t("guests.button.splitGroup"),
	// 	rootGuests,
	// 	selection,
	// 	eventGroupId
	// )

	if (selection.length === 0) {
		actions = baseActions
	} else if (selection.length === 1) {
		actions = [
			{
				text: t("guests.button.assignTags"),
				icon: tagsIcon,
				onClick: () => setSheet(editSheet()),
			},
		]

		// const guest = selection[0]

		// if (guest.hasGroup) {
		// 	if (!guest.groupLead) {
		// 		actions.push({
		// 			text: t("guests.button.makeGroupLead"),
		// 			icon: leadIcon,
		// 			onClick: async () => makeGroupLead(guest, rootGuests, eventGroupId),
		// 		})
		// 	}

		// 	actions.push(splitAction)
		// }

		actions.push(
			{
				text: t("guests.button.edit"),
				icon: tagsIcon,
				onClick: () => setSheet(editSheet()),
			},
			...commonSelectionActions
		)
	} else {
		// const mergeAction = (type: "create" | "add") =>
		// mergeGroups(type, selection, eventGroupId)

		// if (selection.some((g) => !g.hasGroup)) {
		// 	actions.push(mergeAction("create"))

		// 	const groupedGuests = selection.filter((g) => g.hasGroup)

		// 	if (
		// 		selection.some((g) => g.hasGroup) &&
		// 		groupedGuests
		// 			.map((g) => g.guestGroupId)
		// 			.reduce((acc, groupId) => {
		// 				return acc === groupId ? acc : undefined
		// 			}, groupedGuests[0].guestGroupId as string | undefined)
		// 	) {
		// 		actions.push(mergeAction("add"))
		// 	}
		// }

		// if (selection.some((g) => g.hasGroup)) {
		// 	actions.push(splitAction)
		// }

		actions.push(...commonSelectionActions)
	}

	setActions(actions)
}

export default setupActionsEffect
