import clsx from "clsx"
import Loader from "components/Loader"
import closeIcon from "images/icons/close.svg"

const Popup = ({
	title,
	className,
	children,
	isLoading = false,
	onClose,
}: {
	title: string
	className?: string
	children: React.ReactNode
	isLoading?: boolean
	onClose: () => void
}): JSX.Element => (
	<div
		className="fixed inset-0 z-1 h-full w-full bg-dark-100 bg-opacity-30"
		onClick={onClose}
	>
		<style>{`html { overflow: hidden; }`}</style>

		<div className="flex h-full justify-center">
			<div className="flex h-full flex-col justify-center">
				<div
					className={clsx(
						className,
						"flex transform flex-col overflow-clip rounded-3xl bg-light-100 text-left shadow-xl"
					)}
					onClick={(e) => e.stopPropagation()}
				>
					<div className={`${isLoading ? "hidden" : "h-[85%]"}`}>
						<div className="justify-between px-24 py-8">
							<img
								className="absolute left-8 -mt-2 cursor-pointer"
								src={closeIcon}
								onClick={onClose}
							/>
							<h1 className="mx-auto text-center text-2xl">{title}</h1>
						</div>
						{children}
					</div>

					<div
						className={
							isLoading
								? "flex h-full flex-col items-center justify-center"
								: "hidden"
						}
					>
						<Loader />
					</div>
				</div>
			</div>
		</div>
	</div>
)

export default Popup
