import H2 from "components/H2"
import HLine from "components/HLine"
import { ReactNode } from "react"

const SheetSection = ({
	title,
	fullWidth = false,
	hideBottomLine = false,
	children,
}: {
	title: string
	fullWidth?: boolean
	hideBottomLine?: boolean
	children: ReactNode
}) => (
	<div className="mt-9 flex w-full flex-col items-center">
		<H2>{title}</H2>

		<div
			className={`${
				children == null ? "mt-1" : "mt-8"
			} mb-9 flex flex-col gap-6 ${fullWidth ? "w-full" : "mx-auto w-[360px]"}`}
		>
			{children}
		</div>

		{hideBottomLine ? null : <HLine />}
	</div>
)

export default SheetSection
