import event from "utils/api/event"
import eventGroups from "utils/api/eventGroups"
import events from "utils/api/events"
import faq from "utils/api/faq"
import guests from "utils/api/guests"
import { perform, setToken } from "utils/api/perform"
import rsvpInvitation from "utils/api/rsvpInvitation"
import rsvpQuestions from "utils/api/rsvpQuestions"
import tags from "utils/api/tags"

const api = {
	users: {
		setToken,
		setupAccount: async (hash: string, password: string) => {
			return perform(`/auth0-onboarding-user`, undefined, hash)("post", "/", {
				password,
			})
		},
		decodeOnboardingHash: async (hash: string) => {
			return perform(
				`/auth0-onboarding-user`,
				undefined,
				hash
			)<{ email: string }>("get", "/")
		},
	},
	eventGroups,
	tags,
	event,
	events,
	guests,
	pages: {
		faq,
	},
	rsvpInvitation,
	rsvpQuestions,
}

export type ApiType = typeof api

export default api
