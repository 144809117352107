import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { dashActions } from "dashboard/dashSlice"
import FAQ from "models/FAQ"
import { ApiType } from "utils/api"

const initialState = {
	name: "Questions & Answers",
	isVisible: true,
	questions: [],
} as FAQState

const slice = createSlice({
	name: "dashboard/pages/faq",
	initialState,
	reducers: {
		setIsVisible: (state, { payload }: PayloadAction<boolean>) => {
			state.isVisible = payload
		},
		setQuestions: (state, { payload }: PayloadAction<FAQ[]>) => {
			state.questions = payload
		},
		clearState: () => initialState,
	},
	extraReducers: (builder) => {
		builder.addCase(faqActions.fetch.rejected, (_, { error }) => {
			console.error(error)
		})
		builder.addCase(faqActions.fetch.fulfilled, (state, { payload }) => {
			state.questions = payload
		})
		builder.addCase(
			dashActions.fetchInitialState.fulfilled,
			(state, { payload }) => {
				state.questions = payload.faq
			}
		)
	},
})

export type FAQState = {
	readonly name: "Questions & Answers"
	isVisible: boolean
	questions: FAQ[]
}

export const faqActions = {
	...slice.actions,

	//#region Thunks
	fetch: createAsyncThunk(
		`${slice.name}/fetchFAQ`,
		async ({ eventGroupId, api }: { eventGroupId: string; api: ApiType }) =>
			await api.pages.faq.list(eventGroupId)
	),
	//#endregion
}

export const faqReducer = slice.reducer
