import clsx from "clsx"
import { memo, ReactNode } from "react"

const TH = ({
	className,
	children,
	padded = true,
	onClick,
}: {
	className?: string
	padded?: boolean
	children: ReactNode
	onClick?: () => void
}) => (
	<th
		scope="col"
		className={clsx(`${padded ? "px-5" : ""} whitespace-nowrap`, className)}
	>
		<div
			className={`flex gap-1 ${onClick == null ? "" : "cursor-pointer"}`}
			onClick={onClick}
		>
			{children}
		</div>
	</th>
)

export default memo(TH)
