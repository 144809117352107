import { I18nProps } from "common/withI18n"
import { RSVPAnswerDTO } from "models/RSVPAnswer"

type RSVPQuestion = {
	id: string
	question: string
	eventId?: string
	type: RSVPQuestionType
	answers: string[]
	optional: boolean
	allowCustomAnswer: boolean
	allowedWhenAnswerForOthersInGroup: boolean
	version?: number
	order: number
}

// We do all 4 due to backwards compatibility.
const isBoolean = (value: string, t: I18nProps["t"]): boolean =>
	value === t("Yes") || value === t("No") || value === "Yes" || value === "No"

const booleanToYesNo = (
	t: I18nProps["t"],
	value?: boolean
): string | undefined =>
	value == null ? undefined : value ? t("Yes") : t("No")

const answersForType = (
	type: RSVPQuestionType,
	t: I18nProps["t"]
): RSVPQuestion["answers"] => {
	switch (type) {
		case "COMING_YES_NO":
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			return [booleanToYesNo(t, true)!, booleanToYesNo(t, false)!]
		case "PLUS_ONES":
			return ["0", "1"]
		case "FOOD":
		case "TRAVEL":
		case "GENERIC":
		default:
			return [""]
	}
}

export type RSVPQuestionGET = Omit<RSVPQuestion, "answers"> & {
	answers: {
		text: string
		stringValue?: string
		integerValue?: number
		booleanValue?: boolean
	}[]
}

export type RSVPQuestionDTO = Omit<RSVPQuestion, "id" | "answers"> & {
	answers: RSVPAnswerDTO[]
	defaultAnswer?: number
}

export type RSVPStatisticQuestion = {
	questionId: string
	eventId: string
	answerCounts: { [key: string]: number }
	textCounts: number
}

export type RSVPStatisticsCustomAnswer = {
	id: string
	guestId: string
	questionId: string
	answerText: string
}

export type RSVPStatistics = {
	eventId: string
	guestStatistics: {
		numGuestsInvited: number
		numGuestsYes: number
		numGuestsNo: number
		numGuestsPlusOnes: 6
		numGuestsTotal: 12
		numGuestsTotalChildren: 1
	}
	customAnswers: RSVPStatisticsCustomAnswer[]
	questions: RSVPStatisticQuestion[]
}[]

export const fromGETToLocal = (
	question: RSVPQuestionGET,
	t: I18nProps["t"]
): RSVPQuestion => ({
	...question,
	answers: question.answers.map(
		(a) =>
			(question.type === "COMING_YES_NO" ? t(a.text) : a.text) ??
			a.integerValue?.toString() ??
			booleanToYesNo(t, a.booleanValue) ??
			a.stringValue
	),
})

export const isPlusOnes = (type: RSVPQuestionType): boolean =>
	type === "PLUS_ONES"

export const isRequired = (type: RSVPQuestionType): boolean =>
	type === "COMING_YES_NO" || type === "PLUS_ONES"

export const RSVPQuestionTypes = [
	"COMING_YES_NO",
	"PLUS_ONES",
	"FOOD",
	"TRAVEL",
	"GENERIC",
] as const

export type RSVPQuestionType = (typeof RSVPQuestionTypes)[number]

export const friendlyQuestionType = (
	type: RSVPQuestionType,
	t: I18nProps["t"]
): string => {
	switch (type) {
		case "COMING_YES_NO":
		case "PLUS_ONES":
		case "FOOD":
		case "TRAVEL":
		case "GENERIC":
			return t(type)
		default:
			return ""
	}
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const questionDTO = (
	question: RSVPQuestion,
	t: I18nProps["t"]
): RSVPQuestionDTO => ({
	question: question.question,
	version: question.version,
	type: question.type,
	optional: question.optional,
	allowCustomAnswer: question.allowCustomAnswer,
	allowedWhenAnswerForOthersInGroup: question.allowedWhenAnswerForOthersInGroup,
	order: question.order,
	defaultAnswer: question.optional ? 0 : undefined,
	answers: question.answers.map((a) => ({
		text: question.type === "COMING_YES_NO" ? t(a) : a,
		integerValue: isPlusOnes(question.type) ? parseInt(a) : undefined,
		booleanValue:
			a === booleanToYesNo(t, true)
				? (true as boolean)
				: a === booleanToYesNo(t, false)
				  ? (false as boolean)
				  : undefined,
		stringValue: isPlusOnes(question.type)
			? undefined
			: isBoolean(a, t)
			  ? undefined
			  : a,
	})),
})

export const emptyQuestion = (
	order: number,
	type: RSVPQuestionType,
	t: I18nProps["t"]
): RSVPQuestion => ({
	id: `-${order}`,
	type,
	allowCustomAnswer: false,
	answers: answersForType(type, t),
	optional: false,
	question: type === "GENERIC" ? "" : friendlyQuestionType(type, t),
	allowedWhenAnswerForOthersInGroup:
		type !== "COMING_YES_NO" && type !== "PLUS_ONES",
	order,
})

export default RSVPQuestion
