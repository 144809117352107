import { Transition } from "@headlessui/react"
import {
	ExclamationTriangleIcon,
	InformationCircleIcon,
	XMarkIcon,
} from "@heroicons/react/20/solid"
import { CheckCircleIcon } from "@heroicons/react/24/outline"
import withI18n, { I18nProps } from "common/withI18n"
import { Fragment, memo } from "react"

const Notification = ({
	t,
	show = true,
	setShow,
	title,
	text,
	type = "info",
}: { setShow: (flag: boolean) => void } & NotificationProps & I18nProps) => (
	<>
		<div
			aria-live="assertive"
			className="pointer-events-none fixed inset-0 flex items-center px-4 py-6 sm:items-start sm:p-6"
		>
			<div className="flex w-full flex-col items-center space-y-4 sm:items-center">
				<Transition
					show={show}
					as={Fragment}
					enter="transform ease-out duration-300 transition"
					enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
					enterTo="translate-y-0 opacity-100 sm:translate-x-0"
					leave="transition ease-in duration-100"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
						<div className="p-4">
							<div className="flex items-start">
								<div className="flex-shrink-0">
									{type === "success" ? (
										<CheckCircleIcon
											className="h-6 w-6 text-green-400"
											aria-hidden="true"
										/>
									) : type === "info" ? (
										<InformationCircleIcon
											className="h-6 w-6 text-dark-100 text-opacity-70"
											aria-hidden="true"
										/>
									) : (
										<ExclamationTriangleIcon
											className={`h-6 w-6 ${
												type === "error" ? "text-red-600" : "text-yellow-600"
											}`}
											aria-hidden="true"
										/>
									)}
								</div>
								<div className="ml-3 w-0 flex-1 pt-0.5">
									<p className="text-sm font-medium text-gray-900">{title}</p>
									{text == null ? null : (
										<p className="mt-1 text-sm text-gray-500">{text}</p>
									)}
								</div>
								<div className="ml-4 flex flex-shrink-0">
									<button
										type="button"
										className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
										onClick={() => setShow(false)}
									>
										<span className="sr-only">{t("close")}</span>
										<XMarkIcon className="h-5 w-5" aria-hidden="true" />
									</button>
								</div>
							</div>
						</div>
					</div>
				</Transition>
			</div>
		</div>
	</>
)

export type NotificationProps = {
	show?: boolean
	title: string
	text?: string
	type?: "success" | "error" | "warning" | "info"
}

export default memo(withI18n(Notification))
