import Event, { EventDTO } from "models/Event"
import RSVPAnsweredQuestion from "models/RSVPAnsweredQuestion"
import { perform } from "utils/api/perform"

const root = (groupId: string) => perform(`/event_groups/${groupId}/events`)

const list = async (groupId: string): Promise<Event[]> =>
	root(groupId)("get", "/")

const get = async (id: string, groupId: string): Promise<Event> =>
	root(groupId)("get", `/${id}`)

const update = async (
	event: EventDTO,
	id: string,
	groupId: string
): Promise<Event> =>
	await root(groupId)("put", `/${id}`, {
		...event,
	})

const create = async (event: EventDTO, groupId: string): Promise<Event> =>
	await root(groupId)("post", "/", {
		...event,
	})

const _delete = (id: string, groupId: string): Promise<void> =>
	root(groupId)("delete", `/${id}`)

const questionStatistics = (
	id: string,
	groupId: string,
	questionId: string
): Promise<RSVPAnsweredQuestion[]> =>
	root(groupId)("get", `/${id}/questions/${questionId}/answers`)

export default {
	create,
	list,
	get,
	update,
	questionStatistics,
	delete: _delete,
}
