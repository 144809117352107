import Capsule from "components/Capsule"
import TH from "components/TH"
import TR from "components/TR"
import starIcon from "images/star.svg"
import Event, { friendlyEventType } from "models/Event"
import { useContext } from "react"
import MainContext from "store/context/MainContext"
import trashIcon from "./images/trash.svg"

const EventFixedRow = ({
	isMainEvent,
	isHovered,
	setIsHovered,
	event,
	editSheet,
	onDelete,
}: {
	isMainEvent: boolean
	isHovered: boolean
	setIsHovered: (isHovered: boolean) => void
	event: Event
	editSheet: JSX.Element
	onDelete: (event: Event) => void
}) => {
	const mainContext = useContext(MainContext)

	return (
		<TR
			key={event.id}
			className={`cursor-pointer ${
				isHovered ? "bg-dark-100 bg-opacity-5" : ""
			}`}
			title={isMainEvent ? "Main Event" : undefined}
			onClick={() => mainContext.setSheet(editSheet)}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			<TH padded={false} className="h-20">
				<div
					className={`group/name flex h-full w-full items-center justify-between`}
				>
					<div className="flex flex-col gap-2">
						<div className="flex w-[414.5px] gap-3 overflow-x-clip text-sm font-bold">
							<span>{event.name}</span>
							{!isMainEvent ? null : <img src={starIcon} />}
						</div>
						<div className="flex items-center">
							<span>
								{event.eventStartAt.intlDate({
									hour: "2-digit",
									minute: "2-digit",
								})}
							</span>
							{event.eventEndAt == null ? null : (
								<span>
									<span className="px-1">-</span>
									{event.eventEndAt.intlDate({
										hour: "2-digit",
										minute: "2-digit",
									})}
								</span>
							)}
							{/* Show it back in the future */}
							<Capsule className="ml-4 hidden h-6 bg-slate-300">
								{friendlyEventType(event.type)}
							</Capsule>
						</div>
					</div>
					<img
						src={trashIcon}
						alt="delete event"
						className="mr-9 hidden h-8 w-8 group-hover/name:hidden"
						onClick={(e) => {
							e.stopPropagation()
							onDelete(event)
						}}
					/>
				</div>
			</TH>
		</TR>
	)
}

export default EventFixedRow
