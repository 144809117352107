import {
	combineReducers,
	createAsyncThunk,
	createSlice,
	PayloadAction,
} from "@reduxjs/toolkit"
import { I18nProps } from "common/withI18n"
import { guestsReducer } from "dashboard/guests/guestsSlice"
import { faqReducer } from "dashboard/pages/faqSlice"
import { homeReducer } from "dashboard/pages/homeSlice"
import { rsvpReducer } from "dashboard/pages/rsvpSlice"
import { scheduleReducer } from "dashboard/schedule/scheduleSlice"
import RSVPQuestion, {
	RSVPStatisticQuestion,
	RSVPStatisticsCustomAnswer,
} from "models/RSVPQuestion"
import Tag from "models/Tag"
import { RootState } from "store"
import { ApiType } from "utils/api"

type ShouldReload = {
	guests: boolean
	tags: boolean
	events: boolean
}

type ShouldReloadPayload = {
	[key in keyof ShouldReload]?: boolean
}

const initialState = {
	tags: [],
	statistics: {},
	isInitialized: false,
	eventGroupId: localStorage.getItem("eventGroupId") ?? "",
	mainEventId: localStorage.getItem("mainEventId") ?? "",
	shouldReload: {
		guests: false,
		tags: false,
		events: false,
	},
} as DashboardState

const slice = createSlice({
	name: "dashboard",
	initialState,
	reducers: {
		setTags: (state, { payload }: PayloadAction<Tag[]>) => {
			state.tags = payload
		},
		addTags: (state, { payload }: PayloadAction<Tag[]>) => {
			state.tags = [...state.tags, ...payload]
		},
		removeTags: (state, { payload }: PayloadAction<Tag[]>) => {
			state.tags = state.tags.filter(
				(tag) => !payload.some((t) => t.id === tag.id)
			)
		},
		setShouldReload: (
			state,
			{ payload }: PayloadAction<ShouldReloadPayload>
		) => {
			state.shouldReload = {
				...state.shouldReload,
				...payload,
			}
		},
		setMainEventGroupId: (state, { payload }: PayloadAction<string | null>) => {
			if (payload != null) {
				localStorage.setItem("eventGroupId", payload)
			} else {
				localStorage.removeItem("eventGroupId")
			}

			state.eventGroupId = payload ?? ""
		},
		setMainEventId: (state, { payload }: PayloadAction<string | null>) => {
			if (payload != null) {
				localStorage.setItem("mainEventId", payload)
			} else {
				localStorage.removeItem("mainEventId")
			}

			state.mainEventId = payload ?? ""
		},
		clearState: () => {
			localStorage.removeItem("eventGroupId")
			localStorage.removeItem("mainEventId")

			return initialState
		},
	},
	extraReducers: (builder) => {
		builder.addCase(
			dashActions.fetchInitialState.rejected,
			(state, { error }) => {
				state.isInitialized = true
				console.error(error)
			}
		)
		builder.addCase(
			dashActions.fetchInitialState.fulfilled,
			(state, { payload }) => {
				state.isInitialized = true
				state.tags = payload.tags

				state.statistics = payload.statistics.reduce(
					(acc, stats) => {
						acc[stats.eventId] = {
							sentRSVPs: stats.guestStatistics.numGuestsInvited,
							yes: stats.guestStatistics.numGuestsYes,
							no: stats.guestStatistics.numGuestsNo,
							plusOnes: stats.guestStatistics.numGuestsPlusOnes,
							total: stats.guestStatistics.numGuestsTotal,
							children: stats.guestStatistics.numGuestsTotalChildren,
							customAnswers: stats.customAnswers,
							questions: stats.questions,
						}

						return acc
					},
					{} as DashboardState["statistics"]
				)
			}
		)
		builder.addCase(dashActions.fetchTags.fulfilled, (state, { payload }) => {
			state.tags = payload
		})
		builder.addCase(
			dashActions.fetchStatistics.fulfilled,
			(state, { payload }) => {
				state.statistics = payload.reduce(
					(acc, stats) => {
						acc[stats.eventId] = {
							sentRSVPs: stats.guestStatistics.numGuestsInvited,
							yes: stats.guestStatistics.numGuestsYes,
							no: stats.guestStatistics.numGuestsNo,
							plusOnes: stats.guestStatistics.numGuestsPlusOnes,
							total: stats.guestStatistics.numGuestsTotal,
							children: stats.guestStatistics.numGuestsTotalChildren,
							customAnswers: stats.customAnswers,
							questions: stats.questions,
						}

						return acc
					},
					{} as DashboardState["statistics"]
				)
			}
		)
	},
})

export type DashboardState = {
	tags: Tag[]
	statistics: {
		[key: string]: {
			sentRSVPs: number
			yes: number
			no: number
			plusOnes: number
			total: number
			children: number
			customAnswers: RSVPStatisticsCustomAnswer[]
			questions: RSVPStatisticQuestion[]
		}
	}
	shouldReload: ShouldReload
	isInitialized: boolean
	eventGroupId: string
	mainEventId: string
}

export const dashActions = {
	...slice.actions,

	//#region Thunks
	fetchTags: createAsyncThunk(
		`${slice.name}/fetchTags`,
		async ({ eventGroupId, api }: { eventGroupId: string; api: ApiType }) => {
			try {
				return await api.tags.list(eventGroupId)
			} catch {
				return []
			}
		}
	),
	fetchStatistics: createAsyncThunk(
		`${slice.name}/fetchStatistics`,
		async ({ eventGroupId, api }: { eventGroupId: string; api: ApiType }) => {
			try {
				return await api.eventGroups.questionStatistics(eventGroupId)
			} catch {
				return []
			}
		}
	),
	fetchInitialState: createAsyncThunk(
		`${slice.name}/fetchInitialState`,
		async ({
			eventGroupId,
			api,
			t,
		}: {
			eventGroupId: string
			api: ApiType
			t: I18nProps["t"]
		}) => {
			const questionsPromise = api.events
				.list(eventGroupId)
				.then(async (events) => {
					const rsvpQuestions: { [key: string]: RSVPQuestion[] } = {}

					await Promise.all(
						events.map(async (event) => {
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							const eventId = event.id!

							try {
								const questions = await api.rsvpQuestions.list(
									eventGroupId,
									eventId,
									t
								)
								rsvpQuestions[eventId] = questions
							} catch {
								rsvpQuestions[eventId] = []
							}
						})
					)

					return {
						events,
						rsvpQuestions,
					}
				})

			return await Promise.all([
				api.eventGroups.questionStatistics(eventGroupId),
				api.eventGroups.tier(eventGroupId),
				api.guests.list(eventGroupId),
				api.tags.list(eventGroupId),
				questionsPromise,
				api.pages.faq.list(eventGroupId),
			]).then(
				([statistics, tier, guests, tags, { events, rsvpQuestions }, faq]) => ({
					statistics,
					tier,
					guests,
					tags,
					events,
					rsvpQuestions,
					faq,
				})
			)
		},
		{
			condition: (_, { getState }) =>
				!(getState() as RootState).dash.common.isInitialized,
		}
	),
	//#endregion
}

export const dashReducer = combineReducers({
	common: slice.reducer,
	guests: guestsReducer,
	schedule: scheduleReducer,
	pages: combineReducers({
		home: homeReducer,
		rspv: rsvpReducer,
		faq: faqReducer,
	}),
})
