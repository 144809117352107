import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { updateAcceptLanguage } from "utils/api/perform"
import storage from "utils/storage"
import localJSON from "./translations.json"

/* eslint-disable import/exports-last */
export type Translations = {
	ro: Record<string, string>
	en: Record<string, string>
}

export type I18n = {
	locale: keyof Translations
	translations: Translations
}

const emptyI18n = () => {
	const translations: Translations = localJSON
	let locale: keyof Translations = "ro"

	if (typeof navigator !== "undefined") {
		const browserLocale = (navigator.languages?.[0] ?? navigator.language)
			.split("-")[0]
			.split("_")[0]

		if (Object.keys(translations).includes(browserLocale)) {
			locale = browserLocale as keyof Translations
		}
	}

	return {
		locale,
		translations,
	}
}

export const initialI18nState = (): I18n => {
	const cachedI18n = storage.get("i18n")
	let i18n: I18n | null

	if (typeof cachedI18n === "string" && cachedI18n !== "") {
		try {
			i18n = JSON.parse(cachedI18n) as I18n
			i18n.translations = localJSON

			if (i18n == null) {
				i18n = emptyI18n()
			}
		} catch {
			i18n = emptyI18n()
		}
	} else if (typeof cachedI18n === "object" && cachedI18n != null) {
		i18n = cachedI18n as I18n
		i18n.translations = localJSON
	} else {
		i18n = emptyI18n()
	}

	if (i18n.locale == null) {
		i18n.locale = emptyI18n().locale
	}

	updateAcceptLanguage(i18n.locale)

	return i18n
}

const slice = createSlice({
	name: "i18n",
	initialState: initialI18nState(),
	reducers: {
		setLocale: (state, action: PayloadAction<keyof Translations>) => {
			if (action.payload == null) {
				return
			}

			state.locale = action.payload
			updateAcceptLanguage(action.payload)

			storage.set(
				{
					...state,
					locale: action.payload,
				},
				"i18n"
			)
		},
	},
})

export const i18nActions = { ...slice.actions }

export const i18nReducer = slice.reducer
