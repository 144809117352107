/* eslint-disable import/exports-last */
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { dashActions } from "dashboard/dashSlice"
import AccountTier from "models/AccountTier"
import api from "utils/api"

export type AuthState = {
	isAuthenticated: boolean
	tier: AccountTier | null
	email?: string
}

export const initialAuthState: AuthState = {
	isAuthenticated: false,
	tier: null,
}

const slice = createSlice({
	name: "auth",
	initialState: initialAuthState,
	reducers: {
		setToken: (state: AuthState, action: PayloadAction<string | null>) => {
			api.users.setToken(action.payload)

			state.isAuthenticated = action.payload != null && action.payload !== ""

			if (!state.isAuthenticated) {
				state.tier = null
			}
		},
		setEmail: (state: AuthState, action: PayloadAction<string | undefined>) => {
			state.email = action.payload
		},
		clearState: () => initialAuthState,
	},
	extraReducers: (builder) => {
		builder.addCase(
			dashActions.fetchInitialState.fulfilled,
			(state, { payload }) => {
				state.tier = payload.tier
			}
		)
	},
})

export const authActions = { ...slice.actions }

export const authReducer = slice.reducer
