const RadioButton = ({
	checked,
	onClick,
}: {
	checked: boolean
	onClick?: () => void
}) => {
	return (
		<div
			className="flex h-6 w-6 shrink-0 cursor-pointer items-center justify-center rounded-full bg-white"
			onClick={(e) => {
				if (onClick == null) {
					return
				}

				e.stopPropagation()
				onClick()
			}}
		>
			{checked ? (
				<div className="h-3 w-3 shrink-0 rounded-full bg-primary" />
			) : null}
		</div>
	)
}

export default RadioButton
