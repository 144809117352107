import { useAuth0 } from "@auth0/auth0-react"
import { dashActions } from "dashboard/dashSlice"
import { guestsActions } from "dashboard/guests/guestsSlice"
import { faqActions } from "dashboard/pages/faqSlice"
import { homeActions } from "dashboard/pages/homeSlice"
import { rsvpActions } from "dashboard/pages/rsvpSlice"
import { scheduleActions } from "dashboard/schedule/scheduleSlice"
import store from "store"
import { authActions } from "store/authSlice"
import { AppConfig } from "utils/envSetup"

const useLogout = () => {
	const { logout: auth0Logout } = useAuth0()

	const logout = () => {
		auth0Logout({
			clientId: AppConfig.auth0ClientId,
			logoutParams: {
				returnTo: AppConfig.homeURL,
			},
		})

		store.dispatch(authActions.clearState())
		store.dispatch(dashActions.clearState())
		store.dispatch(guestsActions.clearState())
		store.dispatch(scheduleActions.clearState())
		store.dispatch(homeActions.clearState())
		store.dispatch(rsvpActions.clearState())
		store.dispatch(faqActions.clearState())
	}

	return { logout }
}

export default useLogout
