import { isNonEmptyString } from "utils/validations"

const local = localStorage
const session = sessionStorage

const storage = {
	/**
	 * Remove an item from the used storage
	 */
	remove(key: string): void {
		try {
			local.removeItem(key)
			session.removeItem(key)
		} catch {}
	},

	/**
	 * Remove all app storage
	 */
	clearAppStorage(): void {
		try {
			local.clear()
			session.clear()
		} catch {}
	},

	/**
	 * Set data in storage
	 * @param fromLocalStorage Defines if we need to store in `localStorage` or `sessionStorage`.
	 */
	get(key: string, fromLocalStorage = true): unknown {
		try {
			if (fromLocalStorage) {
				const value = local.getItem(key)

				if (isNonEmptyString(value)) {
					return JSON.parse(value)
				}

				return
			}

			const value = session.getItem(key)

			if (isNonEmptyString(value)) {
				return JSON.parse(value)
			}

			return
		} catch {
			return
		}
	},

	/**
	 * Set data in storage
	 * @param isLocalStorage Defines if we store in `localStorage` or `sessionStorage`, defaults to `true`.
	 */
	set(value: unknown, key: string, isLocalStorage = true): void {
		const toWrite = JSON.stringify(value)

		try {
			if (isLocalStorage) {
				local.setItem(key, toWrite)
				return
			}

			session.setItem(key, toWrite)
		} catch {}
	},
}

export default storage
