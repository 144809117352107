import { numberOrNull } from "utils/helpers"

/**
 * Tests that a value is a `string` and has a `length > 0`.
 * @param value The string to test.
 */
export const isNonEmptyString = (
	value: unknown,
	trim = true
): value is string => {
	return (
		typeof value === "string" &&
		(trim ? value.trim().length > 0 : value.length > 0)
	)
}

export const isNullOrNonEmptyString = (
	value: unknown,
	trim = true
): boolean => {
	if (value == null) {
		return true
	}

	return isNonEmptyString(value, trim)
}

/**
 * Tests that a value is a `string` and has a UUID structure.
 * @param value The string to test.
 */
export const isUUID = (value: unknown): value is string => {
	const pattern =
		/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i

	return typeof value === "string" && pattern.test(value)
}

export const normalize = (string?: string): string => {
	return (string ?? "").toLowerCase().replace(" ", "")
}

export const isPositiveNumber = (value?: unknown): value is number => {
	let v = value

	if (typeof v === "string") {
		v = numberOrNull(v)
	}

	return typeof v === "number" && v != null && v > 0
}

export const isEmptyOrPositiveNumber = (value?: unknown): boolean => {
	if (value == null || value === "") {
		return true
	}

	return isPositiveNumber(value)
}
