import { Helmet as ReactHelmet } from "react-helmet-async"

interface Props {
	title: string
	description: string
	image: string
}

const Helmet = ({ title, description, image }: Props): JSX.Element => {
	const location = window.location.href

	return (
		<ReactHelmet>
			<title>{title}</title>
			<link rel="canonical" href={location} />
			{/* Metadata */}
			<meta name="description" content={description} />

			{/* OG */}
			<meta property="og:title" content={title} />
			<meta property="og:image" content={image} />
			<meta property="og:description" content={description} />
			<meta property="og:url" content={location} />
			<meta property="og:site_name" content={title} />
			<meta property="og:type" content="website" />
		</ReactHelmet>
	)
}

Helmet.defaultProps = {
	title: "Eventa",
	description:
		"Make every event the way it should be: streamlined, organized, hassle-free",
	image: "https://eventa.rsvp/images/favicons/apple-touch-icon.png",
}

export default Helmet
