import RSVPAnsweredQuestion from "models/RSVPAnsweredQuestion"
import { perform } from "utils/api/perform"

const root = (eventGroupId: string) =>
	perform(`/event_groups/${eventGroupId}/rsvp_invitations`)

type EmailContent = {
	subject: string
	body: string
	replyToEmail: string
	headline: string
	subheadline: string
	salutation: string
	buttonText: string
}

const send = async (
	eventGroupId: string,
	test: boolean,
	params:
		| {
				emailContent: EmailContent
				eventIds: string[]
				tags: string[]
				dryRun: boolean
		  }
		| {
				emailContent: EmailContent
		  }
): Promise<{
	guestInvitations: { eventIds: string[]; uniqueLinkHash: string }[]
	invitation: { eventIds: string[] }
}> => await root(eventGroupId)("post", `/${test ? "test" : ""}`, params)

const answers = async (
	eventGroupId: string,
	guestId: string
): Promise<RSVPAnsweredQuestion[]> =>
	await root(eventGroupId)("get", `/guests/${guestId}/answers`)

const saveAnswers = async (
	eventGroupId: string,
	invitationId: string,
	guestId: string,
	answers: RSVPAnsweredQuestion[]
): Promise<void> =>
	await root(eventGroupId)(
		"post",
		`/${invitationId}/guests/${guestId}/answers`,
		answers.map((a) => ({
			questionId: a.questionId,
			version: a.questionVersion,
			answerIndex: a.answerIndex,
			answerText: a.answerText,
		}))
	)

export default {
	send,
	answers,
	saveAnswers,
}
