/* eslint-disable @typescript-eslint/no-empty-function */
import { createSelector } from "@reduxjs/toolkit"
import useApi from "common/useApi"
import withI18n, { I18nProps } from "common/withI18n"
import EmptyState from "components/EmptyState"
import TH from "components/TH"
import TR from "components/TR"
import Header from "dashboard/Header"
import { fixedSideContentWidth } from "dashboard/Layout"
import AddEditSheet from "dashboard/schedule/AddEditSheet"
import EventFixedRow from "dashboard/schedule/EventFixedRow"
import EventScrollableRow from "dashboard/schedule/EventScrollableRow"
import {
	scheduleActions,
	ScheduleState,
} from "dashboard/schedule/scheduleSlice"
import addIcon from "images/add.svg"
import plusIcon from "images/icons/plus.svg"
import Event from "models/Event"
import { memo, useContext, useMemo, useState } from "react"
import store, { useDashSelector, useScheduleSelector } from "store"
import MainContext from "store/context/MainContext"
import calendarEmptyIcon from "./images/calendarEmpty.svg"

const Schedule = ({ t }: I18nProps) => {
	const { eventGroupId, mainEventId } = useDashSelector()
	const schedule = useScheduleSelector()
	const groupedEvents = selectGroupedEvents(schedule)
	const [hoveredRowIndex, setHoveredRowIndex] = useState<string | null>(null)

	const mainContext = useContext(MainContext)
	const apiWithToken = useApi()

	//#region Sheets
	const addSheet = useMemo(
		() =>
			function TheAddSheet(t: I18nProps["t"], date?: string) {
				return (
					<AddEditSheet
						date={date}
						title={t("schedule.sheet.add.title.add")}
						buttonTitle={t("schedule.sheet.add.button.save")}
					/>
				)
			},
		[]
	)
	const editSheet = useMemo(
		() =>
			function TheEditSheet(
				t: I18nProps["t"],
				event: Event,
				focusTags: boolean
			) {
				return (
					<AddEditSheet
						event={event}
						title={t("schedule.sheet.edit.title.edit")}
						buttonTitle={t("schedule.sheet.edit.button.save")}
						focusTags={focusTags}
					/>
				)
			},
		[]
	)
	//#endregion

	const handleRemoveEvent = async (event: Event) => {
		const api = await apiWithToken()

		if (api == null) {
			return
		}

		const initialEvents = [...schedule.events]

		store.dispatch(scheduleActions.remove([event]))

		try {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			await api.events.delete(event.id!, eventGroupId)
		} catch (e) {
			store.dispatch(scheduleActions.set(initialEvents))
		}
	}

	if (schedule.events.length === 0) {
		return (
			<div>
				<Header
					title={t("schedule.title")}
					buttons={[
						{
							text:
								schedule.events.length === 0
									? t("schedule.noContent.button")
									: // eslint-disable-next-line sonarjs/no-duplicate-string
									  t("schedule.button.add"),
							icon: addIcon,
							onClick: () => mainContext.setSheet(addSheet(t)),
						},
					]}
				/>

				<div className="mt-6 pr-9">
					<EmptyState
						title={t("schedule.noContent.title")}
						icon={calendarEmptyIcon}
						text={
							<p className="text-center">
								<span>{t("schedule.noContent.text.1")}</span>
								<br />
								<span>{t("schedule.noContent.text.2")}</span>
							</p>
						}
						button={{
							text: t("schedule.noContent.button"),
							onClick: () => mainContext.setSheet(addSheet(t)),
						}}
					/>
				</div>
			</div>
		)
	}

	return (
		<>
			<Header
				title={t("schedule.title")}
				subtitle={{ text: t("schedule.subtitle"), restrictWidth: true }}
				buttons={[
					{
						text: t("schedule.button.add"),
						icon: addIcon,
						onClick: () => mainContext.setSheet(addSheet(t)),
					},
				]}
			/>

			<div className="overflow-y-scroll">
				<div className="flex pb-24">
					<div className={`w-[${fixedSideContentWidth}px] shrink-0`}>
						{groupedEvents.map((group, i) => (
							<table
								className={`w-full ${i == 0 ? "" : "mt-14"}`}
								key={`${group.date}-table`}
							>
								<thead>
									<TR className="h-10">
										<th className="py-0 opacity-40">
											<span className="font-semibold">
												{group.date.intlDate({
													weekday: "long",
												})}
											</span>
											, <span>{new Date(group.date).intlDate()}</span>
										</th>
									</TR>
								</thead>
								<tbody>
									{group.events.map((event, j) => (
										<EventFixedRow
											key={event.id}
											isMainEvent={event.id === mainEventId}
											isHovered={`${i}-${j}` === hoveredRowIndex}
											setIsHovered={(flag: boolean) =>
												setHoveredRowIndex(flag ? `${i}-${j}` : null)
											}
											event={event}
											onDelete={handleRemoveEvent}
											editSheet={editSheet(t, event, false)}
										/>
									))}

									<tr>
										<td>
											<div
												className="mt-8 flex cursor-pointer items-center gap-4 opacity-60"
												onClick={() => {
													mainContext.setSheet(
														addSheet(t, group.events[0].eventStartAt)
													)
												}}
											>
												<img src={plusIcon} />
												<span className="text-sm">
													{t("schedule.button.add")}
												</span>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						))}
					</div>
					{/* This set of width and flex settings make this work, otherwise it either doesn't grow when there are too few items, or it doesn't align sections with lower items with sections with more items. */}
					<div className="w-full overflow-x-scroll">
						<div className="inline-flex min-w-full flex-col">
							{groupedEvents.map((group, i) => (
								<table
									className={`${i == 0 ? "" : "mt-14"}`}
									key={`${group.date}-table`}
								>
									<thead>
										<TR className="h-10">
											<TH className="opacity-40">
												{t("schedule.column.guests")}
											</TH>
										</TR>
									</thead>
									<tbody>
										{group.events.map((event, j) => (
											<EventScrollableRow
												key={event.id}
												isHovered={`${i}-${j}` === hoveredRowIndex}
												setIsHovered={(flag: boolean) =>
													setHoveredRowIndex(flag ? `${i}-${j}` : null)
												}
												index={j}
												event={event}
												date={group.date}
												editSheet={editSheet(t, event, true)}
											/>
										))}
										<tr>
											<td>
												<div className="h-14 w-1"></div>
											</td>
										</tr>
									</tbody>
								</table>
							))}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export const selectGroupedEvents = createSelector(
	(state: ScheduleState) => ({
		selectedTags: state.selectedTags,
		events: state.events,
	}),
	({ selectedTags, events }) => {
		const temp: { [key: string]: Event[] } = {}

		events
			.filter((event) =>
				selectedTags.every((t) => event.tags.some((tag) => t.tag === tag.tag))
			)
			.forEach((event) => {
				if (event.eventStartAt == null) {
					return
				}

				const key = event.eventStartAt.split("T")[0]

				if (temp[key]) {
					temp[key].push(event)
				} else {
					temp[key] = [event]
				}
			})

		return Object.entries(temp)
			.map(([key, value]) => ({
				date: new Date(key).getTime(),
				events: value,
			}))
			.sort((a, b) => a.date - b.date)
	}
)

export default memo(withI18n(Schedule))
