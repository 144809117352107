import { I18nProps } from "common/withI18n"
import RSVPQuestion, {
	RSVPQuestionDTO,
	RSVPQuestionGET,
	fromGETToLocal,
} from "models/RSVPQuestion"
import { perform } from "utils/api/perform"

const root = (eventGroupId: string, eventId: string) =>
	perform(`/event_groups/${eventGroupId}/events/${eventId}/questions`)

const list = async (
	eventGroupId: string,
	eventId: string,
	t: I18nProps["t"]
): Promise<RSVPQuestion[]> => {
	const response = await root(eventGroupId, eventId)<RSVPQuestionGET[]>(
		"get",
		"/"
	)

	return response.map((q) => fromGETToLocal(q, t))
}

const create = async (
	eventGroupId: string,
	eventId: string,
	question: RSVPQuestionDTO
): Promise<void> => await root(eventGroupId, eventId)("post", "/", question)

const update = async (
	eventGroupId: string,
	eventId: string,
	question: RSVPQuestionDTO,
	questionId: string
): Promise<void> =>
	await root(eventGroupId, eventId)("put", `/${questionId}`, question)

const _delete = async (
	eventGroupId: string,
	eventId: string,
	questionId: string
): Promise<void> =>
	await root(eventGroupId, eventId)("delete", `/${questionId}`)

export default {
	list,
	create,
	update,
	delete: _delete,
}
