import Button from "components/Button"
import HLine from "components/HLine"
import Loader from "components/Loader"
import closeIcon from "images/icons/close.svg"
import trashIcon from "images/icons/trash.svg"
import { ReactNode, useContext, useEffect } from "react"
import MainContext from "store/context/MainContext"

const Sheet = ({
	title,
	subtitle,
	topButtonAction,
	bottomButton,
	onContentScroll,
	extraBottomContent,
	isLoading,
	children,
}: {
	title: string
	subtitle?: ReactNode
	topButtonAction?: (() => Promise<boolean>) | (() => boolean) | (() => void)
	bottomButton?: {
		title: string
		action: () => void
	}
	onContentScroll?: (scrollTop?: number) => void
	extraBottomContent?: ReactNode
	isLoading?: boolean
	children: ReactNode
}) => {
	const context = useContext(MainContext)

	useEffect(() => {
		const sheetContent = document.getElementById("sheetContent")
		const handleScroll = () => {
			onContentScroll?.(sheetContent?.scrollTop)
		}

		sheetContent?.addEventListener("scroll", handleScroll)

		return () => {
			sheetContent?.removeEventListener("scroll", handleScroll)
		}
	}, [onContentScroll])

	return (
		<div className="z-2 fixed top-0 bottom-0 left-0 right-0 flex bg-dark-500 bg-opacity-30">
			<style>{`html { overflow: hidden; }`}</style>

			<div className="grow" onClick={() => context.setSheet(undefined)}></div>

			<div className="relative w-[720px] bg-light-100 py-7 shadow-xl">
				<div className="relative z-10 px-9">
					<div className="relative mb-8 flex items-center">
						<img
							className="absolute left-0 cursor-pointer"
							src={closeIcon}
							onClick={() => context.setSheet(undefined)}
						/>
						<h1 className="mx-auto text-center text-4xl">{title}</h1>
						{topButtonAction == null ? null : (
							<img
								className="absolute right-0 cursor-pointer"
								src={trashIcon}
								onClick={async () => {
									const result = await topButtonAction()
									if (typeof result === "boolean" && !result) {
										return
									}
									context.setSheet(undefined)
								}}
							/>
						)}
					</div>
					{subtitle}
				</div>
				<div
					id="sheetContent"
					className="relative z-0 -mt-8 h-full overflow-x-clip overflow-y-scroll px-9 pt-8 pb-64"
				>
					{children}
					{bottomButton == null ? null : (
						<div className="flex flex-col items-center">
							<HLine />
							<div className="mt-20">
								{isLoading ? (
									<Loader />
								) : (
									<Button
										className="min-w-[160px]"
										onClick={bottomButton.action}
									>
										{bottomButton.title}
									</Button>
								)}
							</div>
						</div>
					)}
					{extraBottomContent}
				</div>
			</div>
		</div>
	)
}

export default Sheet
