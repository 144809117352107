import withI18n, { I18nProps } from "common/withI18n"
import H2 from "components/H2"
import Header from "dashboard/Header"
import tickGreen from "images/tickGreen.svg"
import { useNavigate } from "react-router-dom"
import R from "routes/R"
import { useDashSelector, useGuestSelector, useScheduleSelector } from "store"
import calendar from "./images/calendar.svg"
import calendarGreen from "./images/calendarGreen.svg"
import rsvp from "./images/rsvp.svg"
import rsvpGreen from "./images/rsvpGreen.svg"
import smiley from "./images/smiley.svg"
import smileyGreen from "./images/smileyGreen.svg"

const Card = ({
	icon,
	title,
	body,
	extra,
	buttonTitle,
	buttonAction,
	disabledReason = undefined,
}: {
	icon: string
	title: string
	body: string
	extra?: JSX.Element
	buttonTitle: string
	buttonAction: () => void
	disabledReason?: string
}) => (
	<div
		className={`min-h-[162px] w-[762px] rounded-3xl bg-white p-9 ${
			disabledReason == null ? "" : "cursor-default opacity-60"
		}`}
		title={disabledReason}
	>
		<div className="flex flex-col gap-4">
			<div className="flex flex-1 items-center gap-6">
				<img src={icon} />

				<div className="-mt-0.5 flex w-full items-center justify-between">
					<H2 className="text-opacity-100">{title}</H2>
					{extra}
				</div>
			</div>

			<div className="flex items-center justify-between pl-[52px]">
				<p className="w-[370px] opacity-70">{body}</p>
				<button
					className={`h-[36px] w-[162px] rounded-3xl border-1 border-dark-200 border-opacity-40 font-semibold ${
						disabledReason == null ? "" : "cursor-not-allowed"
					}`}
					onClick={disabledReason == null ? buttonAction : undefined}
				>
					{buttonTitle}
				</button>
			</div>
		</div>
	</div>
)

const Dashboard = ({ t }: I18nProps) => {
	const guests = useGuestSelector().all
	const events = useScheduleSelector().events
	const statistics = useDashSelector().statistics
	const sentRSVPs = Object.values(statistics).reduce(
		(acc, curr) => acc + curr.sentRSVPs,
		0
	)
	const navigate = useNavigate()

	return (
		<>
			<Header
				title={t("dashboard.title")}
				subtitle={{ text: t("dashboard.subtitle") }}
			/>

			<div className="ml-0 flex flex-col gap-6">
				<Card
					icon={guests.length > 0 ? smileyGreen : smiley}
					title={t("dashboard.firstSteps.1.title")}
					body={t("dashboard.firstSteps.1.body")}
					buttonTitle={
						guests.length === 0
							? t("dashboard.firstSteps.1.button1")
							: t("dashboard.firstSteps.1.button2")
					}
					buttonAction={() => navigate(R.guests)}
					extra={
						guests.length === 0 ? undefined : (
							<div className="flex items-center gap-4">
								<span className="opacity-70">
									<span>{t("dashboard.firstSteps.1.extra.1")}</span>{" "}
									<span className="font-semibold">
										{guests.length}{" "}
										{guests.length === 1 ? t("guest") : t("guests")}
									</span>{" "}
									<span>{t("dashboard.firstSteps.1.extra.2")}</span>
								</span>
								<img src={tickGreen} />
							</div>
						)
					}
				/>
				<Card
					icon={events.length > 0 ? calendarGreen : calendar}
					title={t("dashboard.firstSteps.2.title")}
					body={t("dashboard.firstSteps.2.body")}
					buttonTitle={t("dashboard.firstSteps.2.button")}
					buttonAction={() => navigate(R.schedule)}
					extra={
						events.length === 0 ? undefined : (
							<div className="flex items-center justify-between gap-4">
								<span className="opacity-70">
									<span>{t("dashboard.firstSteps.1.extra.1")}</span>{" "}
									<span className="font-semibold">
										{events.length}{" "}
										{events.length === 1 ? t("event") : t("events")}
									</span>{" "}
									<span>{t("dashboard.firstSteps.1.extra.2")}</span>
								</span>
								<img src={tickGreen} />
							</div>
						)
					}
				/>
				<Card
					icon={sentRSVPs > 0 ? rsvpGreen : rsvp}
					title={t("dashboard.firstSteps.3.title")}
					body={t("dashboard.firstSteps.3.body")}
					buttonTitle={t("dashboard.firstSteps.3.button")}
					buttonAction={() => navigate(R.comms)}
					extra={
						events.length === 0 ? undefined : (
							<div className="flex items-center justify-between gap-4">
								<span className="opacity-70">
									<span>{t("dashboard.firstSteps.3.extra.1")}</span>{" "}
									<span className="font-semibold">
										{sentRSVPs} {sentRSVPs === 1 ? t("invite") : t("invites")}
									</span>{" "}
									<span>{t("dashboard.firstSteps.3.extra.2")}</span>
								</span>
								<img src={tickGreen} />
							</div>
						)
					}
					disabledReason={
						guests.length > 0 && events.length > 0
							? undefined
							: t("dashboard.sidebar.disabledRSVPs")
					}
				/>
			</div>
		</>
	)
}

export default withI18n(Dashboard)
