import withI18n, { I18nProps } from "common/withI18n"
import HLine from "components/HLine"
import Popup from "components/Popup"
import Guest from "models/Guest"

const AnswersPopup = ({
	t,
	guests,
	question,
	answer,
	isLoading = false,
	onClose,
}: I18nProps & {
	guests: Guest[]
	question: string
	answer: string
	isLoading?: boolean
	onClose: () => void
}): JSX.Element => (
	<Popup
		onClose={onClose}
		className="h-[600px] w-[720px]"
		title={question}
		isLoading={isLoading}
	>
		<div className="h-full overflow-y-scroll pb-10">
			{guests.length === 0 ? (
				<div className="flex h-full flex-col justify-center px-12 text-dark-100">
					<span className="mx-auto text-lg font-semibold tracking-wider">
						{t("answerPopup.nooneSelected")}
					</span>
				</div>
			) : (
				guests.map((guest, i) => {
					const name = `${guest.sortableFields.firstName} ${guest.sortableFields.lastName}`

					return (
						<div key={guest.id}>
							{i === 0 ? <HLine /> : null}

							<div className="flex h-16 items-center px-12 text-dark-100">
								<span className="min-w-[200px] text-lg font-semibold tracking-wider">
									{name}:{" "}
								</span>
								<span>{answer}</span>
							</div>

							<HLine />
						</div>
					)
				})
			)}
		</div>
	</Popup>
)

export default withI18n(AnswersPopup)
