import withI18n from "common/withI18n"
import { memo } from "react"

const Header = ({
	title,
	subtitle,
	buttons = [],
}: {
	title: string
	subtitle?: {
		text: string
		restrictWidth?: boolean
	}
	buttons?: {
		text: string
		icon: string
		onClick: () => void
	}[]
}) => (
	// To take into account that on `Layout` we only add a left padding,
	// so that tables overflow to the right (couldn't make it work otherwise)
	<div className="mb-12 flex place-content-between pr-9">
		<div className="flex flex-col">
			<h1 className="dashboard-title mb-4">{title}</h1>
			{subtitle == null ? null : (
				<p
					className={`${
						subtitle.restrictWidth === true ? "max-w-[400px]" : ""
					} text-dark-100 text-opacity-60`}
				>
					{subtitle.text}
				</p>
			)}
		</div>

		<div className="flex gap-12">
			{buttons.map((b, i) => (
				<MenuEntry key={i} text={b.text} icon={b.icon} onClick={b.onClick} />
			))}
		</div>
	</div>
)

export const MenuEntry = ({
	text,
	icon,
	onClick,
}: {
	text: string
	icon: string
	onClick: () => void
}) => (
	<div
		className="flex cursor-pointer flex-col items-center gap-2 text-[10px]"
		onClick={onClick}
	>
		<img className="h-7 w-7" src={icon} alt={text} />
		<span className="opacity-60">{text}</span>
	</div>
)

export default memo(withI18n(Header))
