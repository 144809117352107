/* eslint-disable @typescript-eslint/no-empty-function */
import useApi from "common/useApi"
import withI18n, { I18nProps } from "common/withI18n"
import Alert from "components/Alert"
import Button from "components/Button"
import SheetWithSegmentedControl from "components/SheetWithSegmentedControl"
import Tags from "components/Tags"
import VLabeledInput from "components/VLabeledInput"
import GuestNotes from "dashboard/guests/GuestNotes"
import GuestRSVP from "dashboard/guests/GuestRSVP"
import Guest, { guestDTO } from "models/Guest"
import RSVPAnsweredQuestion from "models/RSVPAnsweredQuestion"
import { memo, useContext, useEffect, useState } from "react"
import { useDashSelector } from "store"
import MainContext from "store/context/MainContext"
import { isNonEmptyString } from "utils/validations"
import isEmail from "validator/lib/isEmail"

const emptyGuest = {
	id: "",
	groupLead: true,
	guestGroupId: "-1",
	pictureUrl: undefined,
	hasGroup: false,
	sortableFields: {
		firstName: "",
		lastName: "",
		email: "",
		plusOnes: "",
	},
	tags: [],
	groupColor: "",
} as Guest
const AddEditSheet = ({
	t,
	guest,
	title,
	buttonTitle,
	action,
	onDelete,
}: I18nProps & {
	guest?: Guest
	title: string
	buttonTitle: string
	action: (guest: Guest) => void
	onDelete?: () => void
}) => {
	const eventGroupId = useDashSelector().eventGroupId
	const [localGuest, setLocalGuest] = useState(
		(guest == null ? emptyGuest : { ...guest }) as Guest
	)
	const [selectedIndex, setSelectedIndex] = useState(0)
	const [showDeleteAlert, setShowDeleteAlert] = useState(false)
	const [answeredQuestions, setAnsweredQuestions] = useState<
		RSVPAnsweredQuestion[] | undefined
	>(undefined)

	const mainContext = useContext(MainContext)
	const apiWithToken = useApi()

	useEffect(() => {
		const fetch = async () => {
			const api = await apiWithToken()

			if (
				guest?.id == null ||
				selectedIndex !== 1 ||
				answeredQuestions != null ||
				api == null
			) {
				return
			}

			try {
				const q = await api.rsvpInvitation.answers(eventGroupId, guest.id)
				setAnsweredQuestions(q)
			} catch {}
		}

		fetch()
	}, [eventGroupId, guest?.id, answeredQuestions, selectedIndex, apiWithToken])

	const handleSave = async (closeAfterSave = false) => {
		const api = await apiWithToken()

		if (api == null) {
			return
		}

		if (
			!isNonEmptyString(localGuest.sortableFields.firstName) ||
			!isNonEmptyString(localGuest.sortableFields.lastName) ||
			(isNonEmptyString(localGuest.sortableFields.email)
				? !isEmail(localGuest.sortableFields.email)
				: false)
		) {
			mainContext.setNotification({
				type: "warning",
				title: t("missingFields"),
			})

			return
		}

		let updatedGuest: Guest
		const dto = guestDTO(localGuest)

		try {
			if (guest?.id != null) {
				updatedGuest = await api.guests.update(
					dto,
					guest.id,
					eventGroupId,
					guest.guestGroupId
				)

				// Grouped questions based on invitation id.
				const groupedQuestions = answeredQuestions?.reduce(
					(acc, q) => {
						if (acc[q.rsvpInvitationId] == null) {
							acc[q.rsvpInvitationId] = []
						}

						acc[q.rsvpInvitationId].push(q)

						return acc
					},
					{} as Record<string, RSVPAnsweredQuestion[]>
				)

				// Update questions.
				Object.entries(groupedQuestions ?? []).forEach(
					async ([invitationId, qs]) => {
						await api.rsvpInvitation.saveAnswers(
							eventGroupId,
							invitationId,
							guest.id,
							qs
						)
					}
				)
			} else {
				updatedGuest = await api.guests.create(dto, eventGroupId)
			}

			action(updatedGuest)

			mainContext.setNotification({
				type: "success",
				title: t("notification.saveSuccess"),
			})

			if (closeAfterSave) {
				mainContext.setSheet(null)
			}

			if (guest?.id == null) {
				setLocalGuest(emptyGuest)
				return
			}

			mainContext.setSheet(null)
		} catch (e) {
			mainContext.setNotification({
				type: "warning",
				title: t("notification.saveFailure"),
			})

			return
		}
	}

	const handleDelete = async () => {
		if (localGuest.id == null) {
			return false
		}

		return onDelete?.()
	}

	const Info = (
		<div className="mt-12 flex flex-col items-center">
			<div className="mx-auto flex w-[360px] flex-col items-center gap-6">
				<div className="flex gap-3">
					<VLabeledInput
						label={t("guests.sheet.addEdit.firstName")}
						value={localGuest.sortableFields.firstName}
						onChange={(n) =>
							setLocalGuest({
								...localGuest,
								sortableFields: {
									...localGuest.sortableFields,
									firstName: n,
								},
							})
						}
					/>
					<VLabeledInput
						label={t("guests.sheet.addEdit.lastName")}
						value={localGuest.sortableFields.lastName}
						onChange={(n) =>
							setLocalGuest({
								...localGuest,
								sortableFields: {
									...localGuest.sortableFields,
									lastName: n,
								},
							})
						}
					/>
				</div>
				<VLabeledInput
					label={t("guests.sheet.addEdit.email")}
					value={localGuest.sortableFields.email ?? ""}
					onChange={(n) =>
						setLocalGuest({
							...localGuest,
							sortableFields: {
								...localGuest.sortableFields,
								email: n,
							},
						})
					}
				/>
			</div>

			<div className="mt-12 w-full pb-4">
				<Tags
					selectedTags={localGuest.tags}
					onSelectTag={(tag) =>
						setLocalGuest({
							...localGuest,
							tags: [...localGuest.tags, tag],
						})
					}
					onDeselectTag={(tag) =>
						setLocalGuest({
							...localGuest,
							tags: [...localGuest.tags.filter((t) => t.id !== tag.id)],
						})
					}
				/>
			</div>
		</div>
	)

	return (
		<>
			<Alert
				title={t("guests.alert.delete.confirmation.title")}
				text={`${t("guests.alert.delete.confirmation.text")} ${t(
					"cannotBeUndone"
				)}`}
				buttonTitle={t("guests.alert.delete.confirmation.button")}
				onClick={() => {
					setShowDeleteAlert(false)
					handleDelete()
					mainContext.setSheet(null)
				}}
				open={showDeleteAlert}
				onClose={() => {
					setShowDeleteAlert(false)
				}}
			/>

			<SheetWithSegmentedControl
				title={title}
				segmentedControl={
					guest == null
						? undefined
						: {
								items: [
									t("guests.sheet.addEdit.tab.1"),
									t("guests.sheet.addEdit.tab.2"),
									t("guests.sheet.addEdit.tab.3"),
								].map((t, i) => ({
									title: <span key={i}>{t}</span>,
									action: setSelectedIndex,
								})),
								selectedIndex,
						  }
				}
				topButtonAction={() => {
					if (onDelete == null) {
						return false
					}

					setShowDeleteAlert(true)
					return false
				}}
				bottomButton={{
					title: buttonTitle,
					action: () => handleSave(false),
				}}
				extraBottomContent={
					<>
						{guest?.id != null ? undefined : (
							<div className="flex items-center">
								<Button
									className="mx-auto mt-16 w-[160px]"
									variant="outline"
									onClick={() => handleSave(true)}
								>
									{t("guests.sheet.add.button.saveAndClose")}
								</Button>
							</div>
						)}
					</>
				}
			>
				{selectedIndex === 0 ? (
					Info
				) : selectedIndex === 1 ? (
					<GuestRSVP
						guestId={guest?.id ?? ""}
						answeredQuestions={answeredQuestions}
						onQuestionsChange={setAnsweredQuestions}
					/>
				) : (
					<GuestNotes />
				)}
			</SheetWithSegmentedControl>
		</>
	)
}

export default memo(withI18n(AddEditSheet))
