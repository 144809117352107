import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { DashboardState, dashReducer } from "dashboard/dashSlice"
import { GuestsState } from "dashboard/guests/guestsSlice"
import { FAQState } from "dashboard/pages/faqSlice"
import { HomeState } from "dashboard/pages/homeSlice"
import { RSVPState } from "dashboard/pages/rsvpSlice"
import { ScheduleState } from "dashboard/schedule/scheduleSlice"
import { TypedUseSelectorHook, useSelector } from "react-redux"
import { AuthState, authReducer } from "store/authSlice"
import { I18n, i18nReducer } from "store/i18nSlice"
import api from "utils/api"
import { AppConfig } from "utils/envSetup"

const reducer = combineReducers({
	auth: authReducer,
	dash: dashReducer,
	i18n: i18nReducer,
})

const useRootSelector: TypedUseSelectorHook<RootState> = useSelector

export type RootState = {
	auth: AuthState
	i18n: I18n
	dash: {
		common: DashboardState
		guests: GuestsState
		schedule: ScheduleState
		pages: {
			home: HomeState
			rsvp: RSVPState
			faq: FAQState
		}
	}
}

export const useAuthSelector = () => useRootSelector((state) => state.auth)

export const useI18nSelector = () => useRootSelector((state) => state.i18n)

export const useDashSelector = () =>
	useRootSelector((state) => state.dash.common)

export const useGuestSelector = () =>
	useRootSelector((state) => state.dash.guests)
export const useScheduleSelector = () =>
	useRootSelector((state) => state.dash.schedule)
export const useRSVPSelector = () =>
	useRootSelector((state) => state.dash.schedule.rsvpQuestions)

export const usePagesSelector = () =>
	useRootSelector((state) => state.dash.pages)

export const useHomeSelector = () =>
	useRootSelector((state) => state.dash.pages.home)
export const useRSPVSelector = () =>
	useRootSelector((state) => state.dash.pages.rsvp)
export const useFAQSelector = () =>
	useRootSelector((state) => state.dash.pages.faq)

export default configureStore({
	reducer,
	middleware: (getDefaultMiddleware) => {
		return getDefaultMiddleware({
			thunk: {
				extraArgument: { api },
			},
		})
	},
	devTools: AppConfig.isDev,
})
