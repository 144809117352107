import Button from "components/Button"
import H2 from "components/H2"
import { ReactNode } from "react"

const EmptyState = ({
	title,
	text,
	icon,
	button,
}: {
	title: string
	text: ReactNode
	icon: string
	button: {
		text: string
		onClick: () => void
	}
}): JSX.Element => (
	<div className="flex items-center justify-between rounded-3xl bg-light-200 p-9">
		<div className="flex items-center gap-6">
			<img className="opacity-140" src={icon} />
			<H2>{title}</H2>
		</div>

		<div className="opacity-60">{text}</div>

		<Button className="w-[220px]" onClick={button.onClick}>
			{button.text}
		</Button>
	</div>
)

export default EmptyState
