import { createSelector } from "@reduxjs/toolkit"
import useApi from "common/useApi"
import withI18n, { I18nProps } from "common/withI18n"
import Alert from "components/Alert"
import Capsule from "components/Capsule"
import RadioButton from "components/RadioButton"
import SheetSection from "components/SheetSection"
import SheetWithSegmentedControl from "components/SheetWithSegmentedControl"
import Tags from "components/Tags"
import VLabeledInput from "components/VLabeledInput"
import { dashActions } from "dashboard/dashSlice"
import { GuestsState } from "dashboard/guests/guestsSlice"
import RSVPSection from "dashboard/schedule/RSVPSection"
import { scheduleActions } from "dashboard/schedule/scheduleSlice"
import Switch from "dashboard/Switch"
import Event, { eventDTO } from "models/Event"
import RSVPQuestion, { emptyQuestion, questionDTO } from "models/RSVPQuestion"
import Tag from "models/Tag"
import timezones from "models/timezones"
import { memo, useContext, useState } from "react"
import store, {
	useDashSelector,
	useGuestSelector,
	useRSVPSelector,
	useScheduleSelector,
} from "store"
import MainContext from "store/context/MainContext"
import { isNonEmptyString } from "utils/validations"

const offset = -new Date().getTimezoneOffset()
const GMTLabel = (offset: number) => {
	const hours = Math.floor(Math.abs(offset) / 60)
	const minutes = Math.abs(offset) % 60
	const sign = offset < 0 ? "-" : "+"
	const leadingHour = Math.abs(hours) < 10 ? "0" : ""
	const leadingMinute = Math.abs(minutes) < 10 ? "0" : ""

	return `GMT ${sign}${leadingHour}${hours}:${leadingMinute}${minutes}`
}
const selectNumberOfSelectedGuests = createSelector(
	(state: GuestsState, tags: Tag[]) => ({
		guests: state.all,
		tags,
	}),
	({ guests, tags }) =>
		guests.filter((g) =>
			g.tags.some((t) => tags.some((et) => t.tag === et.tag))
		).length
)

const AddEditSheet = ({
	t,
	event,
	date,
	title,
	buttonTitle,
	focusTags = false,
}: I18nProps & {
	event?: Event
	date?: string
	title: string
	buttonTitle: string
	focusTags?: boolean
}) => {
	const { eventGroupId, mainEventId, tags } = useDashSelector()
	const rsvpQuestions = useRSVPSelector()
	const events = useScheduleSelector().events.length
	const guests = useGuestSelector()
	const [isSaving, setIsSaving] = useState(false)
	const [localEvent, setLocalEvent] = useState(
		(event == null
			? {
					eventStartAt: date ?? "",
					eventEndAt: undefined,
					timeZone: {
						name: timezones.find((t) => t.offset === offset)?.name ?? "London",
						offset,
					},
					name: "",
					description: "",
					tags: [],
					type: "WEDDING",
					location: {
						name: "",
						address: "",
					},
					eventGroupId,
			  }
			: { ...event }) as Event
	)
	const [localRSVPQuestions, setLocalRSVPQuestions] = useState(
		event?.id == null
			? [
					emptyQuestion(1, "COMING_YES_NO", t),
					emptyQuestion(2, "PLUS_ONES", t),
					emptyQuestion(4, "FOOD", t),
					emptyQuestion(5, "TRAVEL", t),
			  ]
			: rsvpQuestions[event.id]
	)
	const [endAt, setEndAt] = useState("")
	const [showDeleteAlert, setShowDeleteAlert] = useState(false)
	const [shouldSendAllGuests, setShouldSendAllGuests] = useState(false)
	const [selectedIndex, setSelectedIndex] = useState(focusTags ? 2 : 0)
	const selectedGuests = selectNumberOfSelectedGuests(
		guests,
		shouldSendAllGuests ? tags : localEvent.tags
	)

	const mainContext = useContext(MainContext)
	const apiWithToken = useApi()

	const deleteRemovedQuestions = async (eventId: string) => {
		const api = await apiWithToken()

		if (api == null) {
			return
		}

		const questionsToRemove: RSVPQuestion[] = []

		// Delete any RSVP questions that are no longer in the local state.
		for (const q of rsvpQuestions[eventId]) {
			if (localRSVPQuestions.some((lq) => lq.id === q.id)) {
				continue
			}

			try {
				await api.rsvpQuestions.delete(eventGroupId, eventId, q.id)
				questionsToRemove.push(q)
			} catch (e) {
				console.log(q.question, e)
			}
		}

		store.dispatch(
			scheduleActions.removeRSVPQuestions({
				id: eventId,
				questions: questionsToRemove,
			})
		)
	}

	const validateQuestions = () => {
		const validQuestions = localRSVPQuestions.every(
			(q) =>
				isNonEmptyString(q.question) &&
				(q.answers.length > 0 || q.allowCustomAnswer) &&
				q.answers.every((a) => isNonEmptyString(a) || q.allowCustomAnswer)
		)

		if (!validQuestions) {
			mainContext.setNotification({
				type: "error",
				title: t("schedule.sheet.addEdit.notification.missingAnswers"),
			})

			return false
		}

		return true
	}

	const saveNewAndModifiedQuestions = async (eventId: string) => {
		const questionsToCreate: RSVPQuestion[] = []
		const questionsToUpdate: RSVPQuestion[] = []

		const api = await apiWithToken()

		if (api == null) {
			return
		}

		if (!validateQuestions()) {
			mainContext.setNotification({
				type: "error",
				title: t("schedule.sheet.addEdit.notification.missingAnswers"),
			})
			return
		}

		for (const q of localRSVPQuestions) {
			try {
				// The questions might be empty if the question was just created, because we're still during the previous loop and the selector hasn't updated yet.
				const stateQuestion = rsvpQuestions[eventId]?.find(
					(sq) => sq.id === q.id
				)

				// If the question doesn't exist in state, create it.
				if (stateQuestion == null) {
					await api.rsvpQuestions.create(
						eventGroupId,
						eventId,
						questionDTO(q, t)
					)
					questionsToCreate.push(q)
				} else if (stateQuestion != q) {
					// Otherwise, update it, if it's different.
					await api.rsvpQuestions.update(
						eventGroupId,
						eventId,
						questionDTO(q, t),
						q.id
					)
					questionsToUpdate.push(q)
				}
			} catch (e) {
				console.log(q.question, e)
			}
		}

		store.dispatch(
			scheduleActions.addRSVPQuestions({
				id: eventId,
				questions: questionsToCreate,
			})
		)
		store.dispatch(
			scheduleActions.updateRSVPQuestions({
				id: eventId,
				questions: questionsToUpdate,
			})
		)
	}

	// eslint-disable-next-line sonarjs/cognitive-complexity
	const handleSave = async () => {
		const api = await apiWithToken()

		if (api == null) {
			return
		}

		if (
			!isNonEmptyString(localEvent.name) ||
			!isNonEmptyString(localEvent.description) ||
			!isNonEmptyString(localEvent.location.name) ||
			!isNonEmptyString(localEvent.location.address) ||
			!isNonEmptyString(localEvent.eventStartAt) ||
			!isNonEmptyString(localEvent.type)
		) {
			mainContext.setNotification({
				type: "warning",
				title: t("missingFields"),
			})

			return
		}

		if (!validateQuestions()) {
			mainContext.setNotification({
				type: "warning",
				title: t("missingQuestions"),
			})

			return
		}

		if (localEvent.tags.length === 0 && !shouldSendAllGuests) {
			mainContext.setNotification({
				type: "warning",
				title: t("missingTags"),
			})

			return
		}

		const dto = eventDTO({
			...localEvent,
			tags: shouldSendAllGuests ? tags : localEvent.tags,
		})

		setIsSaving(true)

		try {
			if (event?.id != null) {
				store.dispatch(
					scheduleActions.update(
						await api.events.update(dto, event.id, eventGroupId)
					)
				)

				try {
					await deleteRemovedQuestions(event.id)
					await saveNewAndModifiedQuestions(event.id)
				} catch {}
			} else {
				const newEvent = await api.events.create(dto, eventGroupId)

				store.dispatch(scheduleActions.add([newEvent]))

				// If we have no events yet, set this one as the main event.
				if (events === 0) {
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					store.dispatch(dashActions.setMainEventId(newEvent.id!))
				}

				try {
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					await saveNewAndModifiedQuestions(newEvent.id!)
				} catch {}
			}

			mainContext.setNotification({
				type: "success",
				title: t("notification.saveSuccess"),
			})

			mainContext.setSheet(null)

			setIsSaving(false)

			return
		} catch (e) {
			mainContext.setNotification({
				type: "warning",
				title: t("notification.saveFailure"),
			})

			setIsSaving(false)

			return
		}
	}

	const handleDelete = async () => {
		const api = await apiWithToken()

		if (event?.id == null || api == null) {
			return false
		}

		try {
			await api.events.delete(event.id, eventGroupId)
			store.dispatch(scheduleActions.remove([event]))

			mainContext.setNotification({
				type: "success",
				title: t("notification.deleteSuccess"),
			})
			mainContext.setSheet(null)

			return true
		} catch (e) {
			mainContext.setNotification({
				type: "warning",
				title: t("notification.deleteFailure"),
			})

			return false
		}
	}

	const Info = (
		<>
			<SheetSection title={t("schedule.sheet.addEdit.info.basics.title")}>
				<VLabeledInput
					label={t("schedule.sheet.addEdit.info.basics.name")}
					value={localEvent.name}
					onChange={(n) => setLocalEvent({ ...localEvent, name: n })}
				/>
				<VLabeledInput
					inputType="area"
					label={t("schedule.sheet.addEdit.info.basics.description")}
					value={localEvent.description ?? ""}
					onChange={(n) => setLocalEvent({ ...localEvent, description: n })}
				/>
			</SheetSection>

			<SheetSection title={t("schedule.sheet.addEdit.info.date.title")}>
				<style>{`input::-webkit-datetime-edit { margin: auto !important; }`}</style>
				<VLabeledInput
					className="w-full text-center"
					type="datetime-local"
					step="1800"
					label={t("schedule.sheet.addEdit.info.date.start")}
					value={localEvent.eventStartAt}
					onChange={(n) => {
						if (localEvent.eventEndAt != null && localEvent.eventEndAt != "") {
							setLocalEvent({ ...localEvent, eventStartAt: n })
							return
						}

						if (localEvent.eventEndAt == null) {
							setLocalEvent({ ...localEvent, eventStartAt: n })
							setEndAt(n)
						} else {
							setLocalEvent({ ...localEvent, eventStartAt: n, eventEndAt: n })
						}
					}}
				/>

				<div className="w-full">
					{localEvent.eventEndAt == null ? null : (
						<VLabeledInput
							className="w-full text-center"
							type="datetime-local"
							step="1800"
							label={t("schedule.sheet.addEdit.info.date.end")}
							value={localEvent.eventEndAt}
							onChange={(n) => {
								setLocalEvent({ ...localEvent, eventEndAt: n })
								setEndAt(n)
							}}
						/>
					)}
					<div
						className={`${
							localEvent.eventEndAt == null ? "w-[360px]" : "mt-6"
						} flex cursor-pointer gap-4`}
						onClick={() => {
							if (localEvent.eventEndAt == null) {
								setLocalEvent({
									...localEvent,
									eventEndAt: endAt,
								})
							} else {
								setLocalEvent({
									...localEvent,
									eventEndAt: undefined,
								})
							}
						}}
					>
						<RadioButton checked={localEvent.eventEndAt == null} />
						<span>{t("schedule.sheet.addEdit.info.date.noEndTime")}</span>
					</div>
				</div>

				<div className="relative w-full text-center">
					<div className="text-sm font-semibold">
						{t("schedule.sheet.addEdit.info.date.timeZone")}
					</div>
					<select
						name="timezone"
						className="mt-2 w-full text-center"
						value={localEvent.timeZone?.name ?? ""}
						onChange={(e) => {
							const newTimeZone = timezones.find(
								(t) => t.name === e.target.value
							)

							setLocalEvent({
								...localEvent,
								timeZone: newTimeZone,
							})
						}}
					>
						{[
							{
								name: t("selectOne"),
								offset: undefined as number | undefined,
							},
						]
							.concat(timezones)
							.map((t) => (
								<option value={t.name} key={t.name}>
									{t.offset == null ? "" : `${GMTLabel(t.offset)}, `}
									{`${t.name}`}
								</option>
							))}
					</select>
				</div>
			</SheetSection>

			<SheetSection
				title={t("schedule.sheet.addEdit.info.location.title")}
				hideBottomLine
			>
				<VLabeledInput
					label={t("schedule.sheet.addEdit.info.location.name")}
					value={localEvent.location?.name ?? ""}
					onChange={(n) =>
						setLocalEvent({
							...localEvent,
							location: {
								...localEvent.location,
								name: n,
							},
						})
					}
				/>
				<VLabeledInput
					label={t("schedule.sheet.addEdit.info.location.address")}
					value={localEvent.location?.address ?? ""}
					onChange={(n) =>
						setLocalEvent({
							...localEvent,
							location: {
								...localEvent.location,
								address: n,
							},
						})
					}
				/>
			</SheetSection>
		</>
	)

	const Guests = (
		<>
			<SheetSection
				title={t("schedule.sheet.addEdit.guests.title")}
				fullWidth
				hideBottomLine={shouldSendAllGuests}
			>
				<div className="flex w-full flex-col justify-center gap-6">
					<div className="relative flex w-full items-center justify-center">
						<span
							className={`absolute left-[32%] cursor-pointer ${
								shouldSendAllGuests ? "font-bold" : ""
							}`}
							onClick={() => setShouldSendAllGuests(true)}
						>
							{t("schedule.sheet.addEdit.guests.tags.all")}
						</span>
						<Switch
							checked={!shouldSendAllGuests}
							onChange={(v) => setShouldSendAllGuests(!v)}
						/>
						<span
							className={`absolute left-[57%] cursor-pointer ${
								shouldSendAllGuests ? "" : "font-bold"
							}`}
							onClick={() => setShouldSendAllGuests(false)}
						>
							{t("schedule.sheet.addEdit.guests.tags.specific")}
						</span>
					</div>
					{shouldSendAllGuests === false ? null : (
						<span className="text-center text-sm italic text-light-500">
							{t("schedule.sheet.addEdit.guests.tags.disclaimer")}
						</span>
					)}
				</div>
			</SheetSection>

			{shouldSendAllGuests ? null : (
				<SheetSection
					title={t("schedule.sheet.addEdit.guests.groups.title")}
					fullWidth
					hideBottomLine
				>
					<Tags
						selectedTags={localEvent.tags}
						onSelectTag={(tag) =>
							setLocalEvent({
								...localEvent,
								tags: [...localEvent.tags, tag],
							})
						}
						onDeselectTag={(tag) =>
							setLocalEvent({
								...localEvent,
								tags: [...localEvent.tags.filter((t) => t.id !== tag.id)],
							})
						}
					/>
				</SheetSection>
			)}
		</>
	)

	const Content = () => {
		switch (selectedIndex) {
			case 0:
				return Info
			case 1:
				return (
					<RSVPSection
						isMainEvent={event?.id === mainEventId}
						questions={localRSVPQuestions.toSorted((a, b) => a.order - b.order)}
						onQuestionsUpdate={setLocalRSVPQuestions}
					/>
				)
			case 2:
				return Guests
			default:
				return null
		}
	}

	return (
		<>
			<Alert
				buttonTitle={t("schedule.sheet.addEdit.alert.delete.button")}
				title={t("schedule.sheet.addEdit.alert.delete.title")}
				text={
					<>
						{t("schedule.sheet.addEdit.alert.delete.text")}{" "}
						<span className="font-semibold">{event?.name}</span>?{" "}
						{t("cannotBeUndone")}
					</>
				}
				open={showDeleteAlert}
				onClose={() => setShowDeleteAlert(false)}
				onClick={() => {
					handleDelete()
					setShowDeleteAlert(false)
				}}
			/>

			<SheetWithSegmentedControl
				title={title}
				segmentedControl={{
					items: [
						t("schedule.sheet.addEdit.tab.1"),
						t("schedule.sheet.addEdit.tab.2"),
						t("schedule.sheet.addEdit.tab.3"),
					].map((t, i) => ({
						title: (
							<span
								key={i}
								className={`${
									i === 2 ? "flex items-center justify-center gap-2" : ""
								}`}
							>
								{t}{" "}
								{i === 2 ? (
									<Capsule className="my-1 rounded-3xl bg-light-500 bg-opacity-30 px-3 py-1 text-xs">
										{selectedGuests}
									</Capsule>
								) : null}
							</span>
						),
						action: setSelectedIndex,
					})),
					selectedIndex,
				}}
				topButtonAction={
					event == null
						? undefined
						: () => {
								setShowDeleteAlert(true)
								return false
						  }
				}
				bottomButton={{
					title: buttonTitle,
					action: handleSave,
				}}
				isLoading={isSaving}
			>
				{Content()}
			</SheetWithSegmentedControl>
		</>
	)
}

export default memo(withI18n(AddEditSheet))
