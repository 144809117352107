import SetupAccount from "common/SetupAccount"
import Comms from "dashboard/comms/Comms"
import Dashboard from "dashboard/dashboard/Dashboard"
import Design from "dashboard/design/Design"
import Guests from "dashboard/guests/Guests"
import DashboardLayout from "dashboard/Layout"
import Pages from "dashboard/pages/Pages"
import Profile from "dashboard/profile/Profile"
import Schedule from "dashboard/schedule/Schedule"
import Settings from "dashboard/settings/Settings"
import EventPage from "event/EventPage"
import EventRSVP from "event/EventRSVP"
import Home from "home/Home"
import { ReactElement } from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import Login from "routes/Login"
import PrivateRoute from "routes/PrivateRoute"
import R from "routes/R"

const Router = (): ReactElement => (
	<Routes>
		<Route index element={<Home />} />
		<Route
			path={R.interested}
			element={
				<iframe
					className="h-full min-h-screen w-full bg-dark-100"
					src="https://mailchi.mp/9aa4a94912b8/im-interested-in-eventa"
				/>
			}
		/>
		<Route path={R.login} element={<Login />} />

		<Route path={R.setupAccount} element={<SetupAccount />}></Route>

		<Route path={R.event} element={<EventPage />}></Route>
		<Route path={R.eventRSVP} element={<EventRSVP />}></Route>

		<Route
			path={R.dashboard}
			element={<PrivateRoute component={DashboardLayout} />}
		>
			<Route index element={<PrivateRoute component={Dashboard} />} />
			<Route path={R.guests} element={<PrivateRoute component={Guests} />} />
			<Route path={R.comms} element={<PrivateRoute component={Comms} />} />
			<Route
				path={R.schedule}
				element={<PrivateRoute component={Schedule} />}
			/>
			<Route path={R.design} element={<PrivateRoute component={Design} />} />
			<Route path={R.pages} element={<PrivateRoute component={Pages} />} />
			<Route
				path={R.settings}
				element={<PrivateRoute component={Settings} />}
			/>
			<Route path={R.profile} element={<PrivateRoute component={Profile} />} />
		</Route>

		<Route path="*" element={<Navigate to={R.home} />} />
	</Routes>
)

export default Router
