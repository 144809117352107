import { withAuthenticationRequired } from "@auth0/auth0-react"
import { ComponentType, ReactElement } from "react"

const PrivateRoute = ({
	component,
}: {
	component: ComponentType
}): ReactElement | null => {
	const Component = withAuthenticationRequired(component, {
		returnTo: location.pathname,
	})

	return <Component />
}

export default PrivateRoute
