import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { dashActions } from "dashboard/dashSlice"
import Event from "models/Event"
import RSVPQuestion from "models/RSVPQuestion"
import Tag from "models/Tag"
import store from "store"
import { ApiType } from "utils/api"

const initialState = {
	events: [],
	rsvpQuestions: {},
	selectedTags: [],
} as ScheduleState

const slice = createSlice({
	name: "dashboard/schedule",
	initialState,
	reducers: {
		add: (state, { payload }: PayloadAction<Event[]>) => {
			state.events = [...state.events, ...payload]
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			state.rsvpQuestions[payload[0].id!] = []
		},
		update: (state, { payload }: PayloadAction<Event>) => {
			const index = state.events.findIndex((event) => event.id === payload.id)

			if (index === -1) {
				return
			}

			state.events[index] = payload
		},
		remove: (state, { payload }: PayloadAction<Event[]>) => {
			state.events = state.events.filter(
				(event) => !payload.some((e) => e.id === event.id)
			)
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			state.rsvpQuestions[payload[0].id!] = []
		},
		set: (state, { payload }: PayloadAction<Event[]>) => {
			state.events = payload
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			payload.forEach((e) => (state.rsvpQuestions[e.id!] = []))
		},

		addRSVPQuestions: (
			state,
			{ payload }: PayloadAction<{ id: string; questions: RSVPQuestion[] }>
		) => {
			state.rsvpQuestions[payload.id] = [
				...state.rsvpQuestions[payload.id],
				...payload.questions,
			]
		},
		updateRSVPQuestions: (
			state,
			{ payload }: PayloadAction<{ id: string; questions: RSVPQuestion[] }>
		) => {
			state.rsvpQuestions[payload.id] = state.rsvpQuestions[payload.id].map(
				(question) =>
					payload.questions.find((q) => q.id === question.id) ?? question
			)
		},
		removeRSVPQuestions: (
			state,
			{ payload }: PayloadAction<{ id: string; questions: RSVPQuestion[] }>
		) => {
			state.rsvpQuestions[payload.id] = state.rsvpQuestions[payload.id].filter(
				(q) => !payload.questions.some((question) => question.id === q.id)
			)
		},

		filter: (state, { payload }: PayloadAction<Tag[]>) => {
			state.selectedTags = payload
		},
		clearFilter: (state) => {
			state.selectedTags = []
		},
		setRSVPQuestions: (
			state,
			{ payload }: PayloadAction<{ [key: string]: RSVPQuestion[] }>
		) => {
			state.rsvpQuestions = payload
		},
		setRSVPQuestionsForEvent: (
			state,
			{ payload }: PayloadAction<{ id: string; questions: RSVPQuestion[] }>
		) => {
			state.rsvpQuestions[payload.id] = payload.questions
		},
		clearState: () => initialState,
	},
	extraReducers: (builder) => {
		builder.addCase(
			dashActions.fetchInitialState.fulfilled,
			(state, { payload }) => {
				state.events = payload.events
				state.rsvpQuestions = payload.rsvpQuestions
			}
		)
		builder.addCase(dashActions.removeTags, (state, { payload }) => {
			state.events = state.events.map((guest) => ({
				...guest,
				tags: guest.tags.filter((tag) => !payload.some((t) => t.id === tag.id)),
			}))
		})
	},
})

export const scheduleActions = {
	...slice.actions,

	//#region Thunks
	fetch: ({ eventGroupId, api }: { eventGroupId: string; api: ApiType }) => {
		return async (dispatch: typeof store.dispatch) => {
			try {
				dispatch(scheduleActions.set(await api.events.list(eventGroupId)))
			} catch (e) {
				console.error(e)
			}
		}
	},
	//#endregion
}

export type ScheduleState = {
	events: Event[]
	rsvpQuestions: {
		[key: string]: RSVPQuestion[]
	}
	selectedTags: Tag[]
}

export const scheduleReducer = slice.reducer
