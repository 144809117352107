import { useAuth0 } from "@auth0/auth0-react"
import { Navigate } from "react-router-dom"
import R from "routes/R"

const Login = () => {
	const {
		isLoading: isAuthLoading,
		isAuthenticated,
		loginWithRedirect,
	} = useAuth0()

	if (isAuthLoading) {
		return null
	} else if (!isAuthenticated) {
		loginWithRedirect()
		return null
	} else {
		return <Navigate to={`${R.dashboard}`} />
	}
}

export default Login
