import HLine from "components/HLine"
import Popup from "components/Popup"
import Guest from "models/Guest"
import { RSVPStatisticsCustomAnswer } from "models/RSVPQuestion"

const CustomAnswersPopup = ({
	guests,
	answers,
	question,
	isLoading = false,
	onClose,
}: {
	guests: Guest[]
	answers: RSVPStatisticsCustomAnswer[]
	question: string
	isLoading?: boolean
	onClose: () => void
}): JSX.Element => (
	<Popup
		onClose={onClose}
		className="h-[600px] w-[720px]"
		title={question}
		isLoading={isLoading}
	>
		<div className="overflow-y-scroll pb-10">
			{answers.map((a, i) => {
				const guest = guests.find((g) => g.id === a.guestId)

				if (guest == null) {
					return null
				}

				const name = `${guest.sortableFields.firstName} ${guest.sortableFields.lastName}`

				return (
					<>
						{i === 0 ? <HLine /> : null}

						<div
							key={a.id}
							className="flex h-16 items-center px-12 text-dark-100"
						>
							<span className="min-w-[200px] text-lg font-semibold tracking-wider">
								{name}:{" "}
							</span>
							<span>{a.answerText}</span>
						</div>

						<HLine />
					</>
				)
			})}
		</div>
	</Popup>
)

export default CustomAnswersPopup
