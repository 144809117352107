import { Dialog, Transition } from "@headlessui/react"
import { I18nProps } from "common/withI18n"
import VLabeledInput from "components/VLabeledInput"
import { RSVPAnswerForOthers } from "models/RSVPAnswerForOthers"
import { Fragment, ReactNode, useRef, useState } from "react"

type LocalGuest = RSVPAnswerForOthers["guest"] & {
	eventIds: string[]
}

const AddEditGuestPopup = ({
	title,
	buttonTitle,
	guest,
	open,
	onClick,
	onClose,
	t,
}: {
	title: ReactNode
	buttonTitle: string
	guest: LocalGuest | undefined
	open: boolean
	onClick: (guest: LocalGuest) => void
	onClose: () => void
	t: I18nProps["t"]
}): JSX.Element => {
	const cancelButtonRef = useRef(null)
	const [localGuest, setLocalGuest] = useState<LocalGuest | undefined>(guest)

	if (localGuest == null) {
		return <></>
	}

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as="div"
				className="relative z-10"
				initialFocus={cancelButtonRef}
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				onClose={() => {}}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-light-100 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
								<div className="bg-light-100 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
									<div className="mt-3 flex min-h-[38px] flex-col justify-center text-center sm:mt-0 sm:ml-4 sm:text-left">
										<Dialog.Title
											as="h3"
											className="text-center text-lg font-medium leading-6 text-gray-900"
										>
											{title}
										</Dialog.Title>

										<div className="mt-6 mb-4 flex gap-4">
											<VLabeledInput
												label={t("guests.sheet.addEdit.firstName")}
												value={localGuest.firstName}
												onChange={(n) =>
													setLocalGuest({ ...localGuest, firstName: n })
												}
											/>
											<VLabeledInput
												label={t("guests.sheet.addEdit.lastName")}
												value={localGuest.lastName}
												onChange={(n) =>
													setLocalGuest({ ...localGuest, lastName: n })
												}
											/>
										</div>
									</div>
								</div>

								<div className="bg-light-200 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
									<button
										type="button"
										className="mt-3 inline-flex w-full justify-center rounded-3xl border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
										onClick={onClose}
										ref={cancelButtonRef}
									>
										Cancel
									</button>

									<button
										type="button"
										className="inline-flex w-full justify-center rounded-3xl border border-transparent bg-primary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-opacity-95 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
										onClick={() => onClick(localGuest)}
									>
										{buttonTitle}
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}

export default AddEditGuestPopup
