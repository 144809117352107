import { Disclosure } from "@headlessui/react"
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/20/solid"
import useApi from "common/useApi"
import withI18n, { I18nProps } from "common/withI18n"
import Button from "components/Button"
import Capsule from "components/Capsule"
import H2 from "components/H2"
import HTMLBackgroundStyle from "components/HTMLBackgroundStyle"
import Loader from "components/Loader"
import Event from "models/Event"
import { memo, useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import R from "routes/R"
import { EventRSVP } from "utils/api/event"

const EventPage = ({ t }: I18nProps): JSX.Element => {
	const location = useLocation()

	const params = new URLSearchParams(location.search)
	const uniqueLinkHash = params.get("uniqueLinkHash")?.replaceAll(" ", "+")

	if (uniqueLinkHash == null) {
		return <div>Invalid invitation</div>
	}

	return <EventPageContent t={t} uniqueLinkHash={uniqueLinkHash} />
}

const EventPageContent = ({
	t,
	uniqueLinkHash,
}: {
	t: I18nProps["t"]
	uniqueLinkHash: string
}): JSX.Element => {
	const [mainEvent, setMainEvent] = useState({} as Event)
	const [isLoading, setIsLoading] = useState(true)
	const [state, setState] = useState<EventRSVP>({
		allEvents: [],
		questions: [],
		answers: [],
		guests: [],
		mainEventId: "",
		qa: [],
		emailContent: {
			headline: "",
			subheadline: "",
		},
	} as EventRSVP)
	const navigate = useNavigate()
	const apiWithToken = useApi()

	useEffect(() => {
		const fetch = async () => {
			const api = await apiWithToken()

			if (api == null) {
				return
			}

			try {
				const response = await api.event.get(uniqueLinkHash, t)

				setIsLoading(false)

				setMainEvent(
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					response.allEvents.find((e) => e.id === response.mainEventId)!
				)

				setState({
					...response,
					allEvents: response.allEvents.sort((a, b) =>
						a.id === response.mainEventId
							? -1
							: b.id === response.mainEventId
							  ? 1
							  : parseFloat(a.eventStartAt) - parseFloat(b.eventStartAt)
					),
				})
			} catch {
				setIsLoading(false)
			}
		}

		fetch()
	}, [uniqueLinkHash, apiWithToken, t])

	const remainingTime = useMemo(() => {
		const minuteDuration = 1000 * 60
		const hourDuration = minuteDuration * 60
		const dayDuration = hourDuration * 24
		const now = Date.now()
		let timeDifference = new Date(mainEvent.eventStartAt).getTime() - now

		const days = Math.floor(timeDifference / dayDuration)
		timeDifference -= days * dayDuration

		const hours = Math.floor(timeDifference / hourDuration)
		timeDifference -= hours * hourDuration

		const minutes = Math.floor(timeDifference / minuteDuration)

		return {
			days,
			hours,
			minutes,
		}
	}, [mainEvent.eventStartAt])

	if (state.questions.length === 0) {
		return (
			<div className="flex h-screen items-center justify-center bg-light-200">
				<HTMLBackgroundStyle color="rgb(227 234 238 / 1)" />
				<div className="-mt-40">
					{isLoading ? <Loader /> : <H2>{t("eventPage.noInvitation")}</H2>}
				</div>
			</div>
		)
	}

	return (
		<div className="h-full w-full bg-light-200">
			<HTMLBackgroundStyle color="rgb(227 234 238 / 1)" />

			<div className="relative mx-auto flex w-full max-w-3xl items-center justify-center overflow-clip rounded-b-2xl">
				<img src={require("./images/eventHeader.jpg")} />
				{state.emailContent == null ? null : (
					<div className="absolute bottom-8 text-center font-title text-white">
						<p className="text-4xl">{state.emailContent.headline}</p>
						<p className="mt-1 text-2xl">{state.emailContent.subheadline}</p>
					</div>
				)}
			</div>

			<div className="mx-auto mt-6 flex max-w-xl flex-col px-4 pb-10 md:mt-10">
				<div className="mx-auto flex max-w-md flex-col items-center gap-1 rounded-3xl border border-light-300 py-4 px-12">
					<span className="font-bold">
						{new Date(mainEvent.eventStartAt).intlDate({
							year: "numeric",
							month: "long",
							day: "numeric",
							weekday: "long",
						})}
					</span>
					<span className="text-center">
						{mainEvent.location.name}, {mainEvent.location.address}
					</span>
				</div>

				<div className="mx-auto mt-10 max-w-[440px] px-4 text-center">
					{t("eventPage.invitation.1")}
					<span className="font-semibold">{mainEvent.name}</span>.{" "}
					{t("eventPage.invitation.2")}
				</div>

				<Capsule className="mx-0 mt-6 bg-white 2xs:mx-auto md:max-w-[50%]">
					<div className="flex gap-1">
						<span className="font-semibold">{remainingTime.days}</span>
						<span className="text-light-500">{t("days")}</span>
					</div>
					<div className="flex gap-1">
						<span className="ml-2 font-semibold">{remainingTime.hours}</span>
						<span className="text-light-500">{t("hours")}</span>
					</div>
					<div className="flex gap-1">
						<span className="ml-2 font-semibold">{remainingTime.minutes}</span>
						<span className="text-light-500">{t("minutes")}</span>
					</div>
				</Capsule>

				<Button
					className="mx-auto mt-8 w-64"
					onClick={() =>
						navigate(R.eventRSVP, {
							state: {
								uniqueLinkHash,
								...state,
							},
						})
					}
				>
					{state.answers.length === 0
						? t("guests.event.button.rsvp")
						: t("guests.event.button.updateRsvp")}
				</Button>

				{state.answers.length === 0 ? null : (
					<div className="mx-auto mt-4">
						<p className="max-w-[330px] text-center italic text-dark-100 text-opacity-50">
							{t("guests.event.button.rsvp.disclaimer")}
						</p>
					</div>
				)}

				{state.qa.length === 0 ? null : (
					<div className="mt-16">
						<H2 className="text-center">{t("pages.sheet.faq.title")}</H2>

						<div className="mt-6 mb-24">
							{state.qa.map((f, i) => (
								<Disclosure as="div" key={i} className="pt-6">
									{({ open }) => (
										<>
											<dt>
												<Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
													<span className="font-semibold leading-7">
														{f.question}
													</span>
													<span className="ml-6 flex h-7 items-center">
														{open ? (
															<MinusSmallIcon
																className="h-6 w-6"
																aria-hidden="true"
															/>
														) : (
															<PlusSmallIcon
																className="h-6 w-6"
																aria-hidden="true"
															/>
														)}
													</span>
												</Disclosure.Button>
											</dt>
											<Disclosure.Panel as="dd" className="mt-2">
												<p className="leading-7 text-gray-600">{f.answer}</p>
											</Disclosure.Panel>
										</>
									)}
								</Disclosure>
							))}
						</div>
					</div>
				)}
			</div>
		</div>
	)
}

export default memo(withI18n(EventPage))
