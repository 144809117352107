import {
	DetailedHTMLProps,
	InputHTMLAttributes,
	TextareaHTMLAttributes,
} from "react"

type BaseProps = {
	label: string
	labelColor?: string
	textColor?: string
	width?: number
	onChange: (e: string) => void
}

type TextProps = BaseProps & {
	inputType?: "text"
} & Omit<
		DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
		"onChange"
	>

type AreaProps = BaseProps & {
	inputType?: "area"
} & Omit<
		DetailedHTMLProps<
			TextareaHTMLAttributes<HTMLTextAreaElement>,
			HTMLTextAreaElement
		>,
		"onChange"
	>

// Even though we're casting inside, type safety still works outside. The reason for this is that we want to remove extra props that are not valid for the input type and at that moment, TS doesn't know what the input type is anymore for a spread object.
const VLabeledInput = ({
	inputType,
	width,
	label,
	labelColor = "text-dark-500",
	textColor = "text-dark-500",
	onChange,
	...props
}: TextProps | AreaProps) => (
	<div
		className={`flex flex-col items-center gap-2 ${
			width == null ? "w-full" : `w-[${width}px]`
		}`}
	>
		<span className={`text-sm font-semibold ${labelColor}`}>{label}</span>
		{inputType === "area" ? (
			<textarea
				className="min-h-28 w-full px-4 text-center"
				{...(props as AreaProps)}
				onChange={(e) => onChange(e.target.value)}
			/>
		) : (
			<input
				className={`w-full text-center ${textColor}`}
				{...(props as TextProps)}
				onChange={(e) => onChange(e.target.value)}
			/>
		)}
	</div>
)

export default VLabeledInput
