import SegmentedControl from "components/SegmentedControl"
import Sheet from "components/Sheet"
import { ReactNode, useState } from "react"

const SheetWithSegmentedControl = ({
	title,
	segmentedControl,
	topButtonAction,
	bottomButton,
	extraBottomContent,
	isLoading,
	children,
}: {
	title: string
	segmentedControl?: {
		items: {
			title: JSX.Element
			action: (index: number) => void
		}[]
		selectedIndex: number
	}
	topButtonAction?: (() => Promise<boolean>) | (() => boolean) | (() => void)
	bottomButton?: {
		title: string
		action: () => void
	}
	onContentScroll?: (scrollTop?: number) => void
	extraBottomContent?: ReactNode
	isLoading?: boolean
	children: ReactNode
}): JSX.Element => {
	const [scrollTop, setScrollTop] = useState(0)

	return (
		<Sheet
			title={title}
			subtitle={
				segmentedControl == null ? null : (
					<SegmentedControl
						titles={segmentedControl.items.map((item) => item.title)}
						hasShadow={scrollTop > 30} // 40 is the actual threshold, but we also animate.
						selectedIndex={segmentedControl.selectedIndex}
						onSelect={(i) => segmentedControl.items[i].action(i)}
					/>
				)
			}
			onContentScroll={(e) => {
				if (e == null) {
					return
				}

				setScrollTop(e)
			}}
			topButtonAction={topButtonAction}
			bottomButton={bottomButton}
			extraBottomContent={extraBottomContent}
			isLoading={isLoading}
		>
			{children}
		</Sheet>
	)
}

export default SheetWithSegmentedControl
