import withI18n, { I18nProps } from "common/withI18n"
import H2 from "components/H2"
import Header from "dashboard/Header"
import brushIcon from "images/brush.svg"

const Design = ({ t }: I18nProps) => (
	<>
		<Header title={t("design.title")} />

		<div className="flex h-full w-full items-center justify-center pb-40">
			<div className="flex w-[432px] flex-col justify-between rounded-3xl bg-light-200 py-9 text-center">
				<div>
					<img className="mx-auto opacity-40" src={brushIcon} />

					<H2 className="mb-6 mt-5">{t("notImplemented.title")}</H2>

					<div className="opacity-60">
						{t("notImplemented.subtitle.1")}
						<br />
						{t("notImplemented.subtitle.2")}
					</div>
				</div>
			</div>
		</div>
	</>
)

export default withI18n(Design)
