import clsx from "clsx"
import { HTMLAttributes, memo, ReactNode } from "react"

const TR = ({
	className,
	children,
	...props
}: {
	className?: string
	children: ReactNode
} & HTMLAttributes<HTMLTableRowElement>) => (
	<tr
		className={clsx(
			"h-16 border-b border-light-300 border-opacity-30",
			className
		)}
		{...props}
	>
		{children}
	</tr>
)

export default memo(TR)
