type AccountTier = {
	name: string
	permissions: {
		serviceMethods: string[]
		itemLimits: {
			events: number
		}
	}
}

export const Permissions = {
	sendRSVP: "rsvp.send",
} as const

export const hasPermission = (permission: string, tier?: AccountTier | null) =>
	tier?.permissions.serviceMethods.includes(permission) === true

export default AccountTier
