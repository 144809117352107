/* eslint-disable import/exports-last */
import Tag from "models/Tag"

export const EventTypeRaw = [
	"WEDDING",
	"WEDDING_REHEARSAL",
	"BAPTISM",
	"PRIVATE_PARTY",
] as const

export type EventType = (typeof EventTypeRaw)[number]

type Event = {
	createdAt: string
	updatedAt: string
	id?: string
	name: string
	type: EventType
	timeZone?: {
		name: string
		offset: number
	}
	eventStartAt: string
	eventEndAt?: string
	location: {
		name: string
		address: string
	}
	description?: string
	eventGroupId?: string
	tags: Tag[]
}

export type EventDTO = Omit<
	Event,
	"id" | "eventGroupId" | "tags" | "createdAt" | "updatedAt"
> & {
	tags: string[]
}

export const eventDTO = (event: Event): EventDTO => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { id, eventGroupId, createdAt, updatedAt, ...eventDTO } = event

	return {
		...eventDTO,
		tags: eventDTO.tags.map((t) => t.tag),
	}
}

export const friendlyEventType = (eventType: EventType) => {
	switch (eventType) {
		case "WEDDING":
			return "Wedding"
		case "WEDDING_REHEARSAL":
			return "Wedding Rehearsal"
		case "BAPTISM":
			return "Baptism"
		case "PRIVATE_PARTY":
			return "Private Party"
		default:
			return "Other"
	}
}

export default Event
