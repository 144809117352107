import H2 from "components/H2"
import { ReactNode } from "react"

const Placeholder = ({
	title,
	text,
	icon,
}: {
	title: string
	text: ReactNode
	icon: string
}): JSX.Element => (
	<div className="flex w-[432px] flex-col justify-between rounded-3xl bg-light-200 px-4 py-9 text-center">
		<div>
			<img className="mx-auto opacity-40" src={icon} />

			<H2 className="mb-6 mt-5">{title}</H2>

			<div className="opacity-60">{text}</div>
		</div>
	</div>
)

export default Placeholder
