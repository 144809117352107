import { guestsActions } from "dashboard/guests/guestsSlice"
import Guest from "models/Guest"
import store from "store"

const updateGuest = (guest: Guest, guests: Guest[]) => {
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const guestIndex = guests.findIndex((g) => g.id === guest.id)!
	const oldGuest = guests[guestIndex]
	const newGuest = {
		...guest,
		hasGroup: oldGuest.hasGroup,
		groupColor: oldGuest.groupColor,
		sortableFields: {
			...guest.sortableFields,
			plusOnes: guest.groupLead
				? `${
						guests.filter((g) => g.guestGroupId === oldGuest.guestGroupId)
							.length - 1
				  }`
				: "",
		},
	}

	store.dispatch(guestsActions.update(newGuest))
}

export default updateGuest
