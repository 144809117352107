import Tag from "models/Tag"
import { perform } from "utils/api/perform"

const root = (id: string) => perform(`/event_groups/${id}/tags`)

const list = async (eventGroupId: string): Promise<Tag[]> =>
	await root(eventGroupId)("get", `/`)

const create = async (name: string, eventGroupId: string): Promise<Tag> =>
	await root(eventGroupId)("post", `/`, {
		tag: name,
	})

const _delete = (tagId: string, eventGroupId: string): Promise<void> =>
	root(eventGroupId)("delete", `/${tagId}`)

export default {
	create,
	list,
	delete: _delete,
}
