import { I18nProps } from "common/withI18n"
import Event from "models/Event"
import FAQ from "models/FAQ"
import { RSVPAnswer } from "models/RSVPAnswer"
import { RSVPAnswerForOthers } from "models/RSVPAnswerForOthers"
import RSVPQuestion, {
	RSVPQuestionGET,
	fromGETToLocal,
} from "models/RSVPQuestion"
import { perform } from "utils/api/perform"

const root = (hash: string) => perform("/for-guests", hash)

// Because reasons.
const handleIndexText = (
	answer: Omit<RSVPAnswer, "guestId">
): {
	questionId: string
} & (
	| {
			answerIndex: number
	  }
	| {
			answerText: string
	  }
) => {
	const { answerIndex, answerText, ...rest } = answer

	if (answerIndex !== undefined) {
		return { answerIndex, ...rest }
	}

	if (answerText !== undefined) {
		return { answerText, ...rest }
	}

	throw new Error("Invalid answer")
}

type EventRSVPGET = Omit<EventRSVP, "questions"> & {
	questions: RSVPQuestionGET[]
}

const get = async (
	uniqueLinkHash: string,
	t: I18nProps["t"]
): Promise<EventRSVP> => {
	const response = await root(uniqueLinkHash)<EventRSVPGET>("get", "/")

	return {
		...response,
		questions: response.questions.map((q) => fromGETToLocal(q, t)),
		answers: response.answers.map(
			(a) =>
				({
					questionId: a.questionId,
					guestId: a.guestId,
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					version: (a as any).questionVersion ?? a.version, // Because reasons.
					answerIndex: a.answerIndex,
					answerText: a.answerText,
				}) as RSVPAnswer
		),
	}
}

const send = async (
	uniqueLinkHash: string,
	answers: Omit<RSVPAnswer, "guestId">[],
	answerForOthers: RSVPAnswerForOthers[]
): Promise<void> =>
	root(uniqueLinkHash)("post", "/", {
		answers: answers.map(handleIndexText),
		answerForOthers: answerForOthers.map((a) => ({
			...a,
			answers: a.answers.map(handleIndexText),
		})),
	})

export type EventRSVP = {
	mainEventId: string
	questions: RSVPQuestion[]
	allEvents: Event[]
	guests: RSVPGuest[]
	answers: RSVPAnswer[]
	qa: FAQ[]
	emailContent?: {
		headline: string
		subheadline: string
	}
}

export type RSVPGuest = {
	id: string
	details: {
		firstName: string
		lastName: string
		isChild: boolean
	}
	groupLead: boolean
	invitedToEventIds: string[]
	rsvpYesToEventIds: string[]
	email: string
}

export default {
	get,
	send,
}
