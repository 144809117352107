import withI18n, { I18nProps } from "common/withI18n"
import segmentDown from "images/segment-down.svg"
import segmentUp from "images/segment-up.svg"
import trash from "images/trash.svg"

const QuestionHeader = ({
	t,
	isUpDisabled,
	isDownDisabled,
	hideMoveButtons = false,
	offsetTrashIcon = false,
	showDisabledTrashIcon = false,
	onMove,
	onChange,
	onDelete,
	value,
	placeholder = t("question"),
}: I18nProps & {
	value: string
	placeholder?: string
	isUpDisabled: boolean
	isDownDisabled: boolean
	hideMoveButtons?: boolean
	offsetTrashIcon?: boolean
	showDisabledTrashIcon?: boolean
	onMove: (direction: "up" | "down") => void
	onChange: (value: string) => void
	onDelete?: () => void
}) => {
	const disabledOpacity = "opacity-40"
	const handleMove = (direction: "up" | "down") => {
		if (
			(direction === "up" && isUpDisabled) ||
			(direction === "down" && isDownDisabled)
		) {
			return
		}

		onMove(direction)
	}

	return (
		<div className="flex items-center gap-4">
			<div
				className={`flex h-6 cursor-pointer gap-[1px] ${
					hideMoveButtons ? "invisible" : ""
				}`}
			>
				<img
					src={segmentUp}
					className={`${isUpDisabled ? disabledOpacity : ""}`}
					alt="Move question up"
					onClick={() => handleMove("up")}
				/>
				<img
					src={segmentDown}
					className={`${isDownDisabled ? disabledOpacity : ""}`}
					alt="Move question down"
					onClick={() => handleMove("down")}
				/>
			</div>

			<input
				type="text"
				className={`flex-1 rounded-3xl p-2 text-center font-semibold ring-0 focus:ring-0`}
				placeholder={placeholder}
				value={value}
				onChange={(e) => onChange(e.target.value)}
			/>

			<img
				src={trash}
				// Margin here so the title aligns with the answer, because the width of the up/down control is 12.
				className={`w-6 cursor-pointer ${
					offsetTrashIcon ? "mr-3 ml-3" : "ml-6"
				} ${onDelete == null && !showDisabledTrashIcon ? "invisible" : ""} ${
					showDisabledTrashIcon ? disabledOpacity : ""
				}`}
				alt="Delete question"
				onClick={onDelete}
			/>
		</div>
	)
}

export default withI18n(QuestionHeader)
