import RadioButton from "components/RadioButton"
import TH from "components/TH"
import TR from "components/TR"
import Guest from "models/Guest"
import { memo, useContext } from "react"
import MainContext from "store/context/MainContext"

const GuestFixedRow = ({
	isHovered,
	setIsHovered,
	guest,
	selection,
	setSelection,
	editSheet,
}: {
	isHovered: boolean
	setIsHovered: (isHovered: boolean) => void
	guest: Guest
	selection: Guest[]
	setSelection: (guets: Guest[]) => void
	editSheet: JSX.Element
}): JSX.Element => {
	const mainContext = useContext(MainContext)

	return (
		<TR
			key={guest.id}
			className={`cursor-pointer ${
				isHovered ? "bg-dark-100 bg-opacity-5" : ""
			}`}
			onClick={() => mainContext.setSheet(editSheet)}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			<TH>
				<RadioButton
					checked={selection.some((s) => s.id === guest.id)}
					onClick={() => {
						if (selection.some((g) => g.id === guest.id)) {
							setSelection(selection.filter((g) => g.id !== guest.id))
						} else {
							setSelection([...selection, guest])
						}
					}}
				/>
			</TH>
			<TH>
				<div
					className={`w-12  overflow-clip rounded-full ${
						guest.hasGroup ? `ring-2 ${guest.groupColor}` : ""
					}`}
				>
					{guest.pictureUrl == null ? (
						<div className="bg-gray-disabled flex h-12 w-12 items-center justify-center">
							<span className="text-lg font-semibold tracking-wider">
								{guest.sortableFields.firstName[0]}
								{guest.sortableFields.lastName[0]}
							</span>
						</div>
					) : (
						<img src={guest.pictureUrl} alt="avatar" className="h-12 w-12" />
					)}
				</div>
			</TH>
			<TH className="pl-5" padded={false}>
				<span className="w-[105px] overflow-x-scroll">
					{guest.sortableFields.firstName}
				</span>
			</TH>
			<TH className="w-full pl-5" padded={false}>
				<span className="w-[105px] overflow-x-scroll">
					{guest.sortableFields.lastName}
				</span>
			</TH>
		</TR>
	)
}

export default memo(GuestFixedRow)
