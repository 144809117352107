import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState = {
	isVisible: true,
} as HomeState

const slice = createSlice({
	name: "dashboard/pages/home",
	initialState,
	reducers: {
		setIsVisible: (state, { payload }: PayloadAction<boolean>) => {
			state.isVisible = payload
		},
		clearState: () => initialState,
	},
})

export type HomeState = {
	readonly name: "Home"
	isVisible: boolean
}

export const homeActions = {
	...slice.actions,
}

export const homeReducer = slice.reducer
