import { Disclosure } from "@headlessui/react"
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/20/solid"
import withI18n, { I18nProps } from "common/withI18n"
import H2 from "components/H2"
import Loader from "components/Loader"
import Placeholder from "components/Placeholder"
import EventRSVPQuestions from "event/EventRSVPQuestions"
import starIcon from "images/star.svg"
import RSVPAnsweredQuestion from "models/RSVPAnsweredQuestion"
import RSVPQuestion from "models/RSVPQuestion"
import { useDashSelector, useScheduleSelector } from "store"
import muteIcon from "./images/mute.svg"

const GuestRSVP = ({
	t,
	guestId,
	answeredQuestions,
	onQuestionsChange,
}: I18nProps & {
	guestId: string
	answeredQuestions: RSVPAnsweredQuestion[] | undefined
	onQuestionsChange: (questions: RSVPAnsweredQuestion[]) => void
}): JSX.Element => {
	const { statistics: stats, mainEventId } = useDashSelector()
	const { events, rsvpQuestions } = useScheduleSelector()

	const answeredEventIds = Object.keys(stats).filter(
		(e) => answeredQuestions?.some((q) => q.eventId === e)
	)

	if (answeredQuestions == null) {
		return (
			<div className="flex w-full items-center justify-center py-20">
				<Loader />
			</div>
		)
	}

	if (
		answeredQuestions.length === 0 ||
		guestId === "" ||
		answeredEventIds.length === 0
	) {
		return (
			<div className="flex w-full items-center justify-center py-20">
				<Placeholder
					title={t("guests.sheet.addEdit.rsvp.placeholder.title")}
					text={t("guests.sheet.addEdit.rsvp.placeholder.text")}
					icon={muteIcon}
				/>
			</div>
		)
	}

	return (
		<div className="mt-16 mb-12 flex flex-col gap-12 text-center">
			{/* eslint-disable-next-line sonarjs/cognitive-complexity */}
			{answeredEventIds.map((eventId) => {
				const statsQuestions = stats[eventId]?.questions
				const eventQuestions = rsvpQuestions[eventId].toSorted(
					(q1, q2) => q1.order - q2.order
				)
				const comingQuestion = eventQuestions.find(
					(q) => q.type === "COMING_YES_NO"
				)

				const isOtherAnswering =
					answeredQuestions.find((q) => q.questionId === comingQuestion?.id) ==
					null

				let questions: RSVPQuestion[] = []

				if (
					comingQuestion == null ||
					statsQuestions == null ||
					answeredQuestions == null
				) {
					questions = []
				} else {
					questions = isOtherAnswering
						? eventQuestions.filter(
								(q) => q.type !== "COMING_YES_NO" && q.type !== "PLUS_ONES"
						  )
						: eventQuestions
				}

				return (
					<Disclosure as="div" key={eventId} className="pt-6">
						{({ open }) => (
							<>
								<dt>
									<Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
										<H2 className="mx-auto flex items-center gap-4">
											<span>{events.find((e) => e.id === eventId)?.name}</span>
											<span>
												{eventId !== mainEventId ? null : (
													<img src={starIcon} />
												)}
											</span>
										</H2>
										<span className="ml-6 flex h-7 items-center">
											{open ? (
												<MinusSmallIcon
													className="h-6 w-6"
													aria-hidden="true"
												/>
											) : (
												<PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
											)}
										</span>
									</Disclosure.Button>
									<Disclosure.Panel>
										<EventRSVPQuestions
											t={t}
											questions={questions}
											isOtherAnswering={isOtherAnswering}
											disablePlusOnes={true}
											otherGuest={undefined}
											answers={answeredQuestions}
											handleAnswer={(answer) => {
												const existingAnswer = answeredQuestions.find(
													(q) => q.questionId === answer.questionId
												)

												const question = rsvpQuestions[eventId].find(
													(q) => q.id === answer.questionId
												)

												if (existingAnswer == null || question == null) {
													return
												}

												onQuestionsChange([
													...answeredQuestions.filter(
														(q) => q.questionId !== answer.questionId
													),
													{
														...existingAnswer,
														answerIndex: answer.answerIndex,
														answerText: question.allowCustomAnswer
															? answer.answerText
															: undefined,
													},
												])
											}}
										/>
									</Disclosure.Panel>
								</dt>
							</>
						)}
					</Disclosure>
				)
			})}
		</div>
	)
}

export default withI18n(GuestRSVP)
