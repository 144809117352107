import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState = {
	isVisible: true,
} as RSVPState

const slice = createSlice({
	name: "dashboard/pages/rsvp",
	initialState,
	reducers: {
		setIsVisible: (state, { payload }: PayloadAction<boolean>) => {
			state.isVisible = payload
		},
		clearState: () => initialState,
	},
})

export type RSVPState = {
	readonly name: "RSVP"
	isVisible: boolean
}

export const rsvpActions = {
	...slice.actions,
}

export const rsvpReducer = slice.reducer
