const gtag = {
	raw: (...args: unknown[]) => window.gtag(...args),

	event: (key: string, params?: { [key: string]: unknown }) => {
		params == null ? gtag.raw("event", key) : gtag.raw("event", key, params)
	},

	pageview: (params?: {
		title?: string
		location?: string
		[key: string]: unknown
	}) => {
		if (params == null) {
			gtag.event("page_view")
			return
		}

		const { title, location, ...rest } = params
		const parameters = rest

		if (title != null) {
			parameters.page_title = title
		}

		if (location != null) {
			parameters.page_location = location
		}

		gtag.event("page_view", parameters)
	},

	set: (params: { [key: string]: unknown }) => gtag.raw("set", params),

	config: (target: string, params?: { [key: string]: unknown }) => {
		params == null
			? gtag.raw("config", target)
			: gtag.raw("config", target, params)
	},
}

export default gtag
