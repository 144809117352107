import { I18nProps } from "common/withI18n"
import Capsule from "components/Capsule"
import { RSVPAnswer } from "models/RSVPAnswer"
import { RSVPAnswerForOthers } from "models/RSVPAnswerForOthers"
import RSVPQuestion from "models/RSVPQuestion"

const EventRSVPQuestions = ({
	t,
	isComing = true,
	isOtherAnswering = false,
	disablePlusOnes = false,
	otherGuest,
	questions,
	answers,
	handleAnswer,
}: {
	t: I18nProps["t"]
	isComing?: boolean
	isOtherAnswering?: boolean
	disablePlusOnes?: boolean
	otherGuest?: RSVPAnswerForOthers["guest"]
	questions: RSVPQuestion[]
	answers: Omit<RSVPAnswer, "guestId">[]
	handleAnswer: (answer: Omit<RSVPAnswer, "guestId">) => void
}) => (
	<div className="mt-12 flex flex-col gap-12">
		{questions
			.filter((q) => (isComing ? true : q.type === "COMING_YES_NO"))
			// eslint-disable-next-line sonarjs/cognitive-complexity
			.map((question, index) => (
				<div key={index}>
					<div className="flex flex-col items-center">
						<div className="font-semibold">{question.question}</div>
						{isOtherAnswering !== true ? null : (
							<span className="italic text-dark-100 text-opacity-40">
								({t("guests.event.rsvp.questionFor")}{" "}
								{otherGuest == null || otherGuest.firstName.length === 0
									? t("guests.event.rsvp.plusOnes.title.singular")
									: `${otherGuest.firstName} ${otherGuest.lastName}`}
								)
							</span>
						)}

						<div
							className={`mt-6 flex w-full flex-col gap-4 ${
								question.type === "PLUS_ONES" && disablePlusOnes
									? "opacity-50"
									: ""
							}`}
						>
							{question.answers.map((answer, index) => {
								const isSelected = answers.some(
									(a) => a.questionId == question.id && a.answerIndex == index
								)

								return (
									<Capsule
										key={index}
										className={`min-w-[150px] max-w-full flex-grow ${
											isSelected
												? "bg-dark-500 font-semibold text-white"
												: "bg-white"
										}`}
										onClick={() =>
											question.type === "PLUS_ONES" && disablePlusOnes
												? null
												: handleAnswer({
														questionId: question.id,
														version: question.version,
														answerIndex: index,
														answerText: answer,
												  })
										}
									>
										{question.type === "PLUS_ONES"
											? answer === "0"
												? "No"
												: `+${answer}`
											: answer}
									</Capsule>
								)
							})}

							{question.allowCustomAnswer ? (
								<Capsule
									className={`min-w-[150px] max-w-full flex-grow ${
										(
											answers.find(
												(a) =>
													a.questionId == question.id && a.answerIndex == null
											)?.answerText ?? ""
										).length === 0
											? "bg-white"
											: "bg-dark-500 font-semibold text-white"
									}`}
								>
									<input
										autoComplete="off"
										className="without-input-controls h-6 w-full bg-transparent text-center focus-visible:ring-0"
										placeholder={t("guests.event.rsvp.customAnswerPlaceholder")}
										value={(() => {
											return (
												answers.find(
													(a) =>
														a.questionId == question.id && a.answerIndex == null
												)?.answerText ?? ""
											)
										})()}
										type={"text"}
										onChange={(e) =>
											handleAnswer({
												questionId: question.id,
												version: question.version,
												answerText: e.target.value,
											})
										}
									/>
								</Capsule>
							) : null}
						</div>
					</div>
				</div>
			))}
	</div>
)

export default EventRSVPQuestions
