import { I18nProps } from "common/withI18n"
import H2 from "components/H2"
import HLine from "components/HLine"
import CardInfo from "dashboard/comms/CardInfo"
import QARow, { QARowData } from "dashboard/comms/QARow"
import starIcon from "images/star.svg"
import tickGreen from "images/tickGreen.svg"
import { RSVPStatisticsCustomAnswer } from "models/RSVPQuestion"
import { useNavigate } from "react-router-dom"
import R from "routes/R"

const StatisticsCard = ({
	icon,
	title,
	isMainEvent,
	answers,
	qaRowData,
	isExpanded,
	onExpand,
	onAnswerClick,
	onCustomAnswerClick,
	t,
}: {
	icon: string
	title: string
	isMainEvent: boolean
	answers: {
		yes: number
		no: number
		plusOnes: number
		total: number
		sentRSVPs: number
	}
	qaRowData: QARowData[]
	isExpanded: boolean
	onExpand: () => void
	onAnswerClick: (questionId: string, answerIndex: number) => void
	onCustomAnswerClick: (customAnswers: {
		question: string
		answers: RSVPStatisticsCustomAnswer[]
	}) => void
	t: I18nProps["t"]
}) => {
	const navigate = useNavigate()
	const yeses = (answers.yes / answers.sentRSVPs) * 100
	const nos = (answers.no / answers.sentRSVPs) * 100

	return (
		<div className="mr-10 min-h-[300px] cursor-default overflow-clip rounded-3xl bg-white py-9 px-9">
			<div className="flex justify-between">
				<div className="flex items-center gap-4 opacity-80">
					<img className="h-8 w-8" src={icon} />
					<H2 className="-mt-1 text-opacity-100">{title}</H2>
					{!isMainEvent ? null : <img src={starIcon} title="Main Event" />}
				</div>

				<div
					className="flex items-center gap-10"
					title={t("comms.summary.tooltip.sentTo")}
				>
					<div className="flex items-center gap-4">
						<span className="opacity-70">
							<span>{t("comms.summary.sent")}</span>{" "}
							<span className="font-semibold">
								{answers.sentRSVPs}{" "}
								{answers.sentRSVPs === 1
									? t("comms.summary.invitation")
									: t("comms.summary.invitations")}
							</span>
						</span>
						<img src={tickGreen} />
					</div>

					<button
						className="h-[36px] w-[162px] rounded-3xl border-1 border-dark-200 border-opacity-40 font-semibold"
						onClick={() => navigate(R.guests)}
					>
						{t("comms.summary.button.details")}
					</button>
				</div>
			</div>

			<div
				className="mt-10 flex items-center justify-between"
				title={
					answers.yes === 0
						? t("comms.summary.pieChart.tooltip.noAnswers")
						: `${answers.yes} ${
								answers.yes === 1
									? t("comms.summary.pieChart.tooltip.answers.singular")
									: t("comms.summary.pieChart.tooltip.answers.plural")
						  }`
				}
			>
				<div className="flex h-24 w-[450px] overflow-clip rounded-2xl bg-light-500">
					{/* Yes. Calculate width here, because TailwindCSS doesn't support arbitrary values */}
					<div className="h-full bg-green-200" style={{ width: `${yeses}%` }} />
					{/* No */}
					<div className="h-full bg-dark-100" style={{ width: `${nos}%` }} />
					{/* Dummy for remaining */}
					<div />
				</div>

				<CardInfo
					tooltip={t("comms.summary.tooltip.yes")}
					value={answers.total}
					semiboldedValue={false}
					label1={<span>{t("comms.summary.yes.1")}</span>}
					label2={
						<span className="font-semibold">{t("comms.summary.yes.2")}</span>
					}
				/>
				<CardInfo
					value={answers.no}
					semiboldedValue={false}
					label1={<span>{t("comms.summary.no.1")}</span>}
					label2={
						<span className="font-semibold">{t("comms.summary.no.2")}</span>
					}
				/>

				<CardInfo
					tooltip={t("comms.summary.tooltip.pending")}
					value={answers.sentRSVPs - answers.yes - answers.no}
					semiboldedValue={false}
					label1={<span>{t("comms.summary.pending.1")}</span>}
					label2={
						<span>
							<span>{t("comms.summary.pending.2.1")}</span>
							<span className="font-semibold">
								{" "}
								{t("comms.summary.pending.2.2")}
							</span>
						</span>
					}
				/>
			</div>

			<div className="-ml-9 py-9">
				<HLine />
			</div>

			{isExpanded ? (
				<div className="flex flex-col">
					{qaRowData.map((data, i) => (
						<div key={i}>
							<QARow
								t={t}
								onAnswerClick={onAnswerClick}
								onCustomAnswerClick={onCustomAnswerClick}
								{...data}
							/>
							{i === qaRowData.length - 1 ? null : (
								<div className="-ml-9 py-9">
									<HLine />
								</div>
							)}
						</div>
					))}
				</div>
			) : (
				<div className="flex justify-center">
					<button
						className="h-[36px] w-[280px] rounded-3xl border-1 border-dark-200 border-opacity-40 font-semibold"
						onClick={onExpand}
					>
						{t("comms.summary.button.viewAllAnswers")}
					</button>
				</div>
			)}
		</div>
	)
}

export default StatisticsCard
