import { useAuth0 } from "@auth0/auth0-react"
import Container from "components/Container"
import Logo from "components/Logo"
import { memo } from "react"
import { Link, useNavigate } from "react-router-dom"
import R from "routes/R"
import store, { useI18nSelector } from "store"
import { i18nActions } from "store/i18nSlice"
import dashButton from "./images/dash-button.svg"
import keyButton from "./images/key-button.svg"

const Header = () => {
	const { isLoading: isAuthLoading, isAuthenticated } = useAuth0()
	const navigate = useNavigate()
	const locale = useI18nSelector().locale

	return (
		<header className="mx-4 py-8 text-white xl:mx-44 xl:py-16">
			<Container>
				<nav className="relative z-50 flex justify-between">
					<Logo />

					<div
						className="relative flex h-11 cursor-pointer rounded-3xl border-2 border-primary px-4 py-2"
						onClick={() =>
							store.dispatch(
								i18nActions.setLocale(locale === "ro" ? "en" : "ro")
							)
						}
					>
						<div
							className={`absolute left-[6px] top-1 -z-1 h-[32px] w-[44%] transform rounded-3xl bg-white transition ${
								locale === "ro" ? "" : "translate-x-[96%] sm:translate-x-[106%]"
							}`}
						/>

						<span
							className={`${
								locale === "ro" ? "font-semibold text-dark-500" : ""
							} ml-0 transition-all sm:ml-2`}
						>
							RO
						</span>
						<span
							className={`${
								locale === "en" ? "font-semibold text-dark-500" : ""
							} ml-4 mr-0 transition-all sm:ml-10 sm:mr-2`}
						>
							EN
						</span>
					</div>

					<div className="flex items-center gap-x-8 md:gap-x-12">
						{isAuthLoading ? null : isAuthenticated ? (
							<div>
								<Link to="/dashboard/guests">
									<img src={dashButton} />
								</Link>
							</div>
						) : (
							<div className="cursor-pointer" onClick={() => navigate(R.login)}>
								<img src={keyButton} />
							</div>
						)}
					</div>
				</nav>
			</Container>
		</header>
	)
}

export default memo(Header)
