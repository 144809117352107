import { useAuth0 } from "@auth0/auth0-react"
import withI18n, { I18nProps } from "common/withI18n"
import Button from "components/Button"
import Container from "components/Container"
import H2 from "components/H2"
import Header from "home/Header"
import table from "images/home-table.png"
import chevronDown from "images/icons/chevron-down.svg"
import { memo } from "react"
import { useNavigate } from "react-router-dom"
import R from "routes/R"
import feature1 from "./images/feature1.png"
import feature3 from "./images/feature3.png"
import feature4 from "./images/feature4.png"

const Home = ({ t }: I18nProps) => {
	const { isAuthenticated } = useAuth0()
	const navigate = useNavigate()
	const navigateToMailchimp = () =>
		((window.top ?? window).location =
			"https://mailchi.mp/9aa4a94912b8/im-interested-in-eventa")

	return (
		<div className="w-full bg-dark-100">
			<div className="w-full bg-[url('images/home-header.jpg')] bg-contain bg-no-repeat">
				<Header />

				<div className="mx-8 my-36 flex flex-col items-center xl:my-40 xl:mx-auto xl:px-8">
					<h1 className="text-center text-5xl font-semibold tracking-wider text-white">
						{t("home.header.1")}
						<br />
						{t("home.header.2")}
					</h1>

					<p className="mt-10 text-center font-medium text-white">
						{t("home.subheader.1")}
						<br />
						{t("home.subheader.2")}
					</p>

					<Button
						variant="solid"
						color="primary"
						className="mt-10 h-12 w-60"
						onClick={() => {
							if (isAuthenticated) {
								navigate(R.dashboard)
							} else {
								navigateToMailchimp()
							}
						}}
					>
						{t("home.button.setup")}
					</Button>

					<img
						src={chevronDown}
						alt="Scroll down"
						className="mt-16 mb-12 pb-12"
					/>
				</div>

				<Container className="relative z-1 -mt-48">
					<div className="w-full rounded-4xl bg-white px-8 pt-12 xl:pt-24">
						<H2 className="text-center text-opacity-100" textSize="text-4xl">
							{t("home.features.title.1")}
							<br />
							{t("home.features.title.2")}
						</H2>

						<p className="mx-auto mt-8 max-w-[530px] text-center">
							{t("home.features.subtitle.1")}
							<br />
							<br />
							{t("home.features.subtitle.2")}
						</p>

						<div className="lg:mx-20 xl:mx-40 xl:max-w-4xl">
							<div className="mt-16 flex flex-wrap items-center xl:mt-36 xl:flex-nowrap xl:gap-40">
								<img
									className="mx-auto max-w-[90%] xl:mx-0 xl:w-[370px]"
									src={feature1}
								/>

								{/* Some padding to accomodate the iPhone's shadow */}
								<div className="pb-10 xl:w-1/2">
									<h3 className="text-xl font-bold text-primary">
										{t("home.features.1.title")}
									</h3>
									<p className="mt-4">
										{t("home.features.1.description.1")}
										<br />
										<br />
										{t("home.features.1.description.2")}
									</p>

									<h3 className="mt-16 max-w-[260px] text-xl font-bold text-primary">
										{t("home.features.2.title")}
									</h3>
									<p className="mt-4">{t("home.features.2.description")}</p>
								</div>
							</div>
						</div>

						<H2
							className="mt-16 text-center text-opacity-100 xl:mt-32"
							textSize="text-4xl"
						>
							{t("home.features.footer.title.1")}
							<br />
							{t("home.features.footer.title.2")}
						</H2>

						<img className="mx-auto mt-10" src={table} alt="Table mockup" />

						<p className="mt-8 text-center">
							{t("home.features.footer.subtitle.1")}
							<br className="hidden xl:block" />
							{t("home.features.footer.subtitle.2")}
						</p>

						<div className="mx-auto mt-8 flex h-12 w-full justify-center">
							<Button
								variant="outline"
								color="primary"
								onClick={() => {
									if (isAuthenticated) {
										navigate(R.dashboard)
									} else {
										navigateToMailchimp()
									}
								}}
							>
								{t("home.features.footer.button")}
							</Button>
						</div>

						<div className="xl:ml-40 xl:max-w-[1000px]">
							<div className="mt-16 flex flex-wrap items-center gap-8 xl:mt-36 xl:flex-nowrap xl:gap-24">
								<img className="xl:hidden" src={feature3} />

								{/* Some padding to accomodate the baloons' shadow */}
								<div className="pb-5 xl:w-1/2">
									<h3 className="max-w-[240px] text-xl font-bold text-primary">
										{t("home.features.3.title")}
									</h3>
									<p className="mt-4">
										{t("home.features.3.description.1")}
										<br />
										<br />
										{t("home.features.3.description.2")}
									</p>
								</div>

								<img className="hidden w-[500px] xl:block" src={feature3} />
							</div>
						</div>

						<div className="xl:ml-20 xl:max-w-[1000px]">
							<div className="flex flex-wrap items-center xl:flex-nowrap xl:gap-40">
								<img className="hidden w-[500px] xl:block" src={feature4} />

								{/* Some padding to accomodate the cake's shadow */}
								<div className="my-8 pb-4 xl:my-0 xl:w-1/2">
									<h3 className="max-w-[250px] text-xl font-bold text-primary">
										{t("home.features.4.title")}
									</h3>
									<p className="mt-4">{t("home.features.4.description")}</p>
								</div>

								<img className="xl:hidden" src={feature4} />
							</div>
						</div>
					</div>
				</Container>

				<div className="w-full bg-[url('images/home-bottom.png')] bg-cover bg-top pb-20 xl:-mt-[100px] 2xl:-mt-[120px] 2xl:pb-40">
					<div className="pt-96 text-center text-white xl:pt-[420px] 2xl:pt-[520px]">
						<h2 className="font-title text-4xl font-medium text-white text-opacity-100">
							{t("home.footer.title.1")}
							<br />
							{t("home.footer.title.2")}
						</h2>
						<p className="mx-auto mt-8 max-w-[540px]">
							{t("home.footer.subtitle")}
						</p>
						<Button
							variant="solid"
							color="primary"
							className="mt-10 mb-32 h-12 w-60"
							onClick={() => {
								if (isAuthenticated) {
									navigate(R.dashboard)
								} else {
									navigateToMailchimp()
								}
							}}
						>
							{t("home.footer.button")}
						</Button>
					</div>
				</div>

				<div className="flex h-12 items-center justify-center bg-dark-500 text-white">
					<div>© Eventa {new Date().getFullYear()}. All rights reserved.</div>
				</div>
			</div>
		</div>
	)
}

export default memo(withI18n(Home))
