const SegmentedControl = ({
	titles,
	hasShadow = false,
	scrollSheetContentToTop = true,
	selectedIndex,
	onSelect,
}: {
	titles: JSX.Element[]
	hasShadow?: boolean
	scrollSheetContentToTop?: boolean
	selectedIndex: number
	onSelect: (index: number) => void
}) => {
	return (
		<div
			className={`min-h-12 flex flex-row overflow-clip rounded-full border-1 border-dark-100 border-opacity-10 bg-light-100 text-sm transition-shadow duration-300 ease-in-out ${
				hasShadow ? "shadow-md" : ""
			}`}
		>
			{titles.map((title, index) => (
				<div
					key={index}
					className={`${
						index === selectedIndex
							? "bg-dark-100 font-bold text-white"
							: "text-dark-100"
					} ${
						index !== 0 ? "border-l-1 border-dark-100 border-opacity-10" : ""
					} flex flex-1 cursor-pointer items-center justify-center py-2 px-4`}
					onClick={() => {
						onSelect(index)

						if (!scrollSheetContentToTop) {
							return
						}

						document.getElementById("sheetContent")?.scrollTo(0, 0)
					}}
				>
					{title}
				</div>
			))}
		</div>
	)
}

export default SegmentedControl
