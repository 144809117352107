import Helmet from "common/Helmet"
import useApi from "common/useApi"
import withI18n, { I18nProps } from "common/withI18n"
import Auth0ProviderWithHistory from "components/Auth0ProviderWithHistory"
import Notification, { NotificationProps } from "components/Notification"
import { dashActions } from "dashboard/dashSlice"
import { guestsActions } from "dashboard/guests/guestsSlice"
import { scheduleActions } from "dashboard/schedule/scheduleSlice"
import { ReactNode, useEffect, useState } from "react"
import { HelmetProvider } from "react-helmet-async"
import { Provider } from "react-redux"
import { useLocation, useNavigationType } from "react-router-dom"
import Router from "routes/Router"
import store, { useDashSelector } from "store"
import MainContext from "store/context/MainContext"

const App = ({ t }: I18nProps): JSX.Element => {
	const dash = useDashSelector()
	const [path, setPath] = useState<string>(window.location.pathname ?? "")
	const navigation = useNavigationType()
	const location = useLocation()
	const apiWithToken = useApi()

	useEffect(() => {
		const fetchInitialData = async () => {
			const api = await apiWithToken()

			if (api == null) {
				return
			}

			try {
				const [event] = await api.eventGroups.list()

				if (event == null) {
					throw new Error("No event groups found.")
				}

				store.dispatch(dashActions.setMainEventGroupId(event.id))
				store.dispatch(dashActions.setMainEventId(event.mainEventId))
			} catch {
				try {
					const response = await api.eventGroups.create()

					store.dispatch(dashActions.setMainEventGroupId(response.id))
					store.dispatch(dashActions.setMainEventId(response.mainEventId))
				} catch {}
			}
		}

		fetchInitialData()
	}, [apiWithToken])

	useEffect(() => {
		const isSamePath = path === location.pathname
		setPath(location.pathname)

		if (
			typeof window === "undefined" ||
			navigation !== "PUSH" ||
			isSamePath ||
			path == ""
		) {
			return
		}

		window.scrollTo(0, 0)
	}, [navigation, location.pathname, path])

	useEffect(() => {
		const fetchDash = async () => {
			const api = await apiWithToken()

			if (api == null) {
				return
			}

			if (dash.eventGroupId == "") {
				return
			}

			store.dispatch(
				dashActions.fetchInitialState({
					eventGroupId: dash.eventGroupId,
					api,
					t,
				})
			)
		}

		fetchDash()
	}, [dash.eventGroupId, apiWithToken, t])

	useEffect(() => {
		const fetchTags = async () => {
			const api = await apiWithToken()

			if (api == null) {
				return
			}

			if (!dash.shouldReload.tags) {
				return
			}

			store.dispatch(
				dashActions.fetchTags({ eventGroupId: dash.eventGroupId, api })
			)
		}

		fetchTags()
	}, [dash.eventGroupId, dash.shouldReload.tags, apiWithToken])

	useEffect(() => {
		const fetchEvents = async () => {
			const api = await apiWithToken()

			if (api == null) {
				return
			}

			if (!dash.shouldReload.events) {
				return
			}

			store.dispatch(
				scheduleActions.fetch({ eventGroupId: dash.eventGroupId, api })
			)
		}

		fetchEvents()
	}, [dash.eventGroupId, dash.shouldReload.events, apiWithToken])

	useEffect(() => {
		const fetchGuests = async () => {
			const api = await apiWithToken()

			if (api == null) {
				return
			}

			if (!dash.shouldReload.guests) {
				return
			}

			store.dispatch(
				guestsActions.fetch({ eventGroupId: dash.eventGroupId, api })
			)
		}

		fetchGuests()
	}, [dash.eventGroupId, dash.shouldReload.guests, apiWithToken])

	return (
		<div className="h-full w-full scroll-smooth bg-white antialiased [font-feature-settings:'ss01']">
			<Helmet />
			<Router />
		</div>
	)
}

const AppWithI18n = withI18n(App)

const AppWithProviders = () => {
	const [sheet, setSheet] = useState<ReactNode | undefined>(undefined)
	const [isLoading, setIsLoading] = useState(false)
	const [notification, setNotification] = useState<NotificationProps>({
		show: false,
		title: "",
		text: "",
		type: "info",
	})
	const [notificationClose, setNotificationClose] = useState<
		ReturnType<typeof setTimeout> | undefined
	>(undefined)

	const setNotificationWithTimeout = (newNotification: NotificationProps) => {
		if (notification.show === true && (newNotification.show ?? true)) {
			setNotification({ ...notification, show: false })

			setTimeout(() => {
				setNotification({
					...newNotification,
					show: true,
				})

				clearNotificationAfterTimeout(newNotification)
			}, 300)

			return
		}

		setNotification({
			...newNotification,
			show: true,
		})

		clearNotificationAfterTimeout(newNotification)
	}

	const clearNotificationAfterTimeout = (notification: NotificationProps) => {
		clearTimeout(notificationClose)
		setNotificationClose(
			setTimeout(() => setNotification({ ...notification, show: false }), 5000)
		)
	}

	return (
		<Auth0ProviderWithHistory>
			<HelmetProvider>
				<Provider store={store}>
					<MainContext.Provider
						value={{
							setSheet,
							setNotification: setNotificationWithTimeout,
							setIsLoading,
						}}
					>
						<div className="relative">
							<AppWithI18n />
							{sheet}

							{isLoading ? (
								<div className="fixed inset-0 h-full w-full bg-dark-100 bg-opacity-10"></div>
							) : null}

							<Notification
								{...notification}
								setShow={(flag) =>
									setNotification({ ...notification, show: flag })
								}
							/>
						</div>
					</MainContext.Provider>
				</Provider>
			</HelmetProvider>
		</Auth0ProviderWithHistory>
	)
}

export default AppWithProviders
