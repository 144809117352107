import withI18n, { I18nProps } from "common/withI18n"
import NotImplemented from "components/NotImplemented"
import Header from "dashboard/Header"

const Settings = ({ t }: I18nProps) => (
	<>
		<Header title={t("settings.title")} />

		<div className="flex h-full w-full items-center justify-center pb-40">
			<NotImplemented />
		</div>
	</>
)

export default withI18n(Settings)
