import withI18n, { I18nProps } from "common/withI18n"
import Button from "components/Button"
import H2 from "components/H2"
import HTMLBackgroundStyle from "components/HTMLBackgroundStyle"
import RadioButton from "components/RadioButton"
import AddEditGuestPopup from "event/AddEditGuestPopup"
import EventRSVPQuestions from "event/EventRSVPQuestions"
import Event from "models/Event"
import { RSVPAnswer } from "models/RSVPAnswer"
import { RSVPAnswerForOthers } from "models/RSVPAnswerForOthers"
import { memo, useState } from "react"
import { Navigate, useLocation, useNavigate } from "react-router-dom"
import api from "utils/api"
import { EventRSVP as EventRSVPDto } from "utils/api/event"
import editIcon from "./images/edit.svg"

type LocalGuest = RSVPAnswerForOthers["guest"] & {
	eventIds: string[]
}

type LocalRSVPAnswer = Omit<RSVPAnswer, "guestId">

const EventRSVP = ({ t }: I18nProps): JSX.Element => {
	const navigate = useNavigate()
	const state = useLocation().state as {
		uniqueLinkHash: string
	} & EventRSVPDto
	const questionsForOthers = state.questions.filter(
		(q) => q.allowedWhenAnswerForOthersInGroup
	)
	const [guestToEdit, setGuestToEdit] = useState<LocalGuest | undefined>()
	const [guests, setGuests] = useState<LocalGuest[]>(
		state.guests
			.filter((g) => !g.groupLead)
			.map((g) => ({
				guestId: g.id,
				eventIds: g.invitedToEventIds,
				firstName: g.details.firstName,
				lastName: g.details.lastName,
				email: g.email,
				isChild: g.details.isChild,
			}))
	)
	const [isComing, setIsComing] = useState<{ [key: string]: boolean }>(
		state.guests
			.find((g) => g.groupLead)
			?.rsvpYesToEventIds.reduce(
				(acc, eventId) => ({ ...acc, [eventId]: true }),
				{}
			) ?? {}
	)
	// We use this so we keep the state of any questions already answered. Otherwise, we'd lose the state of the answers if the user selected more plus ones, answers, and then selected less plus ones. So we always keep the state, but we display part of it, based on this property.
	const [plusOnes, setPlusOnes] = useState<{ [key: string]: number }>(
		state.guests
			.filter((g) => !g.groupLead)
			?.reduce(
				(acc, guest) => ({
					...acc,
					...guest.invitedToEventIds.reduce(
						(acc2, eventId) => ({
							...acc2,
							[eventId]: (acc[eventId] ?? 0) + 1,
						}),
						{}
					),
				}),
				{} as { [key: string]: number }
			) ?? {}
	)

	// The answers for the group lead.
	const [answers, setAnswers] = useState<{ [key: string]: LocalRSVPAnswer[] }>(
		state.answers.reduce(
			(acc, answer) => {
				const guest = state.guests.find((g) => g.id == answer.guestId)

				if (guest?.groupLead !== true) {
					return acc
				}

				const question = state.questions.find((q) => q.id == answer.questionId)
				const eventId = question?.eventId

				if (eventId == null) {
					return acc
				}

				return {
					...acc,
					[eventId]: [
						...(acc[eventId] ?? []),
						{
							questionId: answer.questionId,
							answerIndex: answer.answerIndex,
							answerText: answer.answerText,
							version: answer.version,
						} as LocalRSVPAnswer,
					],
				}
			},
			{} as { [key: string]: LocalRSVPAnswer[] }
		)
	)

	// The answers for any plus ones the lead has added.
	const [answerForOthers, setAnswerForOthers] = useState<{
		[key: string]: {
			guestId: string
			answers: RSVPAnswerForOthers["answers"]
		}[]
	}>(
		state.answers.reduce(
			(acc, answer) => {
				const guest = state.guests.find((g) => g.id == answer.guestId)

				if (guest == null || guest.groupLead) {
					return acc
				}

				const question = state.questions.find((q) => q.id == answer.questionId)
				const eventId = question?.eventId

				if (eventId == null) {
					return acc
				}

				// We need to group the answers by event and guest.
				const existingForEvent = acc[eventId] ?? []
				const existingForGuest = existingForEvent.find(
					(a) => a.guestId == answer.guestId
				)

				return {
					...acc,
					[eventId]:
						// If there's no existing answer for this guest, we add a new one.
						existingForGuest == null
							? [
									...existingForEvent,
									{
										guestId: answer.guestId,
										answers: [answer],
									},
							  ]
							: // If there are answer for this guest, we merge them.
							  existingForEvent.map((a) =>
									a.guestId == answer.guestId
										? {
												...a,
												answers: [...a.answers, answer],
										  }
										: a
							  ),
				}
			},
			{} as {
				[key: string]: {
					guestId: string
					answers: RSVPAnswerForOthers["answers"]
				}[]
			}
		)
	)

	const [showSuccess, setShowSuccess] = useState<boolean>(false)

	const handleAnswer = (answer: LocalRSVPAnswer, eventId: string) => {
		const question = state.questions.find((q) => q.id == answer.questionId)

		if (question == null) {
			return
		}

		const answersForEvent = answers[eventId] ?? []

		const answerIndex = answersForEvent.findIndex(
			(a) => a.questionId == answer.questionId
		)

		if (question.type === "COMING_YES_NO") {
			setIsComing({
				...isComing,
				[eventId]: answer.answerIndex === 0,
			})
		} else if (question.type === "PLUS_ONES") {
			// The number of plus ones that was selected
			const numberOfOthers = parseInt(answer.answerText ?? "") || 0
			// How many guests we already have for this event
			const guestsForEvent = guests.filter((g) => g.eventIds.includes(eventId))
			const answersForEvent = (answerForOthers[eventId] ?? []).filter((a) =>
				guestsForEvent.some((g) => g.guestId == a.guestId)
			).length
			const plusOnesForEvent = Math.min(answersForEvent, guestsForEvent.length)
			// How many guests we need to create
			const guestsForEventToCreate = numberOfOthers - plusOnesForEvent

			// We create a set of new answers, so the UI shows the new section, but we will add a guest from the picker.
			const newAnswersForOthers: {
				guestId: string
				answers: RSVPAnswerForOthers["answers"]
			}[] =
				// If we already have enough guests, we don't need to create any new ones
				numberOfOthers <= plusOnesForEvent
					? []
					: [...Array(guestsForEventToCreate)].map(() => ({
							// Give it a random id, so the UI doesn't break.
							guestId: `-${Math.random()}`,
							answers: [] as RSVPAnswer[],
					  }))

			// Update the number of plus ones
			setPlusOnes({
				...plusOnes,
				[eventId]: numberOfOthers,
			})

			setAnswerForOthers({
				...answerForOthers,
				[eventId]: [
					...(answerForOthers[eventId] ?? []),
					...newAnswersForOthers,
				],
			})
		}

		setAnswers({
			...answers,
			[eventId]:
				answerIndex === -1
					? [...answersForEvent, answer]
					: answersForEvent[answerIndex].answerIndex === answer.answerIndex &&
					    answer.answerIndex != null
					  ? [
								...answersForEvent.slice(0, answerIndex),
								...answersForEvent.slice(answerIndex + 1),
					    ]
					  : [
								...answersForEvent.slice(0, answerIndex),
								answer,
								...answersForEvent.slice(answerIndex + 1),
					    ],
		})
	}

	const handleOtherAnswer = (
		guestIndex: number,
		answer: LocalRSVPAnswer,
		eventId: string
	) => {
		const answersForEvent = answerForOthers[eventId] ?? []
		const question = state.questions.find((q) => q.id == answer.questionId)

		// If we can't find the guest or the question, we can't handle the answer
		if (guestIndex === -1 || question == null) {
			return
		}

		const guest = {
			...answersForEvent[guestIndex],
		}

		const answerIndex = guest.answers.findIndex(
			(a) => a.questionId == answer.questionId
		)

		// Add new answer
		if (answerIndex === -1) {
			setAnswerForOthers({
				...answerForOthers,
				[eventId]: [
					...answersForEvent.slice(0, guestIndex),
					{
						...guest,
						answers: [...guest.answers, answer],
					},
					...answersForEvent.slice(guestIndex + 1),
				],
			})

			return
		}

		// If the question is optional and the user already answered, remove the answer, but only if the user selected the current answer.
		if (
			question.optional &&
			answerIndex !== -1 &&
			guest.answers[answerIndex].answerIndex === answer.answerIndex &&
			answer.answerIndex != null
		) {
			setAnswerForOthers({
				...answerForOthers,
				[eventId]: [
					...answersForEvent.slice(0, guestIndex),
					{
						...guest,
						answers: [
							...guest.answers.slice(0, answerIndex),
							...guest.answers.slice(answerIndex + 1),
						],
					},
					...answersForEvent.slice(guestIndex + 1),
				],
			})

			return
		}

		// Edit existing answer
		setAnswerForOthers({
			...answerForOthers,
			[eventId]: [
				...answersForEvent.slice(0, guestIndex),
				{
					...guest,
					answers:
						answerIndex === -1
							? [...guest.answers, answer]
							: [
									...guest.answers.slice(0, answerIndex),
									answer,
									...guest.answers.slice(answerIndex + 1),
							  ],
				},
				...answersForEvent.slice(guestIndex + 1),
			],
		})
	}

	const saveAnswers = async () => {
		const answerForOthersDto = Object.entries(answerForOthers)
			.reduce((acc, [eventId, answers]) => {
				const plusOnesForEvent = plusOnes[eventId] ?? 0
				const guestsForEvent = guests.filter((g) =>
					g.eventIds.includes(eventId)
				)

				return [
					...acc,
					...answers.slice(0, plusOnesForEvent).map((a) => {
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						const guest = guestsForEvent.find((g) => g.guestId === a.guestId)!
						const numberGuestId = parseFloat(guest.guestId ?? "")
						const guestDto: RSVPAnswerForOthers["guest"] = {
							firstName: guest.firstName,
							lastName: guest.lastName,
							email: guest.email,
							isChild: guest.isChild,
						}

						// Here we check if we created the id locally (to easily handle guests); if not, add it to the DTO.
						if (isNaN(numberGuestId) || numberGuestId > 0) {
							guestDto.guestId = guest.guestId
						}

						return {
							guest: guestDto,
							eventIds: guest.eventIds,
							answers: a.answers,
						}
					}),
				]
			}, [] as RSVPAnswerForOthers[])
			.filter((a) => a.guest.firstName !== "" || a.guest.lastName !== "")

		// Merge all entries that have the same `guest.guestId` (we can have multiple events for the same guest, stored in `eventIds`).
		const mergedAnswerForOthersDto = answerForOthersDto.reduce((acc, a) => {
			const existing = acc.find((e) => e.guest.guestId === a.guest.guestId)

			if (existing == null) {
				return [...acc, a]
			}

			return [
				...acc.filter((e) => e.guest.guestId !== a.guest.guestId),
				{
					...existing,
					eventIds: [...new Set([...existing.eventIds, ...a.eventIds])], // Merged uniquly
					answers: [...existing.answers, ...a.answers],
				},
			]
		}, [] as RSVPAnswerForOthers[])

		try {
			await api.event.send(
				state.uniqueLinkHash,
				Object.values(answers).flat(),
				mergedAnswerForOthersDto
			)

			setShowSuccess(true)
		} catch {}
	}

	const saveAnswersFromPickerForEventId = (
		guest: LocalGuest,
		eventId: string
	) => {
		const otherAnswersForThisEvent = answerForOthers[eventId] ?? []
		const answerIndex = otherAnswersForThisEvent.findIndex(
			(g) => g.guestId === guest.guestId
		)

		// This shouldn't happen, because we create answers when handling `PLUS_ONES` answers, but just in case.
		if (answerIndex === -1) {
			setAnswerForOthers({
				...answerForOthers,
				[eventId]: [
					...otherAnswersForThisEvent,
					{
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						guestId: guest.guestId!,
						answers: [],
					},
				],
			})
		} else {
			setAnswerForOthers({
				...answerForOthers,
				[eventId]: [
					...otherAnswersForThisEvent.slice(0, answerIndex),
					{
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						guestId: guest.guestId!,
						answers: otherAnswersForThisEvent[answerIndex]?.answers ?? [],
					},
					...otherAnswersForThisEvent.slice(answerIndex + 1),
				],
			})
		}
	}

	const saveGuestFromPicker = (guest: LocalGuest, eventIds: string[]) => {
		if (guest.firstName === "" || guest.lastName === "") {
			return
		}

		eventIds.forEach((e) => saveAnswersFromPickerForEventId(guest, e))

		const guestIndex = guests.findIndex((g) => g.guestId === guest.guestId)

		if (guestIndex === -1) {
			setGuests([...guests, guest])
		} else {
			setGuests([
				...guests.slice(0, guestIndex),
				guest,
				...guests.slice(guestIndex + 1),
			])
		}

		setGuestToEdit(undefined)
	}

	const handleGuestSelect = (
		eventId: string,
		guestId: string,
		// The guest that is currently selected.
		currentlySelectedGuest: LocalGuest | undefined,
		otherAnswersForThisEvent: {
			guestId: string
			answers: RSVPAnswerForOthers["answers"]
		}[],
		answersIndex: number
	) => {
		// The guest corresponding to the new selection.
		const selectedGuest = guests.find((g) => g.guestId === guestId)
		const answers = otherAnswersForThisEvent[answersIndex]

		// This should never happen, since we create these when we handle `PLUS_ONE` answers.
		if (answers == null) {
			return
		}

		if (selectedGuest == null) {
			// If the user selected the "Create new guest" option, we open the modal to create a new guest.
			setGuestToEdit({
				firstName: "",
				lastName: "",
				eventIds: [eventId],
				guestId: answers.guestId, // Reuse this `guestId`, so we can reuse questions already answered, before the guest was created.
			})

			return
		}

		// Otherwise we assign the selected guest to the answers.
		setAnswerForOthers({
			...answerForOthers,
			[eventId]: [
				...otherAnswersForThisEvent.slice(0, answersIndex),
				{
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					guestId: selectedGuest.guestId!,
					answers: answers.answers,
				},
				...otherAnswersForThisEvent.slice(answersIndex + 1),
			],
		})
	}

	if (state == null) {
		return <Navigate to="/" />
	}

	const successContent = () => (
		<div className="mt-10 flex flex-col items-center justify-center">
			<div className="text-center text-3xl font-bold text-dark-100">
				{t("guests.event.rsvp.success.title")}
			</div>
			<div className="text-center text-xl italic text-dark-100 text-opacity-50">
				{t("guests.event.rsvp.success.subtitle")}
			</div>
			<Button
				variant="outline"
				className="mx-auto mt-12 w-64"
				onClick={() => navigate(-1)}
			>
				{t("guests.event.rsvp.button.backToHome")}
			</Button>
		</div>
	)

	const guestPicker = (
		eventId: string,
		guest: LocalGuest | undefined,
		guestIndex: number,
		otherAnswersForThisEvent: {
			guestId: string
			answers: RSVPAnswerForOthers["answers"]
		}[],
		answersIndex: number
	) => {
		// We use these so we have two entries in the dropdown to manipulate data.
		const dummySelectorGuests = [
			{
				firstName: "",
				lastName: "",
				guestId: "-2",
			} as LocalGuest,
			{
				firstName: t("guests.event.rsvp.guestPicker.createNew"),
				lastName: "",
				guestId: "-1",
			} as LocalGuest,
		]

		return (
			<div className="flex w-full flex-col">
				<div className="mt-2 flex items-center gap-4">
					<img
						className={`h-8 w-8 cursor-pointer ${
							guest == null ? "hidden" : ""
						}`}
						title={t("guests.event.rsvp.guestPicker.edit")}
						src={editIcon}
						alt={"Edit"}
						onClick={() => setGuestToEdit(guest)}
					/>
					<select
						name="guest"
						className="w-full text-center"
						value={guest?.guestId ?? ""}
						onChange={(e) =>
							handleGuestSelect(
								eventId,
								e.target.value,
								guest,
								otherAnswersForThisEvent,
								answersIndex
							)
						}
					>
						{dummySelectorGuests
							.concat(
								guests.filter(
									(g) =>
										otherAnswersForThisEvent
											.filter((_, i) => i !== answersIndex)
											.find((a) => a.guestId === g.guestId) == null
								)
							)
							.map((g) => (
								<option value={g.guestId} key={g.guestId}>
									{g.firstName} {g.lastName}
								</option>
							))}
					</select>
				</div>

				<div className="mt-4 flex w-full items-center justify-between px-4">
					<p className="italic text-dark-100 text-opacity-30">
						{t("guests.event.rsvp.guestPicker.info")}
					</p>

					<div className="flex items-center">
						<div
							className="flex cursor-pointer gap-3"
							onClick={() => {
								if (guest == null) {
									return
								}

								setGuests([
									...guests.slice(0, guestIndex),
									{
										...guest,
										isChild: !guest.isChild,
									},
									...guests.slice(guestIndex + 1),
								])
							}}
						>
							<RadioButton checked={guest?.isChild === true} />
							{t("guests.event.rsvp.guest.child")}
						</div>
					</div>
				</div>
			</div>
		)
	}

	const content = (event: Event, isFirst: boolean, isLast: boolean) => {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const eventId = event.id!
		// The questions for this event, sorted.
		const questions = [...state.questions]
			.filter((q) => q.eventId === eventId)
			.sort((a, b) => a.order - b.order)
		const plusOnesForThisEvent = plusOnes[eventId] ?? 0
		const answersForEvent = answers[eventId] ?? []
		// We only display as many answers as there are `plusOnes`. This is to preserve the state, in case the user changes the number of `plusOnes`.
		const otherAnswersForThisEvent =
			answerForOthers[eventId]?.slice(0, plusOnesForThisEvent) ?? []

		return (
			<div key={event.id}>
				<div
					className={`mx-auto ${isFirst ? "" : "mt-6"} ${
						isLast ? "" : "border-b-1 border-light-300 pb-12"
					} flex w-full max-w-xl flex-col`}
				>
					<div className="mt-24 text-center">
						<H2 className="text-3xl">{event.name}</H2>
						<span className="mt-1 block italic text-dark-100 text-opacity-30">
							{event.description}
						</span>
					</div>

					<EventRSVPQuestions
						t={t}
						isComing={isComing[eventId]}
						questions={questions}
						answers={answersForEvent}
						handleAnswer={(answer) => handleAnswer(answer, eventId)}
					/>

					{otherAnswersForThisEvent.length === 0 ||
					!isComing[eventId] ? null : (
						<div className="mt-24">
							<div className="text-center">
								<H2>{t("guests.event.rsvp.plusOnes.title")}</H2>
								<span className="mt-8 italic text-dark-100 text-opacity-30">
									{t("guests.event.rsvp.plusOnes.subtitle")}
								</span>
							</div>

							{otherAnswersForThisEvent.map((a, i) => {
								const thisGuestIndex = guests.findIndex(
									(g) => g.guestId === a.guestId
								)
								const thisGuest = guests[thisGuestIndex]

								return (
									<div
										key={`${a.guestId}-${i}`}
										className={`mt-10 ${
											i < otherAnswersForThisEvent.length - 1
												? "border-b-1 border-light-300 pb-12"
												: ""
										}`}
									>
										<div className="flex gap-3">
											{guestPicker(
												eventId,
												thisGuest,
												thisGuestIndex,
												otherAnswersForThisEvent,
												i
											)}
										</div>

										<EventRSVPQuestions
											t={t}
											questions={questionsForOthers.filter(
												(q) => q.eventId === eventId
											)}
											isOtherAnswering={true}
											otherGuest={thisGuest}
											answers={a.answers}
											handleAnswer={(answer) =>
												handleOtherAnswer(i, answer, eventId)
											}
										/>
									</div>
								)
							})}
						</div>
					)}
				</div>
			</div>
		)
	}

	return (
		<div className="h-full w-full bg-light-200 pb-10">
			{guestToEdit == null ? null : (
				<AddEditGuestPopup
					t={t}
					guest={guestToEdit}
					title={
						guestToEdit == null
							? t("guests.sheet.addPlusOne.title")
							: t("guests.sheet.edit.title")
					}
					buttonTitle="Save"
					onClick={(guest) => saveGuestFromPicker(guest, guest.eventIds)}
					onClose={() => setGuestToEdit(undefined)}
					open={guestToEdit != null}
				/>
			)}

			<HTMLBackgroundStyle color="rgb(227 234 238 / 1)" />

			<div className="mx-auto flex w-full max-w-3xl items-center justify-center overflow-clip rounded-b-2xl">
				<img
					className="w-full object-cover object-top"
					src={require("./images/rsvpHeader.jpg")}
				/>
			</div>

			<div className="mx-auto flex max-w-xl flex-col">
				{showSuccess ? (
					successContent()
				) : (
					<>
						{state.allEvents
							// Only display the events where the user has answered they're coming, or not answered yet.
							.slice(
								0,
								// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
								isComing[state.allEvents[0].id!] === false ? 1 : undefined
							)
							// Only display the ones that have questions (shouldn't really happen, but just in case).
							.filter((e) => state.questions.some((q) => q.eventId === e.id))
							.map((e, i, all) => content(e, i === 0, i === all.length - 1))}

						<Button className="mx-auto mt-12 w-64" onClick={saveAnswers}>
							{state.answers.length === 0
								? t("guests.event.rsvp.button.save")
								: t("guests.event.rsvp.button.update")}
						</Button>
					</>
				)}
			</div>
		</div>
	)
}

export default memo(withI18n(EventRSVP))
