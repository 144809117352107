import withI18n, { I18nProps } from "common/withI18n"
import SortableTH, { SortDirection } from "components/SortableTH"
import TH from "components/TH"
import TR from "components/TR"
import Header from "dashboard/Header"
import { fixedSideContentWidth } from "dashboard/Layout"
import FAQSheet from "dashboard/pages/FAQSheet"
import { faqActions } from "dashboard/pages/faqSlice"
import Switch from "dashboard/Switch"
import { useContext, useState, memo } from "react"
import store, { usePagesSelector } from "store"
import MainContext from "store/context/MainContext"
import faqIcon from "./images/faqIcon.svg"
import homeIcon from "./images/homeIcon.svg"

type SortableField = "pageName" | "updatedAt" | "isVisible"

type Sort = {
	field: SortableField
	direction: SortDirection
} | null

const Pages = ({ t }: I18nProps) => {
	const pagesState = usePagesSelector()
	const mainContext = useContext(MainContext)
	const [sort, setSort] = useState<Sort>(null)
	const [pages, setPages] = useState([
		{
			id: 1,
			pageName: t("pages.home"),
			updatedAt: "2022-10-27",
			icon: homeIcon,
			isVisible: pagesState.home.isVisible,
		},
		{
			id: 2,
			pageName: t("pages.faq"),
			updatedAt: "2022-10-27",
			icon: faqIcon,
			isVisible: pagesState.faq.isVisible,
		},
	])

	const handleRowClick = (id: number) => {
		if (id === 2) {
			mainContext.setSheet(<FAQSheet />)
		}
	}

	const handleSort = (field: SortableField) => {
		if (sort?.field === field) {
			setSort({
				field,
				direction: sort.direction === "asc" ? "desc" : "asc",
			})
		} else {
			setSort({
				field,
				direction: "asc",
			})
		}
	}

	return (
		<>
			<Header
				title={t("pages")}
				subtitle={{ text: t("pages.subtitle"), restrictWidth: true }}
			/>

			<div className="overflow-y-scroll">
				<div className="flex pb-24">
					<table className="w-full">
						<thead>
							<TR>
								<th scope="col" className="h-12 w-12" />
								<SortableTH
									className={`w-[${fixedSideContentWidth - 40}px] shrink-0`}
									label={t("pages.column.pageName")}
									direction={
										sort?.field === "pageName" ? sort?.direction : undefined
									}
									onClick={() => handleSort("pageName")}
								/>
								<SortableTH
									className="w-52 shrink-0"
									label={t("pages.column.updatedAt")}
									direction={
										sort?.field === "updatedAt" ? sort?.direction : undefined
									}
									onClick={() => handleSort("updatedAt")}
								/>
								<SortableTH
									label={t("pages.column.isVisible")}
									direction={
										sort?.field === "isVisible" ? sort?.direction : undefined
									}
									onClick={() => handleSort("isVisible")}
								/>
							</TR>
						</thead>
						<tbody>
							{pages.map((page) => (
								<TR
									className={`${
										page.id === 1
											? ""
											: "cursor-pointer hover:bg-dark-100 hover:bg-opacity-5"
									}`}
									key={page.id}
									onClick={() => handleRowClick(page.id)}
								>
									<TH padded={false}>
										<img src={page.icon} alt="" className="h-12 w-12" />
									</TH>
									<TH className="font-semibold">{page.pageName}</TH>
									<td>{page.updatedAt}</td>
									<td>
										<div className="flex gap-4">
											<Switch
												checked={page.isVisible}
												className={`${page.id === 1 ? "opacity-0" : ""}`}
												onChange={() => {
													if (page.id === 2) {
														store.dispatch(
															faqActions.setIsVisible(!page.isVisible)
														)

														setPages((pages) => {
															return pages.map((page) => {
																if (page.id === 2) {
																	return {
																		...page,
																		isVisible: !page.isVisible,
																	}
																}
																return page
															})
														})
													}
												}}
											/>
											<div
												className={`flex h-8 w-24 items-center justify-center rounded-2xl ${
													page.isVisible
														? "bg-green-100 bg-opacity-40 font-semibold text-green-300"
														: "bg-dark-500 bg-opacity-10 text-dark-500 text-opacity-60"
												}`}
											>
												{page.isVisible ? "Visible" : "Hidden"}
											</div>
										</div>
									</td>
								</TR>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</>
	)
}

export default memo(withI18n(Pages))
