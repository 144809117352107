import { useAuth0 } from "@auth0/auth0-react"
import withI18n, { I18nProps } from "common/withI18n"
import Button from "components/Button"
import Logo from "components/Logo"
import VLabeledInput from "components/VLabeledInput"
import { useEffect, useState } from "react"
import { Navigate, useLocation } from "react-router-dom"
import api from "utils/api"

const SetupAccount = ({ t }: I18nProps): JSX.Element => {
	const location = useLocation()
	const { loginWithRedirect } = useAuth0()
	const params = new URLSearchParams(location.search)
	const onboardingHash = params.get("onboardingLinkHash")?.replaceAll(" ", "+")

	const [password, setPassword] = useState("")
	const [confirmPassword, setConfirmPassword] = useState("")
	const [email, setEmail] = useState("roland@leth.ro")

	useEffect(() => {
		const fetch = async () => {
			if (onboardingHash == null || onboardingHash === "") {
				return
			}

			try {
				const response = await api.users.decodeOnboardingHash(onboardingHash)

				if (response.email == null || response.email === "") {
					throw new Error("Invalid response")
				}

				setEmail(response.email)
			} catch {
				setEmail("$$invalid")
			}
		}

		fetch()
	}, [onboardingHash])

	if (
		onboardingHash == null ||
		onboardingHash === "" ||
		email === "$$invalid"
	) {
		return <Navigate to="/" />
	}

	return (
		<div className="fixed flex h-full w-full bg-[url('images/bg-login.jpg')] bg-cover bg-center bg-no-repeat">
			<div className="flex w-full items-center justify-center">
				<div className="flex w-[360px] flex-col items-center justify-center gap-6">
					<div className="pb-10">
						<Logo />
					</div>

					<h2 className="mb-10 text-center font-title text-4xl font-medium text-white">
						{t("setupAccount.title")}
					</h2>

					<VLabeledInput
						label={t("setupAccount.email")}
						labelColor="text-white"
						textColor="text-white"
						value={email}
						disabled
						// eslint-disable-next-line @typescript-eslint/no-empty-function
						onChange={() => {}}
					/>

					<VLabeledInput
						label={t("setupAccount.password")}
						labelColor="text-white"
						type="password"
						value={password}
						onChange={(n) => setPassword(n)}
					/>
					<VLabeledInput
						label={t("setupAccount.confirmPassword")}
						labelColor="text-white"
						type="password"
						value={confirmPassword}
						onChange={(n) => setConfirmPassword(n)}
					/>
					<Button
						className="min-w-[160px] font-bold"
						onClick={async () => {
							if (password !== confirmPassword || password === "") {
								return
							}

							try {
								await api.users.setupAccount(onboardingHash, password)
								loginWithRedirect()
							} catch {}
						}}
					>
						{t("setupAccount.button")}
					</Button>
				</div>
			</div>
		</div>
	)
}

export default withI18n(SetupAccount)
