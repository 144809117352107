import withI18n, { I18nProps } from "common/withI18n"
import H2 from "components/H2"
import QuestionHeader from "components/QuestionHeader"
import RadioButton from "components/RadioButton"
import addIcon from "images/add.svg"
import RSVPQuestion, {
	emptyQuestion,
	friendlyQuestionType,
	isPlusOnes,
	isRequired,
	RSVPQuestionType,
	RSVPQuestionTypes,
} from "models/RSVPQuestion"
import { memo } from "react"
import { useScheduleSelector } from "store"
import plus from "./images/plus.svg"
import trashLight from "./images/trash-light.svg"

const RSVPSection = ({
	t,
	isMainEvent,
	questions,
	onQuestionsUpdate,
}: I18nProps & {
	isMainEvent: boolean
	questions: RSVPQuestion[]
	onQuestionsUpdate: (questions: RSVPQuestion[]) => void
}) => {
	const events = useScheduleSelector().events
	const dummyValue = 0
	const handleFieldUpdate = <K extends keyof RSVPQuestion>(
		field: K,
		value: RSVPQuestion[K],
		index: number
	) => {
		const q = questions[index]

		onQuestionsUpdate([
			...questions.slice(0, index),
			{ ...q, [field]: value },
			...questions.slice(index + 1),
		])
	}

	const move = (direction: "up" | "down", index: number) => {
		if (direction === "up") {
			onQuestionsUpdate([
				...questions.slice(0, index - 1),
				{ ...questions[index - 1], order: questions[index].order },
				{ ...questions[index], order: questions[index - 1].order },
				...questions.slice(index + 1),
			])
		} else {
			onQuestionsUpdate([
				...questions.slice(0, index),
				{ ...questions[index], order: questions[index + 1].order },
				{ ...questions[index + 1], order: questions[index].order },
				...questions.slice(index + 2),
			])
		}
	}

	const deleteQuestion = (index: number) => {
		onQuestionsUpdate([
			...questions.slice(0, index),
			...questions.slice(index + 1),
		])
	}

	// We always keep generic, because if we have, only non-generic questions left, we still want to show the dropdown, which also contains the generic question type.
	// And when we have only the generic left, we want to not show the dropdown anymore, since there's no _real_ question type left.
	const unusedTypes = RSVPQuestionTypes.filter(
		(type) => !questions.some((q) => q.type === type) || type === "GENERIC"
	)

	return (
		<div className="mt-9 mb-20 w-full text-center">
			<H2>{t("schedule.sheet.addEdit.rsvp.title")}</H2>

			<div className="mt-10 flex w-full flex-col gap-10">
				{/* eslint-disable-next-line sonarjs/cognitive-complexity */}
				{questions.map((q, i) => (
					<div key={q.id} className="flex flex-col gap-4">
						<QuestionHeader
							value={q.question}
							placeholder={`Question ${i + 1}`}
							isUpDisabled={
								i < 2 || (i === 2 && questions[1].type === "PLUS_ONES")
							}
							isDownDisabled={
								i < 1 ||
								questions[i].type === "PLUS_ONES" ||
								q.order === Math.max(...questions.map((q) => q.order))
							}
							offsetTrashIcon
							showDisabledTrashIcon={
								i < 1 || (i === 1 && (isMainEvent || events.length === 0))
							}
							onMove={(direction) => move(direction, i)}
							onChange={(value) => handleFieldUpdate("question", value, i)}
							onDelete={
								// Allow deletion of plus ones question only if it's not the main event.
								i > 1 || (i === 1 && !isMainEvent && events.length > 0)
									? () => deleteQuestion(i)
									: undefined
							}
						/>

						{q.answers.map((a, j) => {
							const isReadOnly =
								isPlusOnes(q.type) || q.type === "COMING_YES_NO"

							return (
								<div key={`${q.id}-${j}`} className="relative flex gap-3">
									<input
										autoComplete="off"
										className={`without-input-controls w-full text-center ${
											isReadOnly ? "cursor-default focus-visible:ring-0" : ""
										}`}
										value={a}
										readOnly={isReadOnly}
										placeholder={
											q.optional && j === 0
												? t("schedule.sheet.addEdit.rsvp.defaultAnswer")
												: `${t(
														"schedule.sheet.addEdit.rsvp.placeholder.answer"
												  )} ${j + 1}${isPlusOnes(q.type) ? " (number)" : ""}`
										}
										onChange={(e) =>
											handleFieldUpdate(
												"answers",
												[
													...q.answers.slice(0, j),
													e.target.value,
													...q.answers.slice(j + 1),
												],
												i
											)
										}
									/>

									{q.answers.length === 1 ||
									(q.type === "PLUS_ONES" && j < 2) ||
									(q.optional && q.answers.length === 1) ||
									q.type === "COMING_YES_NO" ? null : (
										<img
											src={trashLight}
											className="absolute right-3 top-3 w-6 cursor-pointer"
											alt={t("schedule.sheet.addEdit.rsvp.alt.deleteAnswer")}
											onClick={() =>
												handleFieldUpdate(
													"answers",
													q.answers.filter((_, k) => k !== j),
													i
												)
											}
										/>
									)}
								</div>
							)
						})}

						{q.allowCustomAnswer ? (
							<input
								className="without-input-controls w-full cursor-default text-center focus-visible:ring-0"
								autoComplete="off"
								readOnly={true}
								placeholder={t(
									"schedule.sheet.addEdit.rsvp.placeholder.customAnswer"
								)}
								list={isPlusOnes(q.type) ? "PLUS_ONES" : q.type}
								onChange={(e) =>
									handleFieldUpdate(
										"answers",
										[e.target.value, ...q.answers.slice(1)],
										i
									)
								}
							/>
						) : null}

						<div
							className={`flex items-start justify-between ${
								q.type === "COMING_YES_NO" ? "hidden" : ""
							}`}
						>
							<div
								className="flex cursor-pointer items-center gap-4 opacity-40"
								onClick={() =>
									isPlusOnes(q.type)
										? handleFieldUpdate(
												"answers",
												[
													...q.answers,
													`${
														(parseInt(q.answers[q.answers.length - 1]) || 0) + 1
													}`,
												],
												i
										  )
										: handleFieldUpdate("answers", [...q.answers, ""], i)
								}
							>
								<img
									src={plus}
									alt={t("schedule.sheet.addEdit.rsvp.alt.addAnswer")}
									className="w-6"
								/>
								<span className="text-sm">
									{t("schedule.sheet.addEdit.rsvp.addAnswer")}
								</span>
							</div>

							{isRequired(q.type) ? null : (
								<div className="flex flex-col items-end gap-4">
									<div className="flex gap-4">
										<span className="opacity-40">
											{t("schedule.sheet.addEdit.rsvp.optionalQuestion")}
										</span>
										<RadioButton
											checked={q.optional}
											onClick={() => {
												onQuestionsUpdate([
													...questions.slice(0, i),
													{
														...q,
														optional: !q.optional,
													},
													...questions.slice(i + 1),
												])
											}}
										/>
									</div>
									<div className="flex gap-4">
										<span className="opacity-40">
											{t("schedule.sheet.addEdit.rsvp.allowCustomAnswer")}
										</span>
										<RadioButton
											checked={q.allowCustomAnswer}
											onClick={() => {
												onQuestionsUpdate([
													...questions.slice(0, i),
													{
														...q,
														allowCustomAnswer: !q.allowCustomAnswer,
													},
													...questions.slice(i + 1),
												])
											}}
										/>
									</div>
								</div>
							)}
						</div>
					</div>
				))}
				<div className="relative flex w-full justify-center">
					<div
						className="mt-4 flex cursor-pointer flex-col items-center gap-2"
						onClick={() =>
							unusedTypes.length > 1
								? undefined
								: onQuestionsUpdate([
										...questions,
										emptyQuestion(
											questions.length === 0
												? 0
												: Math.max(...questions.map((q) => q.order)) + 1,
											unusedTypes[0],
											t
										),
								  ])
						}
					>
						<img
							className="w-7"
							src={addIcon}
							alt={t("schedule.sheet.addEdit.rsvp.alt.addQuestion")}
						/>
						<span className="text-xs opacity-60">
							{t("schedule.sheet.addEdit.rsvp.addQuestion")}
						</span>
					</div>

					{unusedTypes.length <= 1 ? null : (
						<select
							className="absolute top-[17px] w-10 cursor-pointer opacity-0"
							value={dummyValue}
							onChange={(e) => {
								const type = e.target.value as RSVPQuestionType

								// If plus ones was deleted, if the user adds it back, insert it at index 1
								if (type === "PLUS_ONES") {
									onQuestionsUpdate([
										...questions.slice(0, 1),
										emptyQuestion(1, type, t),
										...questions.slice(1),
									])

									return
								}

								onQuestionsUpdate([
									...questions,
									emptyQuestion(
										questions.length === 0
											? 0
											: Math.max(...questions.map((q) => q.order)) + 1,
										type,
										t
									),
								])
							}}
						>
							<option disabled value={0}>
								{t("schedule.sheet.addEdit.rsvp.select.answerType")}
							</option>
							{unusedTypes.map((type) => (
								<option key={type} value={type}>
									{friendlyQuestionType(type, t)}
								</option>
							))}
						</select>
					)}
				</div>
			</div>
		</div>
	)
}

export default memo(withI18n(RSVPSection))
