import useApi from "common/useApi"
import withI18n, { I18nProps } from "common/withI18n"
import QuestionHeader from "components/QuestionHeader"
import Sheet from "components/Sheet"
import { faqActions } from "dashboard/pages/faqSlice"
import addIcon from "images/add.svg"
import { memo, useContext, useState } from "react"
import store, { useDashSelector, useFAQSelector } from "store"
import MainContext from "store/context/MainContext"

const FAQSheet = ({ t }: I18nProps) => {
	const eventGroupId = useDashSelector().eventGroupId
	const faq = useFAQSelector()
	const [questions, setQuestions] = useState(
		faq.questions.length > 0
			? faq.questions
			: [{ question: "", answer: "", order: 0 }]
	)

	const mainContext = useContext(MainContext)
	const apiWithToken = useApi()

	const handleSave = async () => {
		const api = await apiWithToken()

		if (api == null) {
			return
		}

		questions
			.filter((q) => ![q.question, q.answer].includes(""))
			.map((q, i) => ({
				question: q.question,
				answer: q.answer,
				order: i,
			}))

		try {
			await api.pages.faq.update(eventGroupId, questions)

			store.dispatch(faqActions.setQuestions(questions))
			mainContext.setSheet(null)
			mainContext.setNotification({
				type: "success",
				title: t("notification.saveSuccess"),
			})
		} catch (e) {
			console.log(e)
		}
	}

	const move = (direction: "up" | "down", index: number) => {
		const newQuestions = [...questions]
		const temp = newQuestions[index]
		const newIndex = direction === "up" ? index - 1 : index + 1

		newQuestions[index] = newQuestions[newIndex]
		newQuestions[newIndex] = temp

		setQuestions(newQuestions)
	}

	const deleteQuestion = (index: number) => {
		const newQuestions = [
			...questions.slice(0, index),
			...questions.slice(index + 1),
		]

		setQuestions(newQuestions)
	}

	return (
		<Sheet
			title={t("pages.sheet.faq.title")}
			subtitle={
				<p className="px-24 text-center text-dark-100 text-opacity-60">
					{t("pages.sheet.faq.subtitle")}
				</p>
			}
			bottomButton={{
				title: t("pages.sheet.faq.button.save"),
				action: handleSave,
			}}
		>
			<div className="mt-16 flex flex-col gap-8">
				{questions.map((q, i) => (
					<div key={i} className="flex flex-col gap-4">
						<QuestionHeader
							value={q.question}
							isUpDisabled={i === 0}
							isDownDisabled={i === questions.length - 1}
							onMove={(direction) => move(direction, i)}
							onChange={(e) => {
								setQuestions([
									...questions.slice(0, i),
									{ ...q, question: e },
									...questions.slice(i + 1),
								])
							}}
							onDelete={() => deleteQuestion(i)}
						/>
						<textarea
							className="min-h-[100px] rounded-2xl border-transparent p-2 text-center"
							placeholder={t("pages.sheet.faq.answer.placeholder")}
							value={q.answer}
							onChange={(e) => {
								setQuestions([
									...questions.slice(0, i),
									{ ...q, answer: e.target.value },
									...questions.slice(i + 1),
								])
							}}
						/>
					</div>
				))}
			</div>

			<div className="mb-20 flex w-full justify-center">
				<button
					onClick={() =>
						setQuestions([
							...questions,
							{
								question: "",
								answer: "",
								order: questions.length,
							},
						])
					}
				>
					<div className="mt-10 flex flex-col items-center gap-2">
						<img className="w-7" src={addIcon} alt="Add new question" />
						<span className="text-xs opacity-60">
							{t("pages.sheet.faq.add")}
						</span>
					</div>
				</button>
			</div>
		</Sheet>
	)
}

export default memo(withI18n(FAQSheet))
