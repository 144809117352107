import AccountTier from "models/AccountTier"
import EventGroup from "models/EventGroup"
import { RSVPStatistics } from "models/RSVPQuestion"
import { perform } from "utils/api/perform"

const root = perform(`/event_groups`)

const list = (): Promise<EventGroup[]> => root("get", "/")

const get = (id: string): Promise<EventGroup> => root("get", `/${id}`)

const questionStatistics = (id: string): Promise<RSVPStatistics> =>
	root("get", `/${id}/questions/statistics`)

const tier = (id: string): Promise<AccountTier> => root("get", `/${id}/tier`)

const create = (): Promise<EventGroup> => root("post", `/`)

const _delete = (id: string): Promise<void> => root("delete", `/${id}`)

const downloadAccountData = (id: string): Promise<unknown> =>
	root("get", `/${id}/download-account-data`)

export default {
	create,
	list,
	get,
	delete: _delete,
	questionStatistics,
	downloadAccountData,
	tier,
}
