import clsx from "clsx"
import { memo } from "react"
import { Link } from "react-router-dom"

const baseStyles = {
	solid:
		"group inline-flex items-center justify-center rounded-full py-2 px-5 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 h-12",
	outline:
		"group inline-flex ring-2 items-center justify-center rounded-full py-2 px-5 text-sm focus:outline-none h-12 font-semibold",
}

const variantStyles = {
	solid: {
		// slate:
		// 	"bg-slate-900 text-white hover:bg-slate-700 hover:text-slate-100 active:bg-slate-800 active:text-slate-300 focus-visible:outline-slate-900",
		primary:
			"bg-primary text-white hover:text-slate-100 hover:bg-primary font-semibold active:bg-primary active:text-primary focus-visible:outline-primary",
		destructive:
			"bg-red-300 text-white hover:text-slate-100 hover:bg-red-300 font-semibold active:bg-red-300 active:text-red-300 focus-visible:outline-red-300",
	},
	outline: {
		// slate:
		// 	"ring-slate-200 text-slate-700 hover:text-slate-900 hover:ring-slate-300 active:bg-slate-100 active:text-slate-600 focus-visible:outline-primary focus-visible:ring-slate-300",
		primary:
			"ring-primary text-dark-500 font-semibold hover:ring-primary active:ring-primary active:text-primary focus-visible:outline-white",
		destructive:
			"ring-red-300 text-dark-500 font-semibold hover:ring-red-300 active:ring-red-300 active:text-red-300 focus-visible:outline-white",
	},
}

type BaseProps = {
	variant?: "solid" | "outline"
	color?: "primary" | "destructive"
	className?: string
}

type LinkProps = BaseProps & {
	href: string
	children: React.ReactNode
}

type ButtonProps = BaseProps & {
	onClick: () => void
}

const Button = ({
	variant = "solid",
	color = "primary",
	className,
	...props
}: LinkProps | ButtonProps): JSX.Element => {
	className = clsx(
		baseStyles[variant],
		variantStyles[variant][color],
		className
	)

	return "href" in props ? (
		<Link to={props.href} className={className} {...props} />
	) : (
		<button className={className} {...props} />
	)
}

export default memo(Button)
