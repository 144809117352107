import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { dashActions } from "dashboard/dashSlice"
import { Sort } from "dashboard/guests/helpers"
import Guest, { processGuests, SplitGroupResponse } from "models/Guest"
import Tag from "models/Tag"
import store from "store"
import { ApiType } from "utils/api"

const initialState = {
	all: [],
	selection: [],
	sort: null,
	selectedTags: [],
} as GuestsState

const slice = createSlice({
	name: "dashboard/guests",
	initialState,
	reducers: {
		add: (state, { payload }: PayloadAction<Guest[]>) => {
			state.all = [...state.all, ...payload]
		},
		remove: (state, { payload }: PayloadAction<Guest[]>) => {
			state.all = state.all.filter(
				(guest) => !payload.some((g) => g.id === guest.id)
			)
		},
		update: (state, { payload }: PayloadAction<Guest>) => {
			const index = state.all.findIndex((guest) => guest.id === payload.id)

			if (index === -1) {
				return
			}

			const removedTags = state.all[index].tags.filter(
				(tag) => !payload.tags.some((t) => t.id === tag.id)
			)

			state.all[index] = payload
			state.selection = []
			state.selectedTags = state.selectedTags.filter(
				(tag) => !removedTags.some((t) => t.id === tag.id)
			)
		},
		set: (state, { payload }: PayloadAction<Guest[]>) => {
			state.all = payload
		},
		merge: (
			state,
			{ payload }: PayloadAction<{ guests: Guest[]; groupId: string }>
		) => {
			state.all = processGuests(
				state.all.map((g) =>
					payload.guests.some((s) => s.id === g.id)
						? {
								...g,
								guestGroupId: payload.groupId,
						  }
						: g
				)
			)
			state.selection = []
		},
		split: (
			state,
			{
				payload,
			}: PayloadAction<{
				selectedGuestsFromGroup: Guest[]
				response: SplitGroupResponse
			}>
		) => {
			state.all = processGuests(
				state.all.map((g) => {
					const guestGroupId = payload.response.find((group) =>
						group.guestIds.includes(g.id)
					)?.guestGroupId

					return guestGroupId != null &&
						payload.selectedGuestsFromGroup.some((s) => s.id === g.id)
						? {
								...g,
								guestGroupId,
						  }
						: g
				})
			)
			state.selection = []
		},
		clearSelection: (state) => {
			state.selection = []
		},
		setSelection: (state, { payload }: PayloadAction<Guest[]>) => {
			state.selection = payload
		},
		sort: (state, { payload }: PayloadAction<Sort>) => {
			state.sort = payload
		},
		clearSort: (state) => {
			state.sort = null
		},
		filter: (state, { payload }: PayloadAction<Tag[]>) => {
			state.selectedTags = payload
		},
		clearFilter: (state) => {
			state.selectedTags = []
		},
		clearState: () => initialState,
	},
	extraReducers: (builder) => {
		builder.addCase(
			dashActions.fetchInitialState.fulfilled,
			(state, { payload }) => {
				state.all = payload.guests
			}
		)
		builder.addCase(dashActions.removeTags, (state, { payload }) => {
			state.all = state.all.map((guest) => ({
				...guest,
				tags: guest.tags.filter((tag) => !payload.some((t) => t.id === tag.id)),
			}))
			state.selectedTags = state.selectedTags.filter(
				(tag) => !payload.some((t) => t.id === tag.id)
			)
		})
	},
})

export const guestsActions = {
	...slice.actions,

	//#region Thunks
	fetch: ({ eventGroupId, api }: { eventGroupId: string; api: ApiType }) => {
		return async (dispatch: typeof store.dispatch) => {
			try {
				dispatch(guestsActions.set(await api.guests.list(eventGroupId)))
			} catch (e) {
				console.error(e)
			}
		}
	},
	//#endregion
}

export type GuestsState = {
	all: Guest[]
	selection: Guest[]
	sort: Sort
	selectedTags: Tag[]
}

export const guestsReducer = slice.reducer
