/* eslint-disable @typescript-eslint/no-empty-function */
import useApi from "common/useApi"
import withI18n, { I18nProps } from "common/withI18n"
import EmptyState from "components/EmptyState"
import AnswersPopup from "dashboard/comms/AnswersPopup"
import CustomAnswersPopup from "dashboard/comms/CustomAnswersPopup"
import { QARowData } from "dashboard/comms/QARow"
import RSVPSheet from "dashboard/comms/RSVPSheet"
import StatisticsCard from "dashboard/comms/StatisticsCard"
import Header from "dashboard/Header"
import { RSVPStatisticsCustomAnswer } from "models/RSVPQuestion"
import { memo, useCallback, useContext, useMemo, useState } from "react"
import { useDashSelector, useGuestSelector, useScheduleSelector } from "store"
import MainContext from "store/context/MainContext"
import noRSVPs from "./images/noRSVPs.svg"
import noteIcon from "./images/note.svg"
import notificationIcon from "./images/notification.svg"

const Comms = ({ t }: I18nProps) => {
	const mainContext = useContext(MainContext)
	const guests = useGuestSelector().all
	const { mainEventId, statistics: stats, eventGroupId } = useDashSelector()
	const { events, rsvpQuestions: questions } = useScheduleSelector()
	const [expandedId, setExpandedId] = useState<string>(mainEventId)
	const [questionAnswers, setQuestionAnswers] = useState<{
		[eventId: string]: {
			[questionId: string]: {
				guestId: string
				answerIndex?: number
			}[]
		}
	}>({})
	const [clickedAnswer, setClickedAnswer] = useState<
		| {
				isLoading: boolean
				questionId: string
				answerIndex: number
		  }
		| undefined
	>(undefined)
	const [clickedCustomAnswers, setClickedCustomAnswers] = useState<
		| {
				question: string
				answers: RSVPStatisticsCustomAnswer[]
		  }
		| undefined
	>(undefined)
	const apiWithToken = useApi()

	const clickedQuestion = (eventId: string, questionId: string) =>
		questions[eventId].find((q) => q.id === questionId)

	const rsvpSheet = useMemo(
		() =>
			function TheAddSheet() {
				return <RSVPSheet />
			},
		[]
	)

	const qaRowData = useCallback(
		(eventId: string) => {
			const questionsForEvent = stats[eventId].questions

			const data: QARowData[] = []

			questionsForEvent.forEach((statsQuestion) => {
				const question = questions[eventId].find(
					(q2) => q2.id === statsQuestion.questionId
				)

				if (
					question == null ||
					question.type === "COMING_YES_NO" ||
					question.type === "PLUS_ONES"
				) {
					return
				}

				const customAnswers = stats[eventId].customAnswers.filter(
					(a) => a.questionId === statsQuestion.questionId
				)

				data.push({
					question: {
						id: question.id,
						question: question.question,
					},
					customAnswers,
					answers: question.answers.map((a, i) => ({
						value: statsQuestion.answerCounts[i] ?? 0,
						label: a,
					})),
				})
			})

			return data
		},
		[stats, questions]
	)

	if (
		Object.entries(stats).reduce((acc, s) => {
			return acc + s[1].sentRSVPs
		}, 0) === 0
	) {
		return (
			<div>
				<Header
					title={t("comms.title")}
					buttons={[
						{
							text: t("comms.button.send"),
							icon: noteIcon,
							onClick: () => mainContext.setSheet(rsvpSheet()),
						},
					]}
				/>

				<div className="mt-6 pr-9">
					<EmptyState
						title={t("comms.noContent.title")}
						icon={noRSVPs}
						text={
							<p className="text-center">
								<span>{t("comms.noContent.text.1")}</span>
								<br />
								<span>{t("comms.noContent.text.2")}</span>
							</p>
						}
						button={{
							text: t("comms.noContent.button"),
							onClick: () => mainContext.setSheet(rsvpSheet()),
						}}
					/>
				</div>
			</div>
		)
	}

	return (
		<>
			{clickedCustomAnswers == null ? null : (
				<CustomAnswersPopup
					guests={guests}
					onClose={() => setClickedCustomAnswers(undefined)}
					{...clickedCustomAnswers}
				/>
			)}

			{clickedAnswer == null ? null : (
				<AnswersPopup
					guests={guests.filter((g) => {
						const answer = questionAnswers[expandedId]?.[
							clickedAnswer.questionId
						]?.filter((q) => q.answerIndex === clickedAnswer.answerIndex)

						if (answer == null) {
							return false
						}

						return answer.find((q) => q.guestId === g.id) != null
					})}
					answer={
						clickedQuestion(expandedId, clickedAnswer.questionId)?.answers[
							clickedAnswer.answerIndex
						] ?? ""
					}
					question={
						clickedQuestion(expandedId, clickedAnswer.questionId)?.question ??
						""
					}
					isLoading={clickedAnswer.isLoading}
					onClose={() => setClickedAnswer(undefined)}
				/>
			)}

			<Header
				title={t("comms.title")}
				subtitle={{ text: t("comms.subtitle") }}
				buttons={[
					{
						text: t("comms.button.send"),
						icon: noteIcon,
						onClick: () => mainContext.setSheet(rsvpSheet()),
					},
				]}
			/>

			<div className="mt-7 flex flex-col gap-8 overflow-y-scroll pb-7">
				{Object.keys(stats).map((eventId) =>
					stats[eventId].sentRSVPs === 0 ? null : (
						<StatisticsCard
							key={eventId}
							icon={notificationIcon}
							isMainEvent={eventId === mainEventId}
							isExpanded={eventId === expandedId}
							onExpand={() => setExpandedId(eventId)}
							onAnswerClick={async (questionId, answerIndex) => {
								const clickedAnswer = {
									questionId,
									answerIndex,
									isLoading: questionAnswers[expandedId]?.[questionId] == null,
								}

								setClickedAnswer(clickedAnswer)

								const api = await apiWithToken()

								// If we already have the answer, don't fetch it again.
								if (!clickedAnswer.isLoading || api == null) {
									return
								}

								try {
									const a = await api.events.questionStatistics(
										eventId,
										eventGroupId,
										questionId
									)

									if (a != null && a.length > 0) {
										setQuestionAnswers((prev) => ({
											...prev,
											[expandedId]: {
												...prev[expandedId],
												[questionId]: a,
											},
										}))
									}

									setClickedAnswer({
										...clickedAnswer,
										isLoading: false,
									})
								} catch {
									setClickedAnswer(undefined)
								}
							}}
							onCustomAnswerClick={(answers) =>
								setClickedCustomAnswers(answers)
							}
							title={
								events.find((e) => e.id === eventId)?.name ?? "Unknown event"
							}
							t={t}
							answers={stats[eventId]}
							qaRowData={qaRowData(eventId)}
						/>
					)
				)}
			</div>
		</>
	)
}

export default memo(withI18n(Comms))
