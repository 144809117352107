import useApi from "common/useApi"
import withI18n, { I18nProps } from "common/withI18n"
import Alert from "components/Alert"
import Button from "components/Button"
import Capsule from "components/Capsule"
import HLine from "components/HLine"
import Loader from "components/Loader"
import Popup from "components/Popup"
import RadioButton from "components/RadioButton"
import SheetSection from "components/SheetSection"
import SheetWithSegmentedControl from "components/SheetWithSegmentedControl"
import TH from "components/TH"
import TR from "components/TR"
import VLabeledInput from "components/VLabeledInput"
import { dashActions } from "dashboard/dashSlice"
import { selectGroupedEvents } from "dashboard/schedule/Schedule"
import starIcon from "images/star.svg"
import { hasPermission, Permissions } from "models/AccountTier"
import { friendlyEventType } from "models/Event"
import { memo, useContext, useState } from "react"
import store, {
	useAuthSelector,
	useDashSelector,
	useGuestSelector,
	useScheduleSelector,
} from "store"
import MainContext from "store/context/MainContext"
import { isNonEmptyString } from "utils/validations"
import checkmarkIcon from "./images/checkmark.svg"

// eslint-disable-next-line sonarjs/cognitive-complexity
const RSVPSheet = ({ t }: I18nProps) => {
	const { eventGroupId, mainEventId } = useDashSelector()
	const { email, tier } = useAuthSelector()
	const schedule = useScheduleSelector()
	const groupedEvents = selectGroupedEvents(schedule)
	const mainEvent = useScheduleSelector().events.find(
		(e) => e.id === mainEventId
	)
	const guests = useGuestSelector().all
	const [selectedEventIds, setSelectedEventIds] = useState<string[]>([
		mainEventId,
	])
	const [upgradeState, setUpgradeState] = useState<
		"none" | "popup" | "sending" | "sent"
	>("none")

	const mainContext = useContext(MainContext)
	const apiWithToken = useApi()

	const [isSending, setIsSending] = useState<"idle" | "sending" | "test">(
		"idle"
	)
	const [showConfirmationAlert, setShowConfirmationAlert] = useState([
		false,
		false,
	])
	const [selectedIndex, setSelectedIndex] = useState(0)
	const [subject, setSubject] = useState(
		t("comms.sheet.rsvp.general.subject.value")
	)
	const [replyToEmail, setReplyToEmail] = useState(email ?? "")
	const [headline, setHeadline] = useState(
		t("comms.sheet.rsvp.general.headline.value")
	)
	const [subheadline, setSubheadline] = useState(
		t("comms.sheet.rsvp.general.subheadline.value")
	)
	const [salutation, setSalutation] = useState(
		t("comms.sheet.rsvp.message.salutation.value")
	)
	const [body, setBody] = useState(
		`${t("comms.sheet.rsvp.message.body.value.1")}${
			mainEvent?.eventStartAt == null
				? ""
				: ` ${t(
						"comms.sheet.rsvp.message.body.value.2"
				  )} ${mainEvent.eventStartAt.intlDate()}`
		}${t("comms.sheet.rsvp.message.body.value.3")}`
	)
	const [buttonText, setButtonText] = useState(
		t("comms.sheet.rsvp.message.buttonText.value")
	)
	const canSendRSVP = hasPermission(Permissions.sendRSVP, tier)

	const sendInterestedEmail = async () => {
		setUpgradeState("sending")

		setTimeout(() => setUpgradeState("sent"), 1000)
	}

	const selectedGuests = () =>
		guests.filter((g) =>
			schedule.events
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				.filter((e) => selectedEventIds.includes(e.id!))
				.flatMap((e) => e.tags)
				.some((t) => g.tags.some((gt) => gt.id === t.id))
		).length

	const sendEmail = async () => {
		const test = showConfirmationAlert[1]
		const api = await apiWithToken()

		if (api == null) {
			return
		}

		// Validate that all fields are filled out
		if (
			!isNonEmptyString(subject) ||
			!isNonEmptyString(replyToEmail) ||
			!isNonEmptyString(headline) ||
			!isNonEmptyString(subheadline) ||
			!isNonEmptyString(salutation) ||
			!isNonEmptyString(body) ||
			!isNonEmptyString(buttonText)
		) {
			mainContext.setNotification({
				type: "error",
				title: t("missingFields"),
			})

			return
		}

		const dto = {
			emailContent: {
				subject,
				replyToEmail,
				headline,
				subheadline,
				salutation,
				body,
				buttonText,
			},
		}

		if (!test) {
			Object.assign(dto, {
				dryRun: false,
				eventIds: selectedEventIds,
			})
		}

		try {
			mainContext.setIsLoading(true)
			setIsSending(test ? "test" : "sending")

			await api.rsvpInvitation.send(eventGroupId, test, dto)

			setIsSending("idle")
			mainContext.setIsLoading(false)

			if (test) {
				mainContext.setNotification({
					type: "success",
					title: t("comms.sheet.rsvp.testSent"),
				})

				mainContext.setSheet(null)

				return
			}

			store.dispatch(dashActions.fetchStatistics({ eventGroupId, api }))

			mainContext.setNotification({
				type: "success",
				title: t("comms.sheet.rsvp.messageSent"),
			})

			mainContext.setSheet(null)
		} catch (e) {
			setIsSending("idle")
			mainContext.setIsLoading(false)
			mainContext.setNotification({
				type: "warning",
				title: t("comms.sheet.rsvp.messageFailure"),
			})
		}
	}

	const Content = (
		<>
			<SheetSection title={t("comms.sheet.rsvp.general.title")}>
				<VLabeledInput
					label={t("comms.sheet.rsvp.general.subject")}
					value={subject}
					onChange={(e) => setSubject(e)}
				/>
				<VLabeledInput
					label={t("comms.sheet.rsvp.general.replyToEmail")}
					value={replyToEmail}
					onChange={(e) => setReplyToEmail(e)}
				/>
				<VLabeledInput
					label={t("comms.sheet.rsvp.general.headline")}
					value={headline}
					onChange={(e) => setHeadline(e)}
				/>
				<VLabeledInput
					label={t("comms.sheet.rsvp.general.subheadline")}
					value={subheadline}
					onChange={(e) => setSubheadline(e)}
				/>
			</SheetSection>

			<SheetSection title={t("comms.sheet.rsvp.message.title")}>
				<VLabeledInput
					label={t("comms.sheet.rsvp.message.salutation")}
					value={salutation}
					onChange={(e) => setSalutation(e)}
				/>
				<VLabeledInput
					inputType="area"
					rows={7}
					label={t("comms.sheet.rsvp.message.body")}
					value={body}
					onChange={(e) => setBody(e)}
				/>
				<VLabeledInput
					label={t("comms.sheet.rsvp.message.buttonText")}
					value={buttonText}
					onChange={(e) => setButtonText(e)}
				/>
			</SheetSection>
		</>
	)

	const CheckAndSend = (
		<>
			<SheetSection fullWidth title={t("comms.sheet.rsvp.checkAndSend.title")}>
				{groupedEvents.map((group, i) => (
					<table
						className={`w-full ${i == groupedEvents.length - 1 ? "mb-4" : ""} ${
							i == 0 ? "" : "mt-4"
						}`}
						key={`${group.date}-table`}
					>
						<thead>
							<TR className="h-10">
								<th className="py-0 opacity-40">
									<span className="font-semibold">
										{group.date.intlDate({
											weekday: "long",
										})}
									</span>
									, <span>{new Date(group.date).intlDate()}</span>
								</th>
								<th className="py-4 pr-12 text-center opacity-40">
									{t("guests").capitalized()}
								</th>
							</TR>
						</thead>
						<tbody>
							{group.events.map((event) => {
								const isMainEvent = event.id === mainEventId

								return (
									<TR
										key={event.id}
										className="cursor-pointer hover:bg-dark-100 hover:bg-opacity-5"
										onClick={() =>
											setSelectedEventIds((se) =>
												// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
												se.includes(event.id!)
													? se.filter((id) => id !== event.id)
													: // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
													  [...selectedEventIds, event.id!]
											)
										}
									>
										<TH padded={false} className="h-20 pl-4">
											<div
												className={`group/name flex h-full w-full items-center`}
											>
												<RadioButton
													checked={selectedEventIds.some((s) => s === event.id)}
													onClick={() =>
														setSelectedEventIds((se) =>
															// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
															se.includes(event.id!)
																? se.filter((id) => id !== event.id)
																: // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
																  [...selectedEventIds, event.id!]
														)
													}
												/>
												<div className="ml-5 flex flex-col gap-2">
													<div className="flex w-[414.5px] gap-3 overflow-x-clip text-sm font-bold">
														<span>{event.name}</span>
														{!isMainEvent ? null : (
															<img src={starIcon} title="Main Event" />
														)}
													</div>
													<div className="flex items-center">
														<span>
															{event.eventStartAt.intlDate({
																hour: "2-digit",
																minute: "2-digit",
															})}
														</span>
														{event.eventEndAt == null ? null : (
															<span>
																<span className="px-1">-</span>
																{event.eventEndAt.intlDate({
																	hour: "2-digit",
																	minute: "2-digit",
																})}
															</span>
														)}
														{/* Show it back in the future */}
														<Capsule className="ml-4 hidden h-6 bg-slate-300">
															{friendlyEventType(event.type)}
														</Capsule>
													</div>
												</div>
											</div>
										</TH>
										<th className="pr-12 text-center font-semibold">
											{
												guests.filter((g) =>
													schedule.events
														.filter((e) => event.id === e.id)
														.flatMap((e) => e.tags)
														.some((t) => g.tags.some((gt) => gt.id === t.id))
												).length
											}
										</th>
									</TR>
								)
							})}
						</tbody>
					</table>
				))}
			</SheetSection>
			<div className="mt-9 mb-12 flex w-full flex-col items-center">
				{isSending === "sending" ? (
					<Loader />
				) : (
					<>
						<div className="text-dark-100 text-opacity-60">
							{canSendRSVP ? (
								<>
									{t("comms.sheet.rsvp.disclaimer.1")}{" "}
									<span className="font-medium">{selectedGuests()}</span>{" "}
									{t("guests")}
								</>
							) : (
								<div className="text-center">
									{t("comms.sheet.rsvp.upgrade.disclaimer.1")}
									<br />
									<span className="font-semibold">
										{t("comms.sheet.rsvp.upgrade.disclaimer.2")}
									</span>
								</div>
							)}
						</div>
						{canSendRSVP ? null : (
							<Button
								className="mt-8 w-60 font-bold"
								variant="outline"
								onClick={() => setUpgradeState("popup")}
							>
								{t("comms.sheet.rsvp.upgrade.button")}
							</Button>
						)}
						<Button
							className={`mt-8 w-60 font-bold ${
								canSendRSVP
									? ""
									: "cursor-not-allowed bg-gray-400 hover:bg-gray-400 hover:text-white active:bg-gray-400 active:text-white"
							}`}
							onClick={
								canSendRSVP
									? () => setShowConfirmationAlert([true, false])
									: // eslint-disable-next-line @typescript-eslint/no-empty-function
									  () => {}
							}
						>
							{t("comms.sheet.rsvp.button.send")}
						</Button>
					</>
				)}
			</div>

			<div className="-ml-9">
				<HLine />
			</div>

			<div className="mt-8">
				{isSending === "test" ? (
					<div className="flex justify-center">
						<Loader />
					</div>
				) : (
					<SheetSection title={t("comms.sheet.rsvp.test.title.collapsed")}>
						<Button
							variant="outline"
							className="mx-auto mb-2 w-60 font-bold"
							onClick={() => setShowConfirmationAlert([true, true])}
						>
							{t("comms.sheet.rsvp.test.button.send")}
						</Button>
					</SheetSection>
				)}
			</div>
		</>
	)

	const upgradeInitialContent = (
		<div className="h-full w-full text-center text-dark-500">
			<p className="p-8 pt-4 opacity-60">
				<span className="font-semibold">
					{t("comms.sheet.rsvp.upgradePopup.subtitle.1")}
				</span>
				<br />
				{t("comms.sheet.rsvp.upgradePopup.subtitle.2")}
			</p>
			<div className="flex h-20 items-center justify-center gap-8 bg-light-200">
				<span className="font-semibold opacity-60">
					{t("comms.sheet.rsvp.upgradePopup.price.label")}
				</span>
				<span className="font-title text-3xl">
					{t("comms.sheet.rsvp.upgradePopup.price.value")}
				</span>
			</div>

			{upgradeState === "sending" ? (
				<div className="mt-8 flex justify-center">
					<Loader />
				</div>
			) : (
				<Button
					className="mt-7 min-w-[160px]"
					onClick={() => sendInterestedEmail()}
					variant="solid"
				>
					{t("comms.sheet.rsvp.upgradePopup.button")}
				</Button>
			)}
		</div>
	)

	const upgradeSentContent = (
		<div className="h-full w-full text-center text-dark-500">
			<img className="mx-auto -mt-6" src={checkmarkIcon} />

			<h1 className="mx-auto my-6 text-center text-2xl">
				{t("comms.sheet.rsvp.upgradePopup.confirmation.title")}
			</h1>

			<p className="p-8 pt-0 opacity-60">
				<span className="font-semibold">
					{t("comms.sheet.rsvp.upgradePopup.confirmation.subtitle.1")}
				</span>
				<br />
				{t("comms.sheet.rsvp.upgradePopup.confirmation.subtitle.2")}
			</p>

			<Button
				className="mt-3 min-w-[160px]"
				onClick={() => setUpgradeState("none")}
				variant="outline"
			>
				{t("comms.sheet.rsvp.upgradePopup.confirmation.button")}
			</Button>
		</div>
	)

	return (
		<>
			{upgradeState === "none" ? null : (
				<Popup
					onClose={() => setUpgradeState("none")}
					className="h-[400px] w-[600px]"
					title={
						upgradeState === "popup" || upgradeState === "sending"
							? t("comms.sheet.rsvp.upgradePopup.title")
							: ""
					}
					isLoading={false}
				>
					{upgradeState === "popup" || upgradeState === "sending"
						? upgradeInitialContent
						: upgradeSentContent}
				</Popup>
			)}

			<Alert
				buttonTitle={t("comms.sheet.rsvp.alert.confirmation.button")}
				title={
					showConfirmationAlert[1]
						? t("comms.sheet.rsvp.alert.confirmation.test.title")
						: `${t(
								"comms.sheet.rsvp.alert.confirmation.title.1"
						  )} ${selectedGuests()} ${t(
								"comms.sheet.rsvp.alert.confirmation.title.2"
						  )}`
				}
				type={showConfirmationAlert[1] ? "info" : "error"}
				open={showConfirmationAlert[0]}
				onClose={() => setShowConfirmationAlert([false, false])}
				onClick={() => {
					sendEmail()
					setShowConfirmationAlert([false, showConfirmationAlert[1]])
				}}
			/>
			<SheetWithSegmentedControl
				title={t("comms.sheet.rsvp.title")}
				segmentedControl={{
					items: [t("comms.sheet.rsvp.tab.1"), t("comms.sheet.rsvp.tab.2")].map(
						(t, i) => ({
							title: <span key={i}>{t}</span>,
							action: setSelectedIndex,
						})
					),
					selectedIndex,
				}}
				extraBottomContent={
					<div className="mt-16 flex items-center">
						{selectedIndex !== 0 ? null : (
							<Button
								variant="outline"
								className="mx-auto mb-2 w-60 font-bold"
								onClick={() => setSelectedIndex(1)}
							>
								{t("comms.sheet.rsvp.tab.2")}
							</Button>
						)}
					</div>
				}
			>
				{selectedIndex === 0 ? Content : CheckAndSend}
			</SheetWithSegmentedControl>
		</>
	)
}

export default memo(withI18n(RSVPSheet))
