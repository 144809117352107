import clsx from "clsx"
import { ReactNode } from "react"

const H2 = ({
	children,
	textSize = "text-2xl",
	className,
}: {
	className?: string
	textSize?: string
	children: ReactNode
}) => (
	<h2
		className={clsx(
			"font-title font-medium text-dark-500 text-opacity-40",
			textSize,
			className
		)}
	>
		{children}
	</h2>
)

export default H2
