import { SortDirection } from "components/SortableTH"
import Guest, { SortableField } from "models/Guest"
import Tag from "models/Tag"

export type Sort = {
	field: SortableField
	direction: SortDirection
} | null

export const performSort = (sort: Sort, guests: Guest[]) => {
	if (sort === null) {
		return guests
	}

	let groupId = ""

	return [...guests]
		.reduce((groups, guest) => {
			if (guest.guestGroupId === groupId) {
				return groups
			}

			groupId = guest.guestGroupId

			return groups.concat([
				guests.filter((g) => g.guestGroupId === guest.guestGroupId),
			])
		}, [] as Guest[][])
		.sort((a, b) => {
			return (
				a[0].sortableFields[sort.field].localeCompare(
					b[0].sortableFields[sort.field]
				) * (sort.direction === "asc" ? 1 : -1)
			)
		})
		.flat()
}

export const performFilter = (tags: Tag[], guests: Guest[]) => {
	if (tags.length === 0) {
		return guests
	} else {
		return [...guests].filter((g) =>
			tags.every((tag) => g.tags.find((t) => t.id === tag.id))
		)
	}
}
