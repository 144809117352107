export default [
	{ name: "Midway", offset: -660 },
	{ name: "Niue", offset: -660 },
	{ name: "Pago Pago", offset: -660 },
	{ name: "Adak", offset: -600 },
	{ name: "Honolulu", offset: -600 },
	{ name: "Johnston", offset: -600 },
	{ name: "Rarotonga", offset: -600 },
	{ name: "Tahiti", offset: -600 },
	{ name: "Anchorage", offset: -540 },
	{ name: "Gambier", offset: -540 },
	{ name: "Juneau", offset: -540 },
	{ name: "Metlakatla", offset: -540 },
	{ name: "Nome", offset: -540 },
	{ name: "Sitka", offset: -540 },
	{ name: "Yakutat", offset: -540 },
	{ name: "Marquesas", offset: -510 },
	{ name: "Los Angeles", offset: -480 },
	{ name: "Pitcairn", offset: -480 },
	{ name: "Santa Isabel", offset: -480 },
	{ name: "Tijuana", offset: -480 },
	{ name: "Vancouver", offset: -480 },
	{ name: "Boise", offset: -420 },
	{ name: "Cambridge Bay", offset: -420 },
	{ name: "Chihuahua", offset: -420 },
	{ name: "Creston", offset: -420 },
	{ name: "Dawson", offset: -420 },
	{ name: "Dawson Creek", offset: -420 },
	{ name: "Denver", offset: -420 },
	{ name: "Edmonton", offset: -420 },
	{ name: "Fort Nelson", offset: -420 },
	{ name: "Hermosillo", offset: -420 },
	{ name: "Inuvik", offset: -420 },
	{ name: "Mazatlan", offset: -420 },
	{ name: "Ojinaga", offset: -420 },
	{ name: "Phoenix", offset: -420 },
	{ name: "Whitehorse", offset: -420 },
	{ name: "Yellowknife", offset: -420 },
	{ name: "Bahia Banderas", offset: -360 },
	{ name: "Belize", offset: -360 },
	{ name: "Beulah", offset: -360 },
	{ name: "Center", offset: -360 },
	{ name: "Chicago", offset: -360 },
	{ name: "Costa Rica", offset: -360 },
	{ name: "El Salvador", offset: -360 },
	{ name: "Galapagos", offset: -360 },
	{ name: "Guatemala", offset: -360 },
	{ name: "Knox", offset: -360 },
	{ name: "Managua", offset: -360 },
	{ name: "Matamoros", offset: -360 },
	{ name: "Menominee", offset: -360 },
	{ name: "Merida", offset: -360 },
	{ name: "Mexico City", offset: -360 },
	{ name: "Monterrey", offset: -360 },
	{ name: "New Salem", offset: -360 },
	{ name: "Rainy River", offset: -360 },
	{ name: "Rankin Inlet", offset: -360 },
	{ name: "Regina", offset: -360 },
	{ name: "Resolute", offset: -360 },
	{ name: "Swift Current", offset: -360 },
	{ name: "Tegucigalpa", offset: -360 },
	{ name: "Tell City", offset: -360 },
	{ name: "Winnipeg", offset: -360 },
	{ name: "Bogota", offset: -300 },
	{ name: "Cancun", offset: -300 },
	{ name: "Cayman", offset: -300 },
	{ name: "Coral Harbour", offset: -300 },
	{ name: "Detroit", offset: -300 },
	{ name: "Easter", offset: -300 },
	{ name: "Eirunepe", offset: -300 },
	{ name: "Grand Turk", offset: -300 },
	{ name: "Guayaquil", offset: -300 },
	{ name: "Havana", offset: -300 },
	{ name: "Indianapolis", offset: -300 },
	{ name: "Iqaluit", offset: -300 },
	{ name: "Jamaica", offset: -300 },
	{ name: "Lima", offset: -300 },
	{ name: "Louisville", offset: -300 },
	{ name: "Marengo", offset: -300 },
	{ name: "Monticello", offset: -300 },
	{ name: "Montreal", offset: -300 },
	{ name: "Nassau", offset: -300 },
	{ name: "New York", offset: -300 },
	{ name: "Nipigon", offset: -300 },
	{ name: "Panama", offset: -300 },
	{ name: "Pangnirtung", offset: -300 },
	{ name: "Petersburg", offset: -300 },
	{ name: "Port-au-Prince", offset: -300 },
	{ name: "Rio Branco", offset: -300 },
	{ name: "Thunder Bay", offset: -300 },
	{ name: "Toronto", offset: -300 },
	{ name: "Vevay", offset: -300 },
	{ name: "Vincennes", offset: -300 },
	{ name: "Winamac", offset: -300 },
	{ name: "Anguilla", offset: -240 },
	{ name: "Antigua", offset: -240 },
	{ name: "Aruba", offset: -240 },
	{ name: "Barbados", offset: -240 },
	{ name: "Bermuda", offset: -240 },
	{ name: "Blanc-Sablon", offset: -240 },
	{ name: "Boa Vista", offset: -240 },
	{ name: "Campo Grande", offset: -240 },
	{ name: "Caracas", offset: -240 },
	{ name: "Cuiaba", offset: -240 },
	{ name: "Curacao", offset: -240 },
	{ name: "Dominica", offset: -240 },
	{ name: "Glace Bay", offset: -240 },
	{ name: "Goose Bay", offset: -240 },
	{ name: "Grenada", offset: -240 },
	{ name: "Guadeloupe", offset: -240 },
	{ name: "Guyana", offset: -240 },
	{ name: "Halifax", offset: -240 },
	{ name: "Kralendijk", offset: -240 },
	{ name: "La Paz", offset: -240 },
	{ name: "Lower Princes", offset: -240 },
	{ name: "Manaus", offset: -240 },
	{ name: "Marigot", offset: -240 },
	{ name: "Martinique", offset: -240 },
	{ name: "Moncton", offset: -240 },
	{ name: "Montserrat", offset: -240 },
	{ name: "Port of Spain", offset: -240 },
	{ name: "Porto Velho", offset: -240 },
	{ name: "Puerto Rico", offset: -240 },
	{ name: "Santo Domingo", offset: -240 },
	{ name: "St Barthelemy", offset: -240 },
	{ name: "St Kitts", offset: -240 },
	{ name: "St Lucia", offset: -240 },
	{ name: "St Thomas", offset: -240 },
	{ name: "St Vincent", offset: -240 },
	{ name: "Thule", offset: -240 },
	{ name: "Tortola", offset: -240 },
	{ name: "Araguaina", offset: -180 },
	{ name: "Asuncion", offset: -180 },
	{ name: "Bahia", offset: -180 },
	{ name: "Belem", offset: -180 },
	{ name: "Buenos Aires", offset: -180 },
	{ name: "Catamarca", offset: -180 },
	{ name: "Cayenne", offset: -180 },
	{ name: "Cordoba", offset: -180 },
	{ name: "Fortaleza", offset: -180 },
	{ name: "Godthab", offset: -180 },
	{ name: "Jujuy", offset: -180 },
	{ name: "La Rioja", offset: -180 },
	{ name: "Maceio", offset: -180 },
	{ name: "Mendoza", offset: -180 },
	{ name: "Miquelon", offset: -180 },
	{ name: "Montevideo", offset: -180 },
	{ name: "Palmer", offset: -180 },
	{ name: "Paramaribo", offset: -180 },
	{ name: "Punta Arenas", offset: -180 },
	{ name: "Recife", offset: -180 },
	{ name: "Rio Gallegos", offset: -180 },
	{ name: "Rothera", offset: -180 },
	{ name: "Salta", offset: -180 },
	{ name: "San Juan", offset: -180 },
	{ name: "San Luis", offset: -180 },
	{ name: "Santarem", offset: -180 },
	{ name: "Santiago", offset: -180 },
	{ name: "Sao Paulo", offset: -180 },
	{ name: "Stanley", offset: -180 },
	{ name: "Tucuman", offset: -180 },
	{ name: "Ushuaia", offset: -180 },
	{ name: "St Johns", offset: -150 },
	{ name: "Noronha", offset: -120 },
	{ name: "South Georgia", offset: -120 },
	{ name: "Azores", offset: -60 },
	{ name: "Cape Verde", offset: -60 },
	{ name: "Scoresbysund", offset: -60 },
	{ name: "Abidjan", offset: 0 },
	{ name: "Accra", offset: 0 },
	{ name: "Bamako", offset: 0 },
	{ name: "Banjul", offset: 0 },
	{ name: "Bissau", offset: 0 },
	{ name: "Canary", offset: 0 },
	{ name: "Conakry", offset: 0 },
	{ name: "Dakar", offset: 0 },
	{ name: "Danmarkshavn", offset: 0 },
	{ name: "Dublin", offset: 0 },
	{ name: "Faeroe", offset: 0 },
	{ name: "Freetown", offset: 0 },
	{ name: "Guernsey", offset: 0 },
	{ name: "Isle of Man", offset: 0 },
	{ name: "Jersey", offset: 0 },
	{ name: "Lisbon", offset: 0 },
	{ name: "Lome", offset: 0 },
	{ name: "London", offset: 0 },
	{ name: "Madeira", offset: 0 },
	{ name: "Monrovia", offset: 0 },
	{ name: "Nouakchott", offset: 0 },
	{ name: "Ouagadougou", offset: 0 },
	{ name: "Reykjavik", offset: 0 },
	{ name: "Sao Tome", offset: 0 },
	{ name: "St Helena", offset: 0 },
	{ name: "Troll", offset: 0 },
	{ name: "Algiers", offset: 60 },
	{ name: "Amsterdam", offset: 60 },
	{ name: "Andorra", offset: 60 },
	{ name: "Bangui", offset: 60 },
	{ name: "Belgrade", offset: 60 },
	{ name: "Berlin", offset: 60 },
	{ name: "Bratislava", offset: 60 },
	{ name: "Brazzaville", offset: 60 },
	{ name: "Brussels", offset: 60 },
	{ name: "Budapest", offset: 60 },
	{ name: "Busingen", offset: 60 },
	{ name: "Casablanca", offset: 60 },
	{ name: "Ceuta", offset: 60 },
	{ name: "Copenhagen", offset: 60 },
	{ name: "Douala", offset: 60 },
	{ name: "El Aaiun", offset: 60 },
	{ name: "Gibraltar", offset: 60 },
	{ name: "Kinshasa", offset: 60 },
	{ name: "Lagos", offset: 60 },
	{ name: "Libreville", offset: 60 },
	{ name: "Ljubljana", offset: 60 },
	{ name: "Longyearbyen", offset: 60 },
	{ name: "Luanda", offset: 60 },
	{ name: "Luxembourg", offset: 60 },
	{ name: "Madrid", offset: 60 },
	{ name: "Malabo", offset: 60 },
	{ name: "Malta", offset: 60 },
	{ name: "Monaco", offset: 60 },
	{ name: "Ndjamena", offset: 60 },
	{ name: "Niamey", offset: 60 },
	{ name: "Oslo", offset: 60 },
	{ name: "Paris", offset: 60 },
	{ name: "Podgorica", offset: 60 },
	{ name: "Porto-Novo", offset: 60 },
	{ name: "Prague", offset: 60 },
	{ name: "Rome", offset: 60 },
	{ name: "San Marino", offset: 60 },
	{ name: "Sarajevo", offset: 60 },
	{ name: "Skopje", offset: 60 },
	{ name: "Stockholm", offset: 60 },
	{ name: "Tirane", offset: 60 },
	{ name: "Tunis", offset: 60 },
	{ name: "Vaduz", offset: 60 },
	{ name: "Vatican", offset: 60 },
	{ name: "Vienna", offset: 60 },
	{ name: "Warsaw", offset: 60 },
	{ name: "Zagreb", offset: 60 },
	{ name: "Zurich", offset: 60 },
	{ name: "Amman", offset: 120 },
	{ name: "Athens", offset: 120 },
	{ name: "Beirut", offset: 120 },
	{ name: "Blantyre", offset: 120 },
	{ name: "Bucharest", offset: 120 },
	{ name: "Bujumbura", offset: 120 },
	{ name: "Cairo", offset: 120 },
	{ name: "Chisinau", offset: 120 },
	{ name: "Damascus", offset: 120 },
	{ name: "Famagusta", offset: 120 },
	{ name: "Gaborone", offset: 120 },
	{ name: "Gaza", offset: 120 },
	{ name: "Harare", offset: 120 },
	{ name: "Hebron", offset: 120 },
	{ name: "Helsinki", offset: 120 },
	{ name: "Jerusalem", offset: 120 },
	{ name: "Johannesburg", offset: 120 },
	{ name: "Juba", offset: 120 },
	{ name: "Kaliningrad", offset: 120 },
	{ name: "Khartoum", offset: 120 },
	{ name: "Kiev", offset: 120 },
	{ name: "Kigali", offset: 120 },
	{ name: "Lubumbashi", offset: 120 },
	{ name: "Lusaka", offset: 120 },
	{ name: "Maputo", offset: 120 },
	{ name: "Mariehamn", offset: 120 },
	{ name: "Maseru", offset: 120 },
	{ name: "Mbabane", offset: 120 },
	{ name: "Nicosia", offset: 120 },
	{ name: "Riga", offset: 120 },
	{ name: "Sofia", offset: 120 },
	{ name: "Tallinn", offset: 120 },
	{ name: "Tripoli", offset: 120 },
	{ name: "Uzhgorod", offset: 120 },
	{ name: "Vilnius", offset: 120 },
	{ name: "Windhoek", offset: 120 },
	{ name: "Zaporozhye", offset: 120 },
	{ name: "Addis Ababa", offset: 180 },
	{ name: "Aden", offset: 180 },
	{ name: "Antananarivo", offset: 180 },
	{ name: "Asmera", offset: 180 },
	{ name: "Baghdad", offset: 180 },
	{ name: "Bahrain", offset: 180 },
	{ name: "Comoro", offset: 180 },
	{ name: "Dar es Salaam", offset: 180 },
	{ name: "Djibouti", offset: 180 },
	{ name: "Istanbul", offset: 180 },
	{ name: "Kampala", offset: 180 },
	{ name: "Kirov", offset: 180 },
	{ name: "Kuwait", offset: 180 },
	{ name: "Mayotte", offset: 180 },
	{ name: "Minsk", offset: 180 },
	{ name: "Mogadishu", offset: 180 },
	{ name: "Moscow", offset: 180 },
	{ name: "Nairobi", offset: 180 },
	{ name: "Qatar", offset: 180 },
	{ name: "Riyadh", offset: 180 },
	{ name: "Simferopol", offset: 180 },
	{ name: "Syowa", offset: 180 },
	{ name: "Volgograd", offset: 180 },
	{ name: "Tehran", offset: 210 },
	{ name: "Astrakhan", offset: 240 },
	{ name: "Baku", offset: 240 },
	{ name: "Dubai", offset: 240 },
	{ name: "Mahe", offset: 240 },
	{ name: "Mauritius", offset: 240 },
	{ name: "Muscat", offset: 240 },
	{ name: "Reunion", offset: 240 },
	{ name: "Samara", offset: 240 },
	{ name: "Saratov", offset: 240 },
	{ name: "Tbilisi", offset: 240 },
	{ name: "Ulyanovsk", offset: 240 },
	{ name: "Yerevan", offset: 240 },
	{ name: "Kabul", offset: 270 },
	{ name: "Aqtau", offset: 300 },
	{ name: "Aqtobe", offset: 300 },
	{ name: "Ashgabat", offset: 300 },
	{ name: "Atyrau", offset: 300 },
	{ name: "Dushanbe", offset: 300 },
	{ name: "Karachi", offset: 300 },
	{ name: "Kerguelen", offset: 300 },
	{ name: "Maldives", offset: 300 },
	{ name: "Mawson", offset: 300 },
	{ name: "Oral", offset: 300 },
	{ name: "Qyzylorda", offset: 300 },
	{ name: "Samarkand", offset: 300 },
	{ name: "Tashkent", offset: 300 },
	{ name: "Yekaterinburg", offset: 300 },
	{ name: "Calcutta", offset: 330 },
	{ name: "Colombo", offset: 330 },
	{ name: "Katmandu", offset: 345 },
	{ name: "Almaty", offset: 360 },
	{ name: "Bishkek", offset: 360 },
	{ name: "Chagos", offset: 360 },
	{ name: "Dhaka", offset: 360 },
	{ name: "Omsk", offset: 360 },
	{ name: "Qostanay", offset: 360 },
	{ name: "Thimphu", offset: 360 },
	{ name: "Urumqi", offset: 360 },
	{ name: "Vostok", offset: 360 },
	{ name: "Cocos", offset: 390 },
	{ name: "Rangoon", offset: 390 },
	{ name: "Bangkok", offset: 420 },
	{ name: "Barnaul", offset: 420 },
	{ name: "Christmas", offset: 420 },
	{ name: "Davis", offset: 420 },
	{ name: "Hovd", offset: 420 },
	{ name: "Jakarta", offset: 420 },
	{ name: "Krasnoyarsk", offset: 420 },
	{ name: "Novokuznetsk", offset: 420 },
	{ name: "Novosibirsk", offset: 420 },
	{ name: "Phnom Penh", offset: 420 },
	{ name: "Pontianak", offset: 420 },
	{ name: "Saigon", offset: 420 },
	{ name: "Tomsk", offset: 420 },
	{ name: "Vientiane", offset: 420 },
	{ name: "Brunei", offset: 480 },
	{ name: "Choibalsan", offset: 480 },
	{ name: "Hong Kong", offset: 480 },
	{ name: "Irkutsk", offset: 480 },
	{ name: "Kuala Lumpur", offset: 480 },
	{ name: "Kuching", offset: 480 },
	{ name: "Macau", offset: 480 },
	{ name: "Makassar", offset: 480 },
	{ name: "Manila", offset: 480 },
	{ name: "Perth", offset: 480 },
	{ name: "Shanghai", offset: 480 },
	{ name: "Singapore", offset: 480 },
	{ name: "Taipei", offset: 480 },
	{ name: "Ulaanbaatar", offset: 480 },
	{ name: "Eucla", offset: 525 },
	{ name: "Chita", offset: 540 },
	{ name: "Dili", offset: 540 },
	{ name: "Jayapura", offset: 540 },
	{ name: "Khandyga", offset: 540 },
	{ name: "Palau", offset: 540 },
	{ name: "Pyongyang", offset: 540 },
	{ name: "Seoul", offset: 540 },
	{ name: "Tokyo", offset: 540 },
	{ name: "Yakutsk", offset: 540 },
	{ name: "Darwin", offset: 570 },
	{ name: "Brisbane", offset: 600 },
	{ name: "DumontDUrville", offset: 600 },
	{ name: "Guam", offset: 600 },
	{ name: "Lindeman", offset: 600 },
	{ name: "Port Moresby", offset: 600 },
	{ name: "Saipan", offset: 600 },
	{ name: "Truk", offset: 600 },
	{ name: "Ust-Nera", offset: 600 },
	{ name: "Vladivostok", offset: 600 },
	{ name: "Adelaide", offset: 630 },
	{ name: "Broken Hill", offset: 630 },
	{ name: "Bougainville", offset: 660 },
	{ name: "Casey", offset: 660 },
	{ name: "Currie", offset: 660 },
	{ name: "Efate", offset: 660 },
	{ name: "Guadalcanal", offset: 660 },
	{ name: "Hobart", offset: 660 },
	{ name: "Kosrae", offset: 660 },
	{ name: "Lord Howe", offset: 660 },
	{ name: "Macquarie", offset: 660 },
	{ name: "Magadan", offset: 660 },
	{ name: "Melbourne", offset: 660 },
	{ name: "Noumea", offset: 660 },
	{ name: "Ponape", offset: 660 },
	{ name: "Sakhalin", offset: 660 },
	{ name: "Srednekolymsk", offset: 660 },
	{ name: "Sydney", offset: 660 },
	{ name: "Anadyr", offset: 720 },
	{ name: "Funafuti", offset: 720 },
	{ name: "Kamchatka", offset: 720 },
	{ name: "Kwajalein", offset: 720 },
	{ name: "Majuro", offset: 720 },
	{ name: "Nauru", offset: 720 },
	{ name: "Norfolk", offset: 720 },
	{ name: "Tarawa", offset: 720 },
	{ name: "Wake", offset: 720 },
	{ name: "Wallis", offset: 720 },
	{ name: "Apia", offset: 780 },
	{ name: "Auckland", offset: 780 },
	{ name: "Enderbury", offset: 780 },
	{ name: "Fakaofo", offset: 780 },
	{ name: "Fiji", offset: 780 },
	{ name: "McMurdo", offset: 780 },
	{ name: "Tongatapu", offset: 780 },
	{ name: "Chatham", offset: 825 },
	{ name: "Kiritimati", offset: 840 },
]
