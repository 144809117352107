import { FC } from "react"
import { useI18nSelector } from "store"
import { I18n, Translations } from "store/i18nSlice"

// eslint-disable-next-line import/exports-last
export const translator =
	({ translations, locale }: I18n) =>
	(string: string | undefined | null): string => {
		let key: string | undefined | null

		if (typeof string === "string") {
			key = string
		} else {
			return "INVALID_KEY"
		}

		const langTranslations = translations[locale]

		if (Object.keys(langTranslations).length === 0) {
			return key
		}

		return langTranslations[key] ?? key
	}

// We do this so we have a single hook per component, otherwise we'd have a hook per translation. The result would be that the number of hooks in a component might differ between renders, depending on what's displayed.
const withI18n = <T,>(Component: FC<T & I18nProps>): FC<T> => {
	const Comp = (props: T) => {
		const i18n = useI18nSelector()
		const t = translator(i18n)

		return <Component t={t} locale={i18n.locale} {...props} />
	}

	Comp.displayName = `withI18n(${Component.displayName ?? Component.name})`

	return Comp
}

export type I18nProps = {
	t: ReturnType<typeof translator>
	locale: keyof Translations
}

export default withI18n
