import clsx from "clsx"
import closeIcon from "images/icons/close-small.svg"
import { ReactNode, memo } from "react"

const Capsule = ({
	className,
	color,
	icon = {
		src: closeIcon,
		alt: "Close",
	},
	children,
	onClick,
	onDelete,
}: {
	className?: string
	color?: string
	icon?: {
		src: string
		alt: string
	}
	children: ReactNode
	onClick?: () => void
	onDelete?: () => void
}) => (
	<div
		className={clsx(
			"flex shrink-0 items-center justify-center whitespace-nowrap rounded-3xl px-5 py-2",
			onDelete == null
				? onClick == null
					? ""
					: "cursor-pointer"
				: "group/capsule cursor-pointer hover:px-0 hover:py-1.5 hover:pl-5",
			className
		)}
		style={color == null ? undefined : { backgroundColor: color }}
		onClick={(e) => {
			if (onClick == null) {
				return
			}

			e.stopPropagation()
			onClick()
		}}
	>
		<span className="flex h-full w-full items-center justify-center">
			{children}
		</span>
		<img
			{...icon}
			className="hidden group-hover/capsule:inline-block group-hover/capsule:px-3"
			onClick={(e) => {
				e.stopPropagation()
				onDelete?.()
			}}
		/>
	</div>
)

export default memo(Capsule)
