export default {
	home: "/",
	setupAccount: "/setup-account",

	event: "/event",
	eventRSVP: "/event/rsvp",
	interested: "/interested",
	login: "/login",

	dashboard: "/dashboard",
	profile: "/dashboard/profile",
	guests: "/dashboard/guests",
	comms: "/dashboard/comms",
	schedule: "/dashboard/schedule",
	design: "/dashboard/design",
	pages: "/dashboard/pages",
	settings: "/dashboard/settings",
} as const
