import axios, { AxiosResponse } from "axios"
import { AppConfig } from "utils/envSetup"

type Method = "get" | "post" | "put" | "patch" | "delete"

const instance = axios.create()
const onboardingInstance = axios.create()
const guestInstance = axios.create()

// const adminHost = "https://admin.eventa.rsvp"
export const host = AppConfig.host

// We put this here since it requires `instance` and we don't want to export that one.
export const setToken = (token: string | null) => {
	if (token != null) {
		instance.defaults.headers.common.authorization = `Bearer ${token}`
	} else {
		delete instance.defaults.headers.common.authorization
	}
}

export const updateAcceptLanguage = (locale: string) => {
	guestInstance.defaults.headers.common["Accept-Language"] = locale
	instance.defaults.headers.common["Accept-Language"] = locale
}

export const perform =
	(rootPath: string, uniqueLinkHash?: string, onboardingLinkHash?: string) =>
	async <T>(
		method: Method,
		path: string,
		params?: Record<string, unknown> | unknown[]
	) => {
		let url = `${host}/api/v2${rootPath}${path}`.replace(/\/$/, "")
		let result: AxiosResponse<T>
		const ax =
			uniqueLinkHash != null
				? guestInstance
				: onboardingLinkHash != null
				  ? onboardingInstance
				  : instance

		if (uniqueLinkHash != null) {
			ax.defaults.headers.common.authorization = `Digest ${uniqueLinkHash}`
		} else if (onboardingLinkHash != null) {
			ax.defaults.headers.common.authorization = `Digest ${onboardingLinkHash}`
		}

		if (method === "post" || method === "put" || method === "patch") {
			result = await ax[method]<T>(url, params)
		} else {
			if (params != null) {
				url += "?"
				url += Object.entries(params)
					.map(([k, v]) => `${k}=${v}`)
					.join("&")
			}

			result = await ax[method]<T>(url)
		}

		if (result.status !== 200) {
			throw new Error(`${result.status}: ${result.statusText}`)
		}

		return result.data
	}
