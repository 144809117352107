import { Dialog, Transition } from "@headlessui/react"
import {
	CheckCircleIcon,
	ExclamationTriangleIcon,
	InformationCircleIcon,
} from "@heroicons/react/24/outline"
import { Fragment, ReactNode, memo, useRef } from "react"

// TODO: Do the same for alert like for `Notification`
const Alert = ({
	title,
	text = undefined,
	buttonTitle,
	type = "error",
	open,
	onClick = undefined,
	onClose,
}: {
	title: ReactNode
	text?: ReactNode
	buttonTitle: string
	type?: "success" | "error" | "warning" | "info"
	open: boolean
	onClick?: () => void
	onClose: () => void
}) => {
	const cancelButtonRef = useRef(null)

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as="div"
				className="relative z-10"
				initialFocus={cancelButtonRef}
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				onClose={() => {}}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
								<div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
									<div className="sm:flex sm:items-start">
										<div
											className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full ${
												type === "success"
													? ""
													: type === "info"
													  ? "bg-light-100"
													  : type === "warning"
													    ? "bg-yellow-100"
													    : "bg-red-100"
											} sm:mx-0 sm:h-10 sm:w-10`}
										>
											{type === "success" ? (
												<CheckCircleIcon
													className="h-6 w-6 text-green-400"
													aria-hidden="true"
												/>
											) : type === "info" ? (
												<InformationCircleIcon className="text-dark-100 text-opacity-70" />
											) : (
												<ExclamationTriangleIcon
													className={`h-6 w-6 ${
														type === "error"
															? "text-red-600"
															: "text-yellow-600"
													}`}
													aria-hidden="true"
												/>
											)}
										</div>
										<div className="mt-3 flex min-h-[38px] flex-col justify-center text-center sm:mt-0 sm:ml-4 sm:text-left">
											<Dialog.Title
												as="h3"
												className="text-lg font-medium leading-6 text-gray-900"
											>
												{title}
											</Dialog.Title>
											{text == null ? null : (
												<div className="mt-2">
													<p className="text-sm text-gray-500">{text}</p>
												</div>
											)}
										</div>
									</div>
								</div>
								<div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
									{onClick == null ? null : (
										<button
											type="button"
											className="inline-flex w-full justify-center rounded-3xl border border-transparent bg-primary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-opacity-95 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
											onClick={onClick}
										>
											{buttonTitle}
										</button>
									)}
									<button
										type="button"
										className="mt-3 inline-flex w-full justify-center rounded-3xl border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
										onClick={onClose}
										ref={cancelButtonRef}
									>
										{onClick == null ? buttonTitle : "Cancel"}
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}

export default memo(Alert)
