import { I18nProps } from "common/withI18n"
import { RSVPStatisticsCustomAnswer } from "models/RSVPQuestion"

const QARow = ({
	question,
	answers,
	customAnswers,
	onAnswerClick,
	onCustomAnswerClick,
	t,
}: QARowData & {
	t: I18nProps["t"]
	onAnswerClick: (questionId: string, answerIndex: number) => void
	onCustomAnswerClick: (customAnswers: {
		question: string
		answers: RSVPStatisticsCustomAnswer[]
	}) => void
}) => {
	const maxAnswers = Math.max(...answers.map((a) => a.value))
	const leadingMargin =
		maxAnswers < 10
			? "-ml-[11.1rem]"
			: maxAnswers < 100
			  ? "-ml-[9.8rem]"
			  : maxAnswers < 1000
			    ? "-ml-[9.3rem]"
			    : "-ml-[8.8rem]"

	return (
		<div className="flex justify-between">
			<span className="font-semibold opacity-70">{question.question}</span>
			<div className="flex w-1/2 flex-col gap-4">
				{answers.map((a, i) => (
					// This weird alignment because the font used for values has a very high baseline.
					<div
						key={i}
						className="flex cursor-pointer items-end gap-6"
						onClick={() => onAnswerClick(question.id, i)}
					>
						<span
							className={`${leadingMargin} -mt-2 w-16 text-end font-title text-3xl font-semibold`}
						>
							{a.value}
						</span>
						<span className="font-semibold opacity-70">{a.label}</span>
					</div>
				))}

				{customAnswers.length === 0 ? null : (
					<div
						className="flex cursor-pointer items-end gap-6"
						onClick={() =>
							onCustomAnswerClick({
								question: question.question,
								answers: customAnswers,
							})
						}
					>
						<span
							className={`${leadingMargin} -mt-2 w-16 text-end font-title text-3xl font-semibold`}
						>
							{customAnswers.length}
						</span>
						<span className="font-semibold opacity-70">
							{customAnswers.length === 1
								? t("comms.summary.customAnswer")
								: t("comms.summary.customAnswers")}
						</span>
					</div>
				)}
			</div>
		</div>
	)
}

export type QARowData = {
	question: {
		id: string
		question: string
	}
	customAnswers: RSVPStatisticsCustomAnswer[]
	answers: {
		value: number
		label: string
	}[]
}

export default QARow
