import withI18n, { I18nProps } from "common/withI18n"
import Placeholder from "components/Placeholder"
import brushIcon from "images/brush.svg"

const NotImplemented = ({ t }: I18nProps) => (
	<Placeholder
		title={t("notImplemented.title")}
		icon={brushIcon}
		text={
			<>
				{t("notImplemented.subtitle.1")}
				<br />
				{t("notImplemented.subtitle.2")}
			</>
		}
	/>
)

export default withI18n(NotImplemented)
