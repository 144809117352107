import intlFormat from "date-fns/intlFormat"

//#region String
String.prototype.capitalized = function (): string {
	if (this == null || this.length === 0) {
		return ""
	}

	return this[0].toUpperCase() + this.substring(1)
}
//#endregion

Array.prototype.withEmptySelection = function (): Array<string> {
	return [""].concat(this)
}

//#region Dates
const defaultFormatOptions = {
	year: "numeric",
	month: "long",
	day: "numeric",
	formatMatcher: "basic",
} as FormatOptions

String.prototype.intlDate = function (
	formatOptions = defaultFormatOptions
): string {
	let n: number

	if (this == null || this.length === 0) {
		return ""
	}

	const date = new Date(`${this}`)

	if (date.toString() === "Invalid Date") {
		n = parseFloat(`${this}`)
	} else {
		n = date.getTime()
	}

	if (isNaN(n)) {
		return ""
	}

	return n.intlDate(formatOptions)
}

Number.prototype.intlDate = function (
	formatOptions = defaultFormatOptions
): string {
	return intlFormat(this as number, formatOptions)
}

Date.prototype.intlDate = function (
	formatOptions = defaultFormatOptions
): string {
	return intlFormat(this.getTime(), formatOptions)
}
//#endregion

const isProd = process.env.NODE_ENV === "production"
const host = isProd ? "https://api.eventa.rsvp" : "http://localhost:8080"
const homeURL = isProd ? host.replaceAll("api.", "") : "http://localhost:3000"
const auth0ClientId = isProd
	? "uUNTTtQQkDYMjCDKCxvynsUL64Dlja58"
	: "9n5xUHyBKc34QKhFbSWACIFTfPihBmsU"

export const AppConfig = {
	isTest: process.env.NODE_ENV === "test",
	isProd,
	isDev: !isProd && !process.env.isTest,
	auth0ClientId,
	host,
	homeURL,
}
