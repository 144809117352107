import { useAuth0 } from "@auth0/auth0-react"
import { useCallback, useEffect } from "react"
import store from "store"
import { authActions } from "store/authSlice"
import api from "utils/api"

const useApi = (authenticated = true) => {
	const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
		useAuth0()

	const fetchToken = useCallback(async () => {
		if (isLoading || !isAuthenticated) {
			return
		}

		try {
			const token = await getAccessTokenSilently()

			store.dispatch(authActions.setToken(token))
		} catch {}
	}, [isLoading, isAuthenticated, getAccessTokenSilently])

	useEffect(() => {
		store.dispatch(authActions.setEmail(user?.email))
	}, [user])

	return useCallback(async () => {
		if (authenticated) {
			if (!isAuthenticated) {
				return
			}

			await fetchToken()
		}

		return api
	}, [fetchToken, isAuthenticated, authenticated])
}

export default useApi
