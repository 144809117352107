import withI18n, { I18nProps } from "common/withI18n"
import Button from "components/Button"
import H2 from "components/H2"
import Links from "dashboard/sidebar/Links"
import brushIcon from "images/brush.svg"
import { useState } from "react"
import { Outlet } from "react-router-dom"
import { GridLoader } from "react-spinners"
import R from "routes/R"
import { useDashSelector, useGuestSelector, useScheduleSelector } from "store"

const Layout = ({ t }: I18nProps) => {
	const dash = useDashSelector()
	const guests = useGuestSelector().all
	const events = useScheduleSelector().events
	const type = [R.guests, R.schedule, R.pages, R.profile]
		.filter((e) => {
			if (e === R.guests) {
				return guests.length > 0
			} else if (e === R.schedule) {
				return events.length > 0
			}
			return true
		})
		.map((e) => `${e}`)
		.includes(location.pathname)
		? "split"
		: "single"
	const [didAcceptMobile, setDidAcceptMobile] = useState(false)

	return (
		<div className="relative">
			<div className="flex h-full overflow-x-clip">
				<div className="w-[138px]">
					<nav className="fixed flex h-screen flex-col items-center justify-between gap-6 overflow-y-auto bg-white py-10 px-6">
						<Links />
					</nav>
				</div>
				<div className="h-full w-full overflow-x-clip bg-light-100">
					{type === "split" && dash.isInitialized ? (
						<div
							className={`fixed h-full bg-light-200 w-[${fixedSideWidth}px]`}
						></div>
					) : null}
					{/* No right padding because of tables */}
					<div
						className={`pl-[${fixedSidePadding}px] relative flex h-full flex-col pt-7`}
					>
						{/* Always show profile, especially to allow logout in case something breaks. */}
						{!dash.isInitialized && location.pathname !== R.profile ? (
							<div className="fixed flex h-full w-full justify-center">
								<div
									className={`-mt-40 -ml-[225px] flex flex-col items-center justify-center`}
								>
									<GridLoader color="#FF7C67" />
								</div>
							</div>
						) : (
							<Outlet />
						)}
					</div>
				</div>
			</div>
			<div
				className={`fixed bottom-0 left-0 right-0 top-0 z-[99] bg-light-100 ${
					didAcceptMobile ? "hidden" : "block lg:hidden"
				}`}
			>
				<div className="flex h-full flex-col items-center justify-center">
					<div className="flex flex-col items-center justify-center gap-4">
						<img className="mx-auto opacity-40" src={brushIcon} />
						<H2 className="mx-20 mb-4 max-w-[500px] text-center text-2xl font-bold">
							{t("smallScreen.unavailable")}
						</H2>
						<Button onClick={() => setDidAcceptMobile(true)}>
							{t("smallScreen.continue")}
						</Button>
					</div>
				</div>
			</div>
		</div>
	)
}

// Comments with actual values because PostCSS would remove the classes
export const fixedSidePadding = 36 // pl-[36px]
export const fixedSideWidth = 450.5 // w-[450.5px] -ml-[225px]
export const fixedSideContentWidth = fixedSideWidth - fixedSidePadding // w-[414.5px], w-[374.5px]

export default withI18n(Layout)
